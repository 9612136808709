import React from "react";

import { FunctionComponent } from "react";
import "../../css/CardContainer.css";
import { useNavigate } from "react-router-dom";

const CardContainer: FunctionComponent = () => {
  const navigate = useNavigate();
  const handleclick = (path: string) => {
    navigate(path);
  };
  return (
    <div className="cards">
      <div className="cart-1-parent">
        <button
          className="cart-1"
          id="Clients"
          type="button"
          onClick={() => handleclick("/Clients")}
        >
          <img
            className="group-icon"
            alt=""
            src="Images/HomePage/undefined6.png"
          />
          <div className="clients">Clients</div>
        </button>
        <button
          className="cart-2"
          id="produits"
          type="button"
          onClick={() => handleclick("/Produit")}
        >
          <img
            className="group-icon1"
            alt=""
            src="Images/HomePage/undefined7.png"
          />
          <div className="clients">Produits / Services</div>
        </button>
        <button
          className="cart-3"
          id="Hospitalisation"
          type="button"
          onClick={() => handleclick("/Hospitalisation")}
        >
          <img
            className="group-icon2"
            alt=""
            src="Images/HomePage/undefined8.png"
          />
          <div className="clients">Hospitalisation</div>
        </button>
      </div>
      <div className="cart-1-parent">
        <button
          className="cart-5"
          id="ventes"
          type="button"
          onClick={() => handleclick("/Ventes")}
        >
          <img
            className="group-icon3"
            alt=""
            src="Images/HomePage/undefined9.png"
          />
          <div className="clients">Ventes</div>
        </button>
        <button
          className="cart-6"
          id="Achats"
          type="button"
          onClick={() => handleclick("/Achats")}
        >
          <img
            className="group-icon4"
            alt=""
            src="Images/HomePage/undefined10.png"
          />
          <div className="clients">Achats</div>
        </button>
        <button
          className="cart-7"
          id="caisse"
          type="button"
          onClick={() => handleclick("/Caisse")}
        >
          <img
            className="group-icon5"
            alt=""
            src="Images/HomePage/undefined11.png"
          />
          <div className="clients">
            <p className="caisse1">Caisse</p>
          </div>
        </button>
      </div>
      <div className="cart-1-parent">
        <button
          className="cart-8"
          id="point"
          type="button"
          onClick={() => handleclick("/PointDeVente")}
        >
          <img
            className="group-icon6"
            alt=""
            src="Images/HomePage/undefined12.png"
          />
          <div className="clients">Point de Vente</div>
        </button>
        <button
          className="cart-9"
          id="rendezvous"
          type="button"
          onClick={() => handleclick("/Rendezvous")}
        >
          <img
            className="group-icon7"
            alt=""
            src="Images/HomePage/undefined13.png"
          />
          <div className="clients">Rendez-vous</div>
        </button>
        <button
          className="cart-10"
          id="utilisateurs"
          type="button"
          onClick={() => handleclick("/Utilisateurs")}
        >
          <img
            className="group-icon8"
            alt=""
            src="Images/HomePage/undefined14.png"
          />
          <div className="clients">Utilisateurs</div>
        </button>
      </div>
    </div>
  );
};

export default CardContainer;
