import React from 'react';
import { FunctionComponent, useState } from 'react';
import Buttons1 from './Buttons1';
import '../../css/Buttons.css';
import AddClient from './AddClient';
import Buttons2 from './Buttons2';
import AddAnimal from './addAnimal';
import UpdateClient from './ModifyClient';
export interface Data {
  id: number;
  client: string;
  phone: string;
  credit: number;
  derniereTransaction: string;
  animals: {id:number, name: string; race: string }[];
}

interface ButtonsBarProps {
  selectedClient:Data | null;

}

const ButtonsBar: FunctionComponent<ButtonsBarProps> = ({ selectedClient }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isUpdateClientOpen, setIsUpdateClientOpen] = useState(false);

  const handleAjouterClientClick = () => {
    setIsModalOpen(true);
  };
  const handleModifierClientClick = () => {
    setIsUpdateClientOpen(true);
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleCloseModalUpdate = () => {
    setIsUpdateClientOpen(false);
  };
  
  const handleAjouterAnimalClick = () => {
    setIsModalOpen1(true);
  };

  const handleCloseModal1 = () => {
    setIsModalOpen1(false);
  };

  return (
    <div className="buttons">
      <div>
        <Buttons1
          icon="/Images/Clients/adduser.png"
          text="Ajouter client"
          onClick={handleAjouterClientClick}
          background="background"
        />    
    {selectedClient && (
  <Buttons1
    icon="/Images/Clients/update.png"
    text="Modifier Client"
    onClick={handleModifierClientClick}
    background="background2"
  />
)}
        {isModalOpen && <AddClient onCloseModal={handleCloseModal} />}
        {isUpdateClientOpen && <UpdateClient onCloseModal={handleCloseModalUpdate}  selectedClient={selectedClient} />}
      </div>
      {selectedClient?.id !== null && <Buttons2 onAjouterAnimalClick={handleAjouterAnimalClick} />}
      {isModalOpen1 && selectedClient && (
  <AddAnimal selectedClientId={selectedClient.id} onCloseModal={handleCloseModal1} />
)}

    </div>
  );
};

export default ButtonsBar;
