import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "../../css/BasicInfo.module.css";
import { GetAnimalByIdClientId  } from "../../api/api";
import { useParams } from "react-router-dom";
import { useBarcode } from '@createnextapp/react-barcode';
import { UpdateAnimalapi } from "../../api/api";
import { format } from 'date-fns-tz';

const BasicInfo: FunctionComponent = () => {
  const [animalData, setAnimalData] = useState({
    name: "",
    race: "",
    robe: "",
    clientId: "",
    hidden:false,
    age: '',
  });
  const [agedate, setagedate] = useState<string | null>(null);

  const { id,selectedClientId } = useParams(); 
  useEffect(() => {
    GetAnimalByIdClientId (id,selectedClientId) 
      .then((data) => {
        setAnimalData(data.animal); 
        const defaultAgeDate = data?.animal?.age ? format(new Date(data.animal.age), "yyyy-MM-dd'T'HH:mm", { timeZone: 'Africa/Algiers' }) : null;
        setagedate(defaultAgeDate);
      })
      .catch((error) => {
        console.error("Une erreur s'est produite lors de la récupération des données de l'animal : ", error);
      });
  }, []); 

  const handleButtonClick = async () => {
    try {
          const algeriaTimeZone = 'Africa/Algiers';
    const agee = agedate?new Date(agedate):null;
    const formattedage = agee?format(agee, "yyyy-MM-dd'T'HH:mm", { timeZone: algeriaTimeZone }):null;
   console.log(formattedage)
    animalData.age=formattedage?formattedage:"";
    console.log(animalData)
      if (!animalData || !animalData.name || !animalData.race || !animalData.robe || !animalData.age) {
        console.error('Veuillez remplir tous les champs correctement.');
        return;
      }
       await UpdateAnimalapi(animalData);
    } catch (error:any) {
      console.error('Erreur lors de l\'ajout de l\'animal:', error.message);
    }
  };
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAnimalData((prevData) => ({ ...prevData, [name]: value }));
  };


  return (
    <div className={styles.basicInfo} data-animate-on-scroll>
      <div className={styles.head}>
        <div className={styles.headChild} />
        <b className={styles.overview}>{animalData ? animalData.name : ''}</b>
      </div>
      <div className={styles.inputs}>
      <div className={styles.input1}>
        <div className={styles.fullName}>Full Name</div>
        <input
          className={styles.input1Child}
          onChange={handleChange}
          name="name"
          value={animalData ? animalData.name : ''}
          type="text"
        />
      </div>
      <div className={styles.input1}>
        <div className={styles.fullName}>Race</div>
        <input
          className={styles.input1Child}
          onChange={handleChange}
          name="race"
          value={animalData ? animalData.race : ''}
          type="text"
        />
      </div>
      <div className={styles.input1}>
        <div className={styles.robe}>
          <p className={styles.robe1}>Robe</p>
        </div>
        <input
          className={styles.input1Child}
          onChange={handleChange}
          name="robe"
          value={animalData ? animalData.robe : ''}
          type="text"
        />
      </div>
      <div className={styles.input1}>
        <div className={styles.fullName}>Date Naissance</div>
        <input
           placeholder="Âge"
           type="datetime-local"  
           name="age"
           value={agedate?agedate:''}
           onChange={(e) =>setagedate(e.target.value) }
          className={styles.input1Child}
        />
      </div>
        <button className={styles.btn2BlueL} onClick={handleButtonClick}>
        <div className={styles.text}>
          <div className={styles.saveChanges}>Save Changes</div>
        </div>
      </button>
      </div>
    
    </div>
  );
};

export default BasicInfo;
