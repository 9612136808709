import React, { FunctionComponent, useState,useEffect } from 'react';
import '../../css/tableaddserv1.css'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
} from '@material-ui/core';
import { TablePagination } from '@mui/base/TablePagination';
   

interface TableAddserv1 {
  classcolor:string;
  data:any;
}

const TableAddserv1:  FunctionComponent<TableAddserv1> = ({classcolor,data}) =>{
  console.log(data)
    return (
        <div className="addserv1">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classcolor}>
                <TableSortLabel>
                    ID
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classcolor}>
                <TableSortLabel>
                    Nom
                    </TableSortLabel>
                </TableCell>
                <TableCell className={classcolor}>
                <TableSortLabel>
                    Qte
                    </TableSortLabel>
                </TableCell>
                <TableCell className={classcolor}>
                 <TableSortLabel>
                Type
                 </TableSortLabel>
                </TableCell>
                <TableCell className={classcolor}>
                 <TableSortLabel>
                Date de peremption
                 </TableSortLabel>
                </TableCell>
                
                <TableCell className={classcolor}>
                 <TableSortLabel>
                    Prix de vente
                 </TableSortLabel>
                </TableCell>
                <TableCell className={classcolor}>
                   action
                </TableCell>
              
              </TableRow>
            </TableHead>
            <TableBody>
            {Array.isArray(data) && data.length > 0 ? (
            data.map((product: any) => (
              <TableRow key={product.id}>
                <TableCell className='cellprod'>{product.id}</TableCell>
                <TableCell className='cellprod'>{product.name}</TableCell>
                <TableCell className='cellprod'>
                  {product.qtDispo !== undefined ? (
                    product.qtDispo
                  ) : product.products && product.products.length ? (
                    product.products.length + ' produits'
                  ) : (
                    'Aucune quantité'
                  )}
                </TableCell>
                <TableCell className='cellprod'>{product.qtDispo !== undefined ? (
                    "P"
                  ) : product.products && product.products.length ? (
                    "  S  "
                  ) : (
                    'Aucune Type'
                  )}</TableCell>
                <TableCell className='cellprod'>
                  {product.dateDePeremption ? (
                    new Date(product.dateDePeremption).toISOString().split('T')[0]
                  ) : (
                    'Aucune date'
                  )}
                </TableCell>
                <TableCell className='cellprod'>{product.prix}</TableCell>
                <TableCell className='cellprod'>      
                 <button className='btn-add-point'
      // onClick={() => {
      //   chartHandler(element);
      // }}
      >Ajouter</button>
      </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell  className='cellprod'>
                Aucun produit disponible.
              </TableCell>
            </TableRow>
          )}
        </TableBody>


            </Table>
            
            </div>
            );
};
export default TableAddserv1;