import React, { FunctionComponent, useState,useEffect } from 'react';
import '../../css/tableaddserv2.css'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
} from '@material-ui/core';
import { TablePagination } from '@mui/base/TablePagination';
interface Element {
  id: string;
  type: string;
  qtDispo: number;
  qtInChart: number ;
}
interface TableAddserv2 {
  classcolor:string;
  chart:Element[]
}

const TableAddserv2: FunctionComponent<TableAddserv2> = ({classcolor,chart}) =>{
 console.log(chart)
    return (
        <div className="addserv2">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classcolor}>
                <TableSortLabel>
                    P/S
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classcolor}>
                <TableSortLabel>
                    Prix
                    </TableSortLabel>
                </TableCell>
                <TableCell className={classcolor}>
                <TableSortLabel>
                    Qte
                    </TableSortLabel>
                </TableCell>
                <TableCell className={classcolor}>
                 <TableSortLabel>
                 Sub-totale
                 </TableSortLabel>
                </TableCell>
            
              </TableRow>
            </TableHead>
            


            </Table>
            
            </div>
            );
};
export default TableAddserv2;