import { useReactToPrint } from "react-to-print";

import {
  plusIcon,
  deleteIcon,
  editIcon,
  filtersIcon,
  frwrd,
  moreIcon,
  options,
  rtrn,
  searchIcon,
  downIcon,
  minusIcon,
  arrowUpIcon,
  linkIcon,
  cancelBlack,
  cancelWhite,
  arrowIcon,
  printIcon,
  whitePlusIcon,
} from "../../assets/icons";
import "./caissesList.css";
import { instance,notify } from "../../api/api";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
const pager = (length, perPage) => {
  let actualLength = length / perPage;
  let pages = [];
  for (let i = 0; i < actualLength; i = i + 1) {
    pages[i] = i + 1;
  }
  return pages;
};

export default function CaissesList() {
  const [ventes, setVentes] = useState([]);
  const [perPage, setPerPage] = useState(11);
  const [active, setActive] = useState(1);
  const [activeTransaction, setActiveTransaction] = useState({});
  const [deleteWindow, setDeleteWindow] = useState(false);
  const [selected, setSelected] = useState([]);
  const [activeOption, setActiveOption] = useState("");
  const [products, setProducts] = useState([]);
  const [stock, setStock] = useState(0);
  const [filtred, setFiltred] = useState([]);
  const [filterActive, setFilterActive] = useState("");
  const [pages, setPages] = useState([]);
  const [cs_r, setcs_r] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [filtertype, setfiltertype] = useState(0);
  const [numbers, setNumbers] = useState({
    gains: 0,
    retrait: 0,
  });
  const [transactions, setTransactions] = useState([]);
  const [overlay, setOverlay] = useState(false);
  const [activeStatus, setActiveStatus] = useState("not-payed");
  const [retrait, setRetrait] = useState(0);
  const [payement, setPayement] = useState(0);
  const [validator, setValidator] = useState(false);
  const componentRef2 = useRef(null);
  const [timeFilter, setTimeFilter] = useState([]);
  const [reversed, setReversed] = useState(false);
  const [motif, setMotif] = useState("");
  const [ret, setRet] = useState(0);
  const [sortType,setSortType] = useState("name")
  const [sortModifier,setSortModifier] = useState("asc")


 const  handelSort =(type)=>{
        let newSortModifier;
        if(sortModifier==="asc"){newSortModifier = "desc"}else{newSortModifier ="asc"}
        if(type ==="ouverture"){
          if(sortType !== "ouverture") {setSortType("ouverture")}
          if(newSortModifier==="asc"){
          let newCaissesList = caisses.sort((a,b)=>{
            let nameA = parseInt(a.ouverture)
            let nameB = parseInt(b.ouverture)
            if (nameA < nameB) {
              return -1;
            } else if (nameA > nameB) {
              return 1;
            } else {
              return 0;
            }
          })
          setCaisses(newCaissesList)
          setSortModifier(newSortModifier)
          }
          if(newSortModifier==="desc"){
            let newCaissesList = caisses.sort((a,b)=>{
              let nameA = parseInt(a.ouverture)
              let nameB = parseInt(b.ouverture)
              if (nameA > nameB) {
                return -1;
              } else if (nameA < nameB) {
                return 1;
              } else {
                return 0;
              }
            })
            setCaisses(newCaissesList)
            setSortModifier(newSortModifier)
            }
        }
        if(type ==="user"){
          if(sortType !== "user") {setSortType("user")}
          if(newSortModifier==="asc"){
          let newCaissesList = caisses.sort((a,b)=>{
            let nameA = a.user.email.toUpperCase()
            let nameB = b.user.email.toUpperCase()
            if (nameA < nameB) {
              return -1;
            } else if (nameA > nameB) {
              return 1;
            } else {
              return 0;
            }
          })
          setCaisses(newCaissesList)
          setSortModifier(newSortModifier)
          }
          if(newSortModifier==="desc"){
            let newCaissesList = caisses.sort((a,b)=>{
              let nameA = parseInt(a.ouverture)
              let nameB = parseInt(b.ouverture)
              if (nameA > nameB) {
                return -1;
              } else if (nameA < nameB) {
                return 1;
              } else {
                return 0;
              }
            })
            setCaisses(newCaissesList)
            setSortModifier(newSortModifier)
            }
        }
        if(type ==="gains"){
          if(sortType !== "gains") {setSortType("gains")}
          if(newSortModifier==="asc"){
          let newCaissesList = caisses.sort((a,b)=>{
            let nameA = parseInt(a.gains)
            let nameB = parseInt(b.gains)
            if (nameA < nameB) {
              return -1;
            } else if (nameA > nameB) {
              return 1;
            } else {
              return 0;
            }
          })
          setCaisses(newCaissesList)
          setSortModifier(newSortModifier)
          }
          if(newSortModifier==="desc"){
            let newCaissesList = caisses.sort((a,b)=>{
              let nameA = parseInt(a.gains)
              let nameB = parseInt(b.gains)
              if (nameA > nameB) {
                return -1;
              } else if (nameA < nameB) {
                return 1;
              } else {
                return 0;
              }
            })
            setCaisses(newCaissesList)
            setSortModifier(newSortModifier)
            }
        }
        if(type ==="retrait"){
          if(sortType !== "retrait") {setSortType("retrait")}
          if(newSortModifier==="asc"){
          let newCaissesList = caisses.sort((a,b)=>{
            let nameA = parseInt(a.retraits.reduce((acc,retrait)=>{acc += retrait.montant;return acc},0))
            let nameB = parseInt(b.retraits.reduce((acc,retrait)=>{acc += retrait.montant;return acc},0))
            if (nameA < nameB) {
              return -1;
            } else if (nameA > nameB) {
              return 1;
            } else {
              return 0;
            }
          })
          setCaisses(newCaissesList)
          setSortModifier(newSortModifier)
          }
          if(newSortModifier==="desc"){
            let newCaissesList = caisses.sort((a,b)=>{
              let nameA = parseInt(a.retraits.reduce((acc,retrait)=>{acc += retrait.montant;return acc},0))
              let nameB = parseInt(b.retraits.reduce((acc,retrait)=>{acc += retrait.montant;return acc},0))
              if (nameA > nameB) {
                return -1;
              } else if (nameA < nameB) {
                return 1;
              } else {
                return 0;
              }
            })
            setCaisses(newCaissesList)
            setSortModifier(newSortModifier)
            }
        }
        if(type ==="reste"){
          if(sortType !== "reste") {setSortType("reste")}
          if(newSortModifier==="asc"){
          let newCaissesList = caisses.sort((a,b)=>{
            let nameA = parseInt(a.gains)+parseInt(a.ouverture)-parseInt(a.retraits.reduce((acc,retrait)=>{acc += retrait.montant;return acc},0))
            let nameB = parseInt(b.gains)+parseInt(b.ouverture)-parseInt(b.retraits.reduce((acc,retrait)=>{acc += retrait.montant;return acc},0))
            if (nameA < nameB) {
              return -1;
            } else if (nameA > nameB) {
              return 1;
            } else {
              return 0;
            }
          })
          setCaisses(newCaissesList)
          setSortModifier(newSortModifier)
          }
          if(newSortModifier==="desc"){
            let newCaissesList = caisses.sort((a,b)=>{
              let nameA = parseInt(a.gains)+parseInt(a.ouverture)-parseInt(a.retraits.reduce((acc,retrait)=>{acc += retrait.montant;return acc},0))
              let nameB = parseInt(b.gains)+parseInt(b.ouverture)-parseInt(b.retraits.reduce((acc,retrait)=>{acc += retrait.montant;return acc},0))
              if (nameA > nameB) {
                return -1;
              } else if (nameA < nameB) {
                return 1;
              } else {
                return 0;
              }
            })
            setCaisses(newCaissesList)
            setSortModifier(newSortModifier)
            }
        }
        if(type ==="date"){
          if(sortType !== "date") {setSortType("date")}
          if(newSortModifier==="asc"){
          let newCaissesList = caisses.sort((a,b)=>{
            var dateA = new Date(a.createdAt )
            var dateB = new Date(b.createdAt )
        
            if (dateA === null && dateB === null) {
              return 0; // If both dates are null, maintain the order
            } else if (dateA === null) {
              return 1; // Place null values at the end
            } else if (dateB === null) {
              return -1; // Place null values at the end
            } else {
              return dateA - dateB; // Compare the valid dates
            }
          })
          setCaisses(newCaissesList)
          setSortModifier(newSortModifier)
          }
          if(newSortModifier==="desc"){
            let newCaissesList = caisses.sort((a,b)=>{
              var dateA = new Date(a.createdAt )
              var dateB = new Date(b.createdAt )
          
              if (dateA === null && dateB === null) {
                return 0; // If both dates are null, maintain the order
              } else if (dateB === null) {
                return 1; // Place null values at the end
              } else if (dateA === null) {
                return -1; // Place null values at the end
              } else {
                return dateB - dateA; // Compare the valid dates
              }})
              setCaisses(newCaissesList)
            setSortModifier(newSortModifier)
            }
        }


 }











  const dispatch = useDispatch();

  const caisse = useSelector((state) => state.caisseReducer.caisse);
  const auth = useSelector((state) => state.authReducer.auth);
  const [caisses, setCaisses] = useState([]);


 

  const handelCloture = async () => {
    try {
      if (!selected[0]) {
       notify("error", "vous devez selectioner une caisse!");
        return;
      }
      let user = auth?.user;
      let caisse = caisses.filter((caisse) => caisse.id === selected[0])[0];
      if (user?.id !== caisse?.user?.id) {
       notify("error", "caisse opened by another user!");
        return;
      }
      if (!caisse?.open) {
       notify("error", "caisse deja cloturée!");
        return;
      }
      let url = `/caisse/${caisse?.id}`;
      let data = { open: false };
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "fournisseurs",
      };
 const res= await instance
        .put(url, { data }, { headers })
          if (res?.data?.updated) {
            notify("success", "Caisse Cloturée");
            dispatch({ type: "setCaisse", payload: {} });
            setValidator((state) => !state);
            return;
          }
         notify("error", res.data.message);
    } catch (err) {
     notify("error", err.message);
    }
  };

  const handelRetrait = async () => {
    try {
      let limit = parseInt(cs_r.ouverture) + parseInt(cs_r.gains)-parseInt(cs_r.retraits.reduce((acc,retrait)=>{acc += retrait.montant;return acc},0))
      if (parseInt(retrait) === 0 || !retrait || retrait > limit) {
       notify("error", "montant invalide");
        return;
      }
      if (!cs_r) {
       notify("error", "vous devez selectioner une caisse!");
        return;
      }

      let url = `/retrait`;
      let data = {
        montant: parseInt(retrait),
        motif: motif,
        caisseId: cs_r.id,
      };
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "fournisseurs",
      };
      const res= await instance
        .post(url, { data }, { headers })

          if (res?.data?.retrait) {
            notify("success", "Retrait Validé !");
            setOverlay(false);
            setRetrait(0);
            setSelected([]);
            setcs_r({});
            setValidator((state) => !state);
            return;
          }

         notify("error", res.data.message);
    } catch (err) {
     notify("error", err.message);
    }
  };

  const handelOverlay = () => {
    setOverlay((state) => !state);
  };

  const handelmotif = (event) => {
    setMotif(event.target.value);
  };
  const handelretrait = (event) => {
    setRetrait(event.target.value);
  };



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.classList.contains("clicked")) {
        handelOptions("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [activeOption]);
  useEffect(() => {
    try {
      setPages(pager(caisses?.length, perPage));
    } catch (err) {
    notify("error", err.message);
    }
  }, [caisses]);
  useEffect(() => {
    const getcaisses = async () => {
      let url = "/caisse";
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "produit",
      };
      const res= await instance
        .get(url, { headers })
       try {
          if (res?.data?.caisses) {
            let active = res.data.caisses.filter(
              (caisse) => caisse.open && caisse.userId === auth.user.id
            )[0];
            if (active?.id) {
              dispatch({ type: "setCaisse", payload: active });
            }

            let newCaissesList = res.data.caisses.sort((a,b)=>{
              var dateA = new Date(a.createdAt )
              var dateB = new Date(b.createdAt )
          
              if (dateA === null && dateB === null) {
                return 0; // If both dates are null, maintain the order
              } else if (dateA === null) {
                return -1; // Place null values at the end
              } else if (dateB === null) {
                return 1; // Place null values at the end
              } else {
                return  dateB-dateA ; // Compare the valid dates
              } })
              setSortType("date")
              setSortModifier("desc")
            setCaisses(newCaissesList);
          }
        }
        catch(err)  {
          notify("error", err.message);
        };
    };
    const getventes = async () => {
      let url = "/vente/vente_today";
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "produit",
      };

      try{
const res= await instance
        .get(url, { headers })
          if (res?.data?.ventes) {
            setVentes(res.data.ventes);
            let todays_gains = res.data.ventes.reduce((acc, vente) => {
              acc = acc + parseInt(vente?.payed);
              return acc;
            }, 0);
            setNumbers((state) => {
              let news = state;
              news.gains = todays_gains;
              return news;
            });
          }
        }
        catch(err){
        notify("error", err.message);
        };
    };
    const getretrait = async () => {
      let url = "/retrait";
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      try{
      const res= await instance
        .get(url, { headers })
 
          if (res?.data?.retraits) {
            let todays_retrait = res.data.retraits.reduce((acc, retrait) => {
              acc = acc + parseInt(retrait?.montant);
              return acc;
            }, 0);
            setNumbers((state) => {
              let news = state;
              news.retrait = todays_retrait;
              return news;
            });
          }
        }
        catch(err) {
        notify("error", err.message);
        };
    };
    try {
      getretrait();
      getcaisses();
      getventes();
    } catch (err) {
     notify("error", err.message);
    }
  }, []);
  useEffect(() => {
    const getcaisses = async () => {
      let url = "/caisse";
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "produit",
      };
      const res= await instance
        .get(url, { headers })
        try{
          if (res?.data?.caisses) {
            let active = res.data.caisses.filter(
              (caisse) => caisse.open && caisse.userId === auth.user.id
            )[0];
            if (active?.id) {
              dispatch({ type: "setCaisse", payload: active });
            }
            let newCaissesList = res.data.caisses.sort((a,b)=>{
              var dateA = new Date(a.createdAt )
              var dateB = new Date(b.createdAt )
          
              if (dateA === null && dateB === null) {
                return 0; // If both dates are null, maintain the order
              } else if (dateA === null) {
                return -1; // Place null values at the end
              } else if (dateB === null) {
                return 1; // Place null values at the end
              } else {
                return  dateB-dateA ; // Compare the valid dates
              } })
              setSortType("date")
              setSortModifier("desc")
            setCaisses(newCaissesList);
          }
        }
        catch(err)  {
        notify("error", err.message);
        };
    };
    const getventes = async () => {
      let url = "/vente/vente_today";
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "produit",
      };
      const res= await instance
        .get(url, { headers })
        try {
          if (res?.data?.ventes) {
            setVentes(res.data.ventes);
            let todays_gains = res.data.ventes.reduce((acc, vente) => {
              acc = acc + parseInt(vente?.payed);
              return acc;
            }, 0);
            setNumbers((state) => {
              let news = state;
              news.gains = todays_gains;
              return news;
            });
          }
        }
        catch(err) {
        notify("error", err.message);
        };
    };
    const getretrait = async () => {
      let url = "/retrait";
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      const res= await instance
        .get(url, { headers })
        try {
          if (res?.data?.retraits) {
            let todays_retrait = res.data.retraits.reduce((acc, retrait) => {
              acc = acc + parseInt(retrait?.montant);
              return acc;
            }, 0);
            setNumbers((state) => {
              let news = state;
              news.retrait = todays_retrait;
              return news;
            });
          }
        }
        catch(err)  {
        notify("error", err.message);
        };
    };
    try {
      getretrait();
      getventes();
      getcaisses();
      setSelected([])
    } catch (err) {
     notify("error", err.message);
    }
  }, [validator]);

  const handelselect = (id) => {
    if (selected[0] === id) {
      setSelected([]);
    } else {
      setSelected([id]);
    }
  };
  // const handelselect = (id) => {
  //   const isFound = selected.some((element) => {
  //     if (element === id) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });
  //   if (isFound) {
  //     setSelected((state) => state.filter((idt) => idt != id));
  //   } else {
  //     setSelected((state) => [...state, id]);
  //   }
  // };
  const handelnavigation = (type, page) => {
    if (type === "return") {
      if (active > 1) {
        setActive((state) => state - 1);
      }
    }
    if (type === "frwrd") {
      if (active < pages.at(-1)) {
        setActive((state) => state + 1);
      }
    }
    if (type === "page") {
      setActive(page);
    }
  };
  const handelOptions = (id) => {
    setActiveOption((state) => {
      if (state === id) {
        return "";
      }
      if (id === "") {
        return "";
      }
      return id;
    });
  };

  return (
    <div className="page_container">
     
      <Toaster
        toastOptions={{
          style: {
            background: "transparent",
            border: "none",
            boxShadow: "none",
            color: "#ffffff",
          },
        }}
      />

      {overlay ? (
        <div className="overlaays">
          <div className="add-payement-windowss">
            <img src={cancelBlack} alt="cancel" onClick={handelOverlay} />

            <div className={`amount `}>
              <p>Le montant :</p>{" "}
              <input
                onChange={(event) => {
                  handelretrait(event);
                }}
                name="retrait"
                type="number"
              />
              <p> DZD</p>
            </div>

            {parseInt(cs_r.ouverture) + parseInt(cs_r.gains) <
              parseInt(retrait) || parseInt(retrait) === 0 ? (
              <p style={{ color: "#FF5B5B", fontSize: "smaller" }}>
                Un retrait valide doit être entre 0 DZD et{" "}
                {parseInt(cs_r.ouverture) + parseInt(cs_r.gains)-parseInt(cs_r.retraits.reduce((acc,retrait)=>{acc += retrait.montant;return acc},0))} DZD
              </p>
            ) : (
              ""
            )}
            <div className="motif">
              <label>
                Motife du retrait
                <input
                  onChange={(event) => {
                    handelmotif(event);
                  }}
                  name="retrait"
                  type="text"
                />
              </label>
            </div>
            {/* <p className="p">{fournisseur.firstname}</p> */}
            <div className="btns">
              <div
                className="ajoutersss"
                onClick={(event) => {
                  if (!cs_r?.id) {
                   notify("error", "Vous devez selectioné au moin une Caisse !");
                    return;
                  }
                  handelRetrait();
                }}
              >
                <img src={cancelWhite} alt="delete" /> <p>Ajouter</p>
              </div>
              <div
                className="cancel"
                onClick={() => {
                  handelOverlay();
                  setRetrait(0);
                  setMotif("");
                  setPayement(0);
                }}
              >
                <img src={arrowIcon} alt="cancel" /> <p>Annuler</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="caisses_list">
        <div className="p1">
          <div className="container c1">
            <p>Ouverture</p>
            <p className="num">
              {caisse?.ouverture && caisse?.open
                ? caisse.ouverture.toLocaleString("en-DE")
                : "0"}{" "}
              DZD
            </p>
          </div>
          <div className="container c2">
            <p>Gains d’aujourd'hui</p>
            <p className="num">
              {numbers?.gains ? numbers.gains.toLocaleString("en-DE") : "0"} DZD
            </p>
          </div>
          <div className="container c2">
            <p>Retrait Aujourdhui</p>
            <p className="num">
              {numbers?.retrait ? numbers.retrait.toLocaleString("en-DE") : "0"}{" "}
              DZD
            </p>
          </div>
          <div className="container c3">
            <p>Reste</p>
            <p className="num">
              {}
              {caisse && caisse?.open && caisse?.retraits
                ? (
                    parseInt(caisse.ouverture) +
                    parseInt(caisse.gains) -
                    parseInt(caisse.retraits.reduce((acc,retrait)=>{acc += retrait.montant; return acc},0))
                  ).toLocaleString("en-DE")
                :caisse && caisse?.open? (
                    parseInt(caisse.ouverture) +
                    parseInt(caisse.gains) -
                    parseInt(0)
                  ).toLocaleString("en-DE") : "0"}{" "}
              DZD
            </p>
          </div>
        </div>
        {/* <div className="p1">
                    <div className="btn"><p>+Ajouter</p></div>
                    <div className="bih_holder "></div>
                    <div className="search_bar">
                        <img src={search} alt="search" />
                        <input name="search" placeholder="Recherche d’un fournisseur" type="text" />
                    </div>
                    <div className="little_holder"></div>
                    <div className="little_holder"></div>
                    <div className="little_holder"></div>

                </div> */}
        <div className="p2">
          <div className="hr">
            <p>Caisses</p>
            <p className="faded">Liste Des Caisses</p>
          </div>
          <div className="caisse_btns">
            <div className="btn" onClick={handelCloture}>
              <img src={whitePlusIcon} alt="plus" /> <p>Clôturer</p>
            </div>
            <div
              className="btn"
              onClick={() => {
                if (selected.length) {
                  setOverlay(true);
                  return;
                }
               notify("error", "vous devez selectioner une caisse");
              }}
            >
              <img src={whitePlusIcon} alt="plus" /> <p>Retrait</p>
            </div>
          </div>
        </div>
        <div className="p3">
          <div className=" table">
            <table>
              <thead>
                <tr style={{ borderTop: "1px solid #EAECF0 " }}>
                  <th className="th1" style={{ width: "1rem" }}>
                    <div className="th1d">
                      <img src={minusIcon} alt="minus" />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Date </p>{" "}
                      <img
                        style={{ cursor: "pointer" }}
                        src={sortModifier==="desc"?arrowUpIcon:downIcon}
                        alt="down"
                        onClick={() => {
                          handelSort("date")
                        }}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Ouverture </p>{" "}
                      <img
                        style={{ cursor: "pointer" }}
                        src={sortModifier==="desc"?arrowUpIcon:downIcon}
                        alt="down"
                        onClick={() => {
                          handelSort("ouverture")
                        }}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Gains </p>{" "}
                      <img
                        style={{ cursor: "pointer" }}
                        src={sortModifier==="desc"?arrowUpIcon:downIcon}
                        alt="down"
                        onClick={() => {
                         handelSort("gains")
                        }}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Retrait </p>{" "}
                      <img
                        style={{ cursor: "pointer" }}
                        src={sortModifier==="desc"?arrowUpIcon:downIcon}
                        alt="down"
                        onClick={() => {
                          handelSort("retrait")
                        }}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Reste </p>{" "}
                      <img
                        style={{ cursor: "pointer" }}
                        src={sortModifier==="desc"?arrowUpIcon:downIcon}
                        alt="down"
                        onClick={() => {
                          handelSort("reste")

                        }}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Ouverture par </p>{" "}
                      <img
                        style={{ cursor: "pointer" }}
                        src={sortModifier==="desc"?arrowUpIcon:downIcon}
                        alt="down"
                        onClick={() => {
                         handelSort("user")
                        }}
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              {caisses?.length ? (
                <tbody>
                  {caisses
                    .slice((active - 1) * 11, active * 11)
                    .map((caisse, index) => {
                      let retrait = caisse.retraits.reduce((acc, retrait) => {
                        acc += retrait.montant;
                        return acc;
                      }, 0);
                      return (
                        <tr
                          key={index}
                          style={{ borderTop: "1px solid #EAECF0 " }}
                        >
                          <td className="td1" style={{ width: "1rem" }}>
                            <div className="td1d">
                              <div
                                className={`checkBox ${
                                  selected.indexOf(caisse.id) >= 0
                                    ? "checked"
                                    : ""
                                }`}
                                onClick={() => {
                                  handelselect(caisse.id);
                                  setcs_r(caisse);
                                }}
                              ></div>
                            </div>
                          </td>
                          <td>
                            <div className="td2 lite">
                              <p>
                                {caisse?.createdAt
                                  ? caisse.createdAt.split("T")[0]
                                  : "--"}{" "}
                              </p>{" "}
                            </div>
                          </td>
                          <td>
                            <div className="td2">
                              <p>{caisse?.ouverture} </p>{" "}
                            </div>
                          </td>
                          <td>
                            <div className={`td2 lite `}>
                              <p>{caisse?.gains} </p>
                            </div>
                          </td>
                          <td>
                            <div className="td2 lite">
                              <p>{retrait} DA</p>
                            </div>
                          </td>
                          <td>
                            <div className={`td2 lite `}>
                              <p>
                                {caisse
                                  ? (
                                      parseInt(caisse?.gains) +
                                      parseInt(caisse?.ouverture) -
                                      parseInt(retrait)
                                    ).toLocaleString("en-DE")
                                  : "0"}{" "}
                                DA
                              </p>{" "}
                            </div>
                          </td>
                          <td>
                            <div className="td2">
                              <p>{caisse.user.email}</p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  {/* <tr style={{ borderTop: "1px solid #EAECF0 " }}>
                                    <td className="td1" style={{ width: "1rem" }}><div><input type="checkbox" /></div></td>
                                    <td><div className="td2"><p>Nom et Prénom </p> </div></td>
                                    <td><div className="td2 lite"><p>12/05/2023 </p> </div></td>
                                    <td><div className="td2 lite"><p>1000,00 DA </p> </div></td>
                                    <td><div className="td3" onClick={handelOptions}><img src={options} alt="options" /><div className="openProfile" style={activeOption ? { animation: "slideDown 0.5s ease-out" } : { display: "none" }} ><p>Oper Profile</p><img src={linkIcon} alt="profile" /></div></div></td>
                                </tr> */}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={7}>
                      <div className="no-fr">
                        <p>
                          Aucun produit n'est retrouver ! veuillez Ajouter Un!
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          <div
            className="p-n"
            style={{ width: "100%", margin: "auto auto 1rem auto" }}
          >
            <div className="pagination">
              <div
                className="nav"
                onClick={() => {
                  handelnavigation("return");
                }}
                style={
                  active === 1 || pages.length === 0
                    ? {
                        backgroundColor: "#F5F5F5",
                        opacity: ".6",
                        cursor: "default",
                      }
                    : {}
                }
              >
                <img src={rtrn} alt="return" />
              </div>
              {active >= 3 && pages.length > 3 ? (
                <div className="ect">...</div>
              ) : (
                ""
              )}
              {pages.length > 3
                ? active < 3 && active > 0
                  ? pages?.slice(0, 3).map((page, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            handelnavigation("page", page);
                          }}
                          className="page"
                          style={
                            active === page
                              ? { backgroundColor: "#100DB1" }
                              : {}
                          }
                        >
                          {" "}
                          <p style={page === active ? { color: "white" } : {}}>
                            {page}
                          </p>
                        </div>
                      );
                    })
                  : active > pages.length - 3
                  ? pages
                      ?.slice(pages.length - 3, pages.length)
                      .map((page, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              handelnavigation("page", page);
                            }}
                            className="page"
                            style={
                              active === page
                                ? { backgroundColor: "#100DB1" }
                                : {}
                            }
                          >
                            {" "}
                            <p
                              style={page === active ? { color: "white" } : {}}
                            >
                              {page}
                            </p>
                          </div>
                        );
                      })
                  : pages?.slice(active - 2, active + 2).map((page, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            handelnavigation("page", page);
                          }}
                          className="page"
                          style={
                            active === page
                              ? { backgroundColor: "#100DB1" }
                              : {}
                          }
                        >
                          {" "}
                          <p style={page === active ? { color: "white" } : {}}>
                            {page}
                          </p>
                        </div>
                      );
                    })
                : pages?.map((page, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          handelnavigation("page", page);
                        }}
                        className="page"
                        style={
                          active === page ? { backgroundColor: "#100DB1" } : {}
                        }
                      >
                        {" "}
                        <p style={page === active ? { color: "white" } : {}}>
                          {page}
                        </p>
                      </div>
                    );
                  })}

              {active < pages.length - 2 && pages.length > 3 ? (
                <div className="ect">...</div>
              ) : (
                ""
              )}
              <div
                className="frwrd"
                onClick={() => {
                  handelnavigation("frwrd");
                }}
                style={
                  active === pages.at(-1)
                    ? {
                        backgroundColor: " #F5F5F5",
                        opacity: ".6",
                        cursor: "default",
                      }
                    : {}
                }
              >
                <div
                  className="nav"
                  style={
                    active === pages.at(-1) || pages.length === 0
                      ? {
                          backgroundColor: " #F5F5F5",
                          opacity: ".6",
                          cursor: "default",
                        }
                      : {}
                  }
                >
                  <img src={frwrd} alt="next" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
