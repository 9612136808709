import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./pos.css";
import Select from "react-select";
import { useState, useEffect } from "react";
import { Reciept } from "../../components/Reciept/Reciept";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import {
  chartIcon,
  resetIcon,
  delete_itemIcon,
  searchGrayIcon,
  frwrd,
  rtrn,
  printIcon1,
} from "../../assets/icons";
import Cookies from "js-cookie";
import { notify,instance } from "../../api/api";
import toast, { Toaster } from "react-hot-toast";
export default function Pos() {
  const perPage = 6
  const [active, setActive] = useState(1);
  const [actives, setActives] = useState(1);
  const [selectedClient, setSelectedClient] = useState({});
  const [selectedAnimal, setSelectedAnimal] = useState({});
  const [clients, setClients] = useState([]);
  const [animals, setAnimals] = useState([]);
  const [services, setServices] = useState([]);
  const [products, setProducts] = useState([]);
  const [chart, setChart] = useState([]);
  const [list, setList] = useState([]);
  const [filtred, setFiltred] = useState([]);
  const [filterActive, setFilterActive] = useState("");
  const [servicesProductTracker, setServicesPorductTracker] = useState({});
  const [pages, setPages] = useState([]);
  const [pagess, setPagess] = useState([]);
  const [remise,setRemise] = useState(0)
  const [total, setTotal] = useState(0);
  const [payed, setPayed] = useState(0);
  const [motif,setMotif] = useState("")
  const [description,setDescription] = useState("")
  const [validator, setValidator] = useState(false);
  const [receiptnumber, setReceiptnumber] = useState("");
  const dispatch = useDispatch()
  const inputRef = useRef();
  const componentRef = useRef(null);
  const remiseRef = useRef(null)
  const motifref = useRef(null)
  const descref = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const printHandler = ()=>{
    if(!chart?.length){
     notify("error","chart empty")
      return
    }

    handlePrint();


  }
  const caisse = useSelector((state) => state.caisseReducer.caisse);
  const auth = useSelector((state) => state.authReducer.auth);


  const roles = auth?.roles;

  const redirect = () => {
    let routes =["Home","Clients","Animal","Produit","Ventes","caisse","RendezVous","Hospitalisation","Achats","Pointdevente","utilisateurs","CaisseGenerale"]
    if (roles === "000000000000") {
      window.location.href = "/";
    }
    window.location.href = `/${routes[roles.indexOf("1")]}`;
  };

  if (!caisse?.id) {
    redirect();
  }

  useEffect(() => {
    setTotal(
      chart.reduce((acc, chartElement) => {
        acc += chartElement?.prix * chartElement?.qtInChart;
        return acc;
        console.log(acc)
      }, 0)
    );
  }, [chart]);
  useEffect(() => {
    const getClients = async () => {
      try {
        let url = "/client";
        let headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          pm_auth: `${Cookies.get("auth_token")}`,
          checkfor: "client",
        };
        const res=await instance
          .get(url, { headers })
            if (res?.data?.clients) {
              setClients([
                { label: "guest", id: "none" },
                ...res.data.clients.map((client) => {
                  return {
                    ...client,
                    label: client.firstname + " " + client.lastname,
                  };
                }),
              ]);
            } else {
            notify("error", "Client Empty");
            }
      } catch (err) {
      notify("error", err.message);
      }
    };
    const getReceiptnumber = async () => {
      try {
        let url = "/vente/receiptnumber";
        let headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          pm_auth: `${Cookies.get("auth_token")}`,
          checkfor: "client",
        };
        const res=await instance.get(url, { headers })
            if (res?.data?.id) {
              setReceiptnumber(res.data.id);
            } else {
            notify("error", "Erorr: loading ID refrech the page please");
            }
          }
       catch (err) {
      notify("error", err.message);
      }
    };
    getReceiptnumber();
    getClients();
  }, []);
  useEffect(() => {
    const getAnimals = async () => {
      try {
        if (selectedClient?.id) {
          let url = `/animal/${selectedClient.id}`;
          let headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            pm_auth: `${Cookies.get("auth_token")}`,
            checkfor: "client",
          };
          const res=await instance
            .get(url, { headers })
            console.log(res)
              if (res?.data?.animal) {
                setAnimals(
                  res.data.animal.map((animal) => {
                    return { ...animal, label: animal?.name };
                  })
                );
              } else {
              notify("error", "somthing went wrong");
              }
        return;
            }
      } catch (err) {
      notify("error", err.message);
      }
    };

    getAnimals();
  }, [selectedClient]);

  const chartHandler = (element) => {
    if (element?.type === "P") {
      if (element.qtDispo < 1) {
      notify("error", "Quantité du produit Insufisante");
      }

      let alredyInChart = false;
      let newChart = [];
      if (chart?.length) {
        for (let el of chart) {
          if (el.id === element.id) {
            alredyInChart = true;
          }
        }
      }
      if (alredyInChart) {
        newChart = chart.map((chartElement) => {
          let tracker = servicesProductTracker[chartElement.id];
          if (!tracker) {
            tracker = 0;
          }
          if (
            chartElement.id === element.id &&
            chartElement?.qtInChart + tracker < element.qtDispo
          ) {
            chartElement.qtInChart = chartElement?.qtInChart + 1;
          }
          if (
            chartElement.id === element.id &&
            chartElement?.qtInChart + tracker >= element.qtDispo
          ) {
          notify("error", "quantité insufisante");
          }
          return chartElement;
        });
        setChart(newChart);
        return;
      } else {
        let tracker = servicesProductTracker[element.id];
        if (!tracker) {
          tracker = 0;
        }
        if (tracker < element.qtDispo) {
          setChart((state) => [...state, { ...element, qtInChart: 1 }]);
        }
        if (tracker >= element.qtDispo) {
        notify("error", "quantité insufisante");
        }
      }

      return;
    }
    if (element?.type === "S") {
      let newTracker = servicesProductTracker;
      if (element?.products?.length) {
        console.log(element)
        for (let product of element.products) {
          console.log(list)
          console.log(product)
          let qtDispo = list.filter(
            (listElement) => listElement.id === product.produitId
          )[0].qtDispo;
          let qtInChart = 0;
          let tracker = 0;
          if (servicesProductTracker[product.produitId]) {
            tracker = servicesProductTracker[product.produitId];
          }
          if (
            chart.filter(
              (chartElement) => chartElement.id === product.produitId
            )?.length
          ) {
            qtInChart = chart.filter(
              (chartElement) => chartElement.id === product.produitId
            )[0]?.qtInChart;
          }
       
          if (qtInChart + tracker + product.qt > qtDispo) {
          notify("error", "Quantité insufisante dans le stock");
            return;
          }
          if (servicesProductTracker[product.produitId]) {
            newTracker[product.produitId] =
              newTracker[product.produitId] + product.qt;
          } else {
            newTracker[product.produitId] = product.qt;
          }
        }
        setServicesPorductTracker(newTracker);
        let alredyInChart = false;
        let newChart = [];
        if (chart?.length) {
          for (let el of chart) {
            if (el.id === element.id) {
              alredyInChart = true;
            }
          }
        }
        if (alredyInChart) {
          newChart = chart.map((chartElement) => {
            if (chartElement.id === element.id) {
              chartElement.qtInChart = chartElement?.qtInChart + 1;
            }

            return chartElement;
          });
          setChart(newChart);
          return;
        }
        setChart((state) => [...state, { ...element, qtInChart: 1 }]);
        return;
      }
      if (!element?.products?.length) {
        let alredyInChart = false;
        let newChart = [];
        if (chart?.length) {
          for (let el of chart) {
            if (el.id === element.id) {
              alredyInChart = true;
            }
          }
        }
        if (alredyInChart) {
          newChart = chart.map((chartElement) => {
            if (chartElement.id === element.id) {
              chartElement.qtInChart = chartElement?.qtInChart + 1;
            }

            return chartElement;
          });
          setChart(newChart);
          return;
        }
        setChart((state) => [...state, { ...element, qtInChart: 1 }]);
        return;
      }
    }
  };
  const deleteHandler = async (element) => {
    if (element?.type === "P") {
      let newChart = chart.filter(
        (chartElement) => element.id !== chartElement.id
      );
      setChart(newChart);
    }
    if (element?.type === "S") {
      let newTracker = servicesProductTracker;
      let newChart = chart.filter(
        (chartElement) => element.id !== chartElement.id
      );
      if (element?.products) {
        for (let el of element.products) {
          newTracker[el.produitId] = 0;
        }
      }
      setServicesPorductTracker(newTracker);
      setChart(newChart);
    }
    return;
  };
  const minusHandler = (element) => {
    if (element?.type === "P") {
      let newChart = chart
        .map((chartElement) => {
          if (element.id === chartElement.id && chartElement?.qtInChart > 0) {
            chartElement.qtInChart = chartElement?.qtInChart - 1;
          }
          if (chartElement?.qtInChart > 0) {
            return chartElement;
          }
          return undefined;
        })
        .filter((element) => element !== undefined);
      setChart(newChart);
      return;
    }
    if (element?.type === "S") {
      let newTracker = servicesProductTracker;
      let newChart = chart
        .map((chartElement) => {
          if (element.id === chartElement.id && chartElement?.qtInChart > 0) {
            chartElement.qtInChart = chartElement?.qtInChart - 1;
          }
          if (chartElement?.qtInChart > 0) {
            return chartElement;
          }
          return undefined;
        })
        .filter((element) => element !== undefined);
      if (element?.products) {
        for (let el of element.products) {
          newTracker[el.produitId] = newTracker[el.produitId] - el.qt;
        }
      }
      setServicesPorductTracker(newTracker);

      setChart(newChart);
      return;
    }
  };
  const handelnavigations = (type, page) => {
    if (type === "return") {
      if (actives > 1) {
        setActives((state) => state - 1);
      }
    }
    if (type === "frwrd") {
      if (actives < pages.at(-1)) {
        setActives((state) => state + 1);
      }
    }
    if (type === "page") {
      setActives(page);
    }
  };
  const handelnavigation = (type, page) => {
    if (type === "return") {
      if (active > 1) {
        setActive((state) => state - 1);
      }
    }
    if (type === "frwrd") {
      if (active < pages.at(-1)) {
        setActive((state) => state + 1);
      }
    }
    if (type === "page") {
      setActive(page);
    }
  };
  const pager = (length, perPage) => {
    let actualLength = length / perPage;
    let pages = [];
    for (let i = 0; i < actualLength; i = i + 1) {
      pages[i] = i + 1;
    }
    return pages;
  };





  const payementHandler = async (print) => {
    try {
      if (!chart?.length) {
      notify("error", "Aucun element selectioné!");
        return;
      }
      let url = "/vente";
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "produit",
      };
      let data = {};
      data.motif = motif
      data.description = description
      let products = chart
        .filter((chartElement) => chartElement.type === "P")
        .map((product) => {
          return { ...product, qt: product?.qtInChart };
        });
      if (products.length) {
        data.products = [...products];
      }
      let services = chart
        .filter((chartElement) => chartElement.type === "S")
        .map((service) => {
          return { ...service, qt: service?.qtInChart };
        });
      if (services.length) {
        data.services = [...services];
      }
      data.payed = payed;
      
      if (selectedClient?.id && selectedClient?.id !== "none") {
        data.clientId = selectedClient.id;
      }
      if (selectedAnimal?.id) {
        data.animalId = selectedAnimal.id;
      }
      data.prix = total;
        if(remise){
        data.prix = remise
      }
      console.log("data",data)
      const res=await instance
        .post(url, { ...data, caisseId: caisse?.id }, { headers })
        console.log("creation",res)
          if (res?.data?.success
            && res?.data?.updateCaisse) {
            setValidator((state) => !state);
            notify("success","ventes ajoutée!")
            dispatch({ type: "setCaisse", payload: res?.data?.updateCaisse });
            if(print){printHandler()}
            setChart([]);
            setServicesPorductTracker({});
            inputRef.current.value = 0;
            remiseRef.current.value = 0
            descref.current.value = ""
            motifref.current.value = ""
            setSelectedAnimal(null)
            setSelectedClient(null)
            return
          }
        notify("error","somthing went wrong")
    } catch (err) {
    notify("error", err.message);
    }
  };

  useEffect(() => {
    if (products?.length && services?.length) {
      setList([...products, ...services]);
      return;
    }
    if (products?.length) {
      setList([...products]);
      return;
    }
    if (services?.length) {
      setList([...services]);
      return;
    }
  }, [products, services]);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#f3f4f6",
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  useEffect(() => {
    try {
      setPages(
        pager(list?.filter((element) => element.type === "P")?.length, perPage)
      );
      setPagess(
        pager(list?.filter((element) => element.type === "S")?.length, perPage)
      );
    } catch (err) {
      notify("errcatch", err.message);
    }
  }, [list]);
  useEffect(() => {
    const getProducts = async () => {
      let url = "/product";
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
      };
      try{
      const res=await instance
        .get(url, { headers })
          if (res?.data?.products) {
            setProducts(
              res.data.products.map((product) => {
                return { ...product, type: "P" };
              })
            );
          }
        }catch(err){
          notify("errcatch", err.message);
        };
    };
    if (receiptnumber) {
      setReceiptnumber((state) => parseInt(parseInt(state) + 1));
    }
    const getServices = async () => {
      let url = "/service";
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "produit",
      };
      try{
      const res=await instance
        .get(url, { headers })
        console.log(res)
          if (res?.data) {
            let servicesList = res.data.map((service) => {
              return { ...service, type: "S" };
            })
            setServices(
              servicesList
            );
          }
        }
        catch(err)  {
          notify("errcatch", err.message);
        };
    };
    try {
      getProducts();
      getServices();
    } catch (err) {
    notify("error", err.message);
    }
  }, [validator]);

  const searchHandler = (event) => {
    setFilterActive(event.target.value);
    setActive(1);
    setActives(1);
    setFiltred((state) =>
      list.filter((element) => {
        return (
          element?.name
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          element?.barCode
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        );
      })
    );
  };
  useEffect(() => {
    if (filterActive) {
      setPages(pager(filtred.length, perPage));
      setActive(1);
    }
  }, [filterActive, filtred]);
  return (
    <div className="page_pos_container">
      <div style={{ display: "none" }}>
        <Reciept
          payed={payed}
          receiptnumber={receiptnumber}
          chart={chart}
          total={total}
          client={selectedClient}
          animal={selectedAnimal}
          ref={componentRef}
        />
      </div>
      <Toaster
        toastOptions={{
          style: {
            background: "transparent",
            border: "none",
            boxShadow: "none",
            color: "#ffffff",
          },
        }}
      />
      <div className="p-r">
        <div
          className="header"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.location.href = "/ventes";
          }}
        >
        </div>
        <div className="body">
          <p>Selectionner un client</p>
          <div className="select">
            <Select
            value={selectedClient}
              styles={customStyles}
              onChange={(e) => {
                setSelectedClient(e);
              }}
              options={clients}
              getOptionValue={(client) => client.id}
            />
          </div>
          <p>Selectionner un animal</p>

          <div className="select">
            <Select
                        value={selectedAnimal}

              styles={customStyles}
              onChange={(e) => {
                setSelectedAnimal(e);
              }}
              options={animals}
              getOptionValue={(animal) => animal.id}
            />
          </div>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>P/S</th>
                  <th>prix</th>
                  <th>Qte</th>
                  <th>sub-totale</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {chart?.length
                  ? chart.map((chartElement, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="td">
                              {chartElement?.barCode} <br />{" "}
                              {chartElement?.name}
                            </div>
                          </td>
                          <td>
                            <div className="td">
                              {chartElement?.prix.toLocaleString("en-DE")} DZD
                            </div>
                          </td>
                          <td>
                            <div className="td">
                              <div className="qte">
                                <div
                                  className="minus"
                                  onClick={() => {
                                    minusHandler(chartElement);
                                  }}
                                >
                                  -
                                </div>
                                <input
                                  type="number"
                                  disabled={true}
                                  value={chartElement?.qtInChart}
                                />
                                <div
                                  className="plus"
                                  onClick={(e) => {
                                    chartHandler(chartElement);
                                  }}
                                >
                                  +
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="td">
                              {(
                                chartElement?.qtInChart * chartElement?.prix
                              ).toLocaleString("en-DE")}{" "}
                              DZD
                            </div>
                          </td>
                          <td>
                            <div
                              className="td"
                              onClick={() => {
                                deleteHandler(chartElement);
                              }}
                            >
                              <img src={delete_itemIcon} alt="delete_item" />
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>
          </div>
          <div className="total">
            <p>Total Payable : {total.toLocaleString("en-DE")} DZD</p>
          </div>
          <div className="payed">
            <label>
              Entrer le montant de remise en DZD
              <input
                ref={remiseRef}
                name="remise"
                type="number"
                defaultValue={0}
                onChange={(e) => {
                  if (e.target.value > total) {
                    e.target.value = 0;
                    return;
                  }
                  setRemise(e.target.value);
                }}
              />
            </label>
            <label>
              Entrer le montant payé en DZD
              <input
                ref={inputRef}
                name="payed"
                type="number"
                defaultValue={0}
                onChange={(e) => {
                  if (e.target.value > total) {
                  notify("error", "Montant Invalide");
                    e.target.value = 0;
                    return;
                  }
                  setPayed(e.target.value);
                }}
              />
            </label>
          </div>
          <div className="payed">
            <label>
              Entrer le motif des services
              <input
                ref={motifref}
                name="motif"
                type="text"
                defaultValue={""}
                placeholder="motif"
                onChange={(e) => {
                 
                  setMotif(e.target.value);
                }}
              />
            </label>
            <label>
              Entrer la description des services
              <input
                ref={descref}
                name="description"
                type="text"
                defaultValue={""}
                placeholder="description"
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </label>
          </div>
          <div className="btns">
            <div
              className="reset"
              onClick={() => {
                setChart([]);
                setServicesPorductTracker({});
                inputRef.current.value = 0;
              }}
            >
              <img src={resetIcon} alt="reset" /> <p>Réintialiser</p>
            </div>
            <div className="validate" onClick={()=>{payementHandler(false)}}>
              <img src={chartIcon} alt="validate" /> <p>Payer</p>
            </div>
            <div className="validate btn--yellow" onClick={()=>{payementHandler(true)}}>
              <img src={printIcon1} alt="validate" /> <p>Le ticket</p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-l">
        <div className="searchss">
          <img src={searchGrayIcon} alt="search" />
          <input
            placeholder="Recherche par nom ou code à barres"
            type="text"
            onChange={(e) => {
              searchHandler(e);
            }}
          />
        </div>
        <div className="h3">
          <p>Produits</p>
        </div>
        <div className=" tablet">
          <table>
            <thead>
              <tr style={{ borderTop: "1px solid #EAECF0 " }}>
                <th>
                  <div className="th2">
                    <p>Id </p> 
                  </div>
                </th>
                <th>
                  <div className="th2">
                    <p>Nom </p> 
                  </div>
                </th>
                <th>
                  <div className="th2">
                    <p>Qte </p> 
                  </div>
                </th>
                <th>
                  <div className="th2">
                    <p>Type </p> 
                  </div>
                </th>
                <th>
                  <div className="th2">
                    <p>Date De Peremption </p> 
                  </div>
                </th>
                <th>
                  <div className="th2">
                    <p>Prix de vente </p> 
                  </div>
                </th>
                <th>
                  <div className="th3"></div>
                </th>
              </tr>
            </thead>
            {list?.filter((el) => el.type === "P")?.length ? (
              <tbody>
                {filterActive
                  ? filtred
                      .filter((element) => element.type === "P")
                      .slice((active - 1) * 6, active * 6)
                      .map((element, index) => {
                        let isValid = false;
                        if (element?.dateDePeremption) {
                          let perDate = new Date(element.dateDePeremption);
                          let today = new Date();
                          let notDate = new Date(today.getTime() + 259200000);
                          isValid = perDate.getTime() <= notDate.getTime();
                        }

                        return (
                          <tr
                            key={index}
                            style={{ borderTop: "1px solid #EAECF0 " }}
                          >
                            <td>
                              <div className="td2 lite">
                                <p>{element.barCode} </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div className="td2">
                                <p>{element?.name} </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div
                                className={`td2 lite ${
                                  element.qtDispo <= element.minQt ? "red" : ""
                                }`}
                              >
                                <p>
                                  {element?.qtDispo ? element.qtDispo : "--"}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="td2 lite">
                                <p>{element.type}</p>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`td2 lite ${isValid ? "red" : ""}`}
                              >
                                <p>
                                  {element?.dateDePeremption
                                    ? `${
                                        new Date(element.dateDePeremption)
                                          .toISOString()
                                          .split("T")[0]
                                      }`
                                    : "aucune date"}{" "}
                                </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div className="td2 lite">
                                <p>
                                  {element?.prix.toLocaleString("en-DE")} DA
                                </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div
                                onClick={() => {
                                  chartHandler(element);
                                }}
                                className="add_btn"
                              >
                                Ajouter
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  : list
                      .filter((element) => element.type === "P")
                      .slice((active - 1) * 6, active * 6)
                      .map((element, index) => {
                        let isValid = false;
                        if (element?.dateDePeremption) {
                          let perDate = new Date(element.dateDePeremption);
                          let today = new Date();
                          let notDate = new Date(today.getTime() + 259200000);
                          isValid = perDate.getTime() <= notDate.getTime();
                        }

                        return (
                          <tr
                            key={index}
                            style={{ borderTop: "1px solid #EAECF0 " }}
                          >
                            <td>
                              <div className="td2 lite">
                                <p>{element?.barCode} </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div className="td2">
                                <p>{element?.name} </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div
                                className={`td2 lite ${
                                  element.qtDispo <= element.minQt ? "red" : ""
                                }`}
                              >
                                <p>
                                  {element?.qtDispo ? element.qtDispo : "--"}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="td2 lite">
                                <p>{element.type}</p>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`td2 lite ${isValid ? "red" : ""}`}
                              >
                                <p>
                                  {element?.dateDePeremption
                                    ? `${
                                        new Date(element.dateDePeremption)
                                          .toISOString()
                                          .split("T")[0]
                                      }`
                                    : "aucune date"}{" "}
                                </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div className="td2 lite">
                                <p>
                                  {element?.prix.toLocaleString("en-DE")} DA
                                </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div
                                onClick={() => {
                                  chartHandler(element);
                                }}
                                className="add_btn"
                              >
                                Ajouter
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                {/* <tr style={{ borderTop: "1px solid #EAECF0 " }}>
                                    <td className="td1" style={{ width: "1rem" }}><div><input type="checkbox" /></div></td>
                                    <td><div className="td2"><p>Nom et Prénom </p> </div></td>
                                    <td><div className="td2 lite"><p>12/05/2023 </p> </div></td>
                                    <td><div className="td2 lite"><p>1000,00 DA </p> </div></td>
                                    <td><div className="td3" onClick={handelOptions}><img src={options} alt="options" /><div className="openProfile" style={activeOption ? { animation: "slideDown 0.5s ease-out" } : { display: "none" }} ><p>Oper Profile</p><img src={linkIcon} alt="profile" /></div></div></td>
                                </tr> */}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={8}>
                    <div className="no-fr">
                      <p>
                        Aucun produit n'est retrouver ! veuillez Ajouter Un!
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        <div
          className="p-n"
          style={{ width: "100%", margin: "auto auto 1rem auto" }}
        >
          <div className="pagination">
            <div
              className="nav"
              onClick={() => {
                handelnavigation("return");
              }}
              style={
                active === 1 || pages.length === 0
                  ? {
                      backgroundColor: "#F5F5F5",
                      opacity: ".6",
                      cursor: "default",
                    }
                  : {}
              }
            >
              <img src={rtrn} alt="return" />
            </div>
            {active >= 3 && pages.length > 3 ? (
              <div className="ect">...</div>
            ) : (
              ""
            )}
            {pages.length > 3
              ? active < 3 && active > 0
                ? pages?.slice(0, 3).map((page, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          handelnavigation("page", page);
                        }}
                        className="page"
                        style={
                          active === page ? { backgroundColor: "#100DB1" } : {}
                        }
                      >
                        {" "}
                        <p style={page === active ? { color: "white" } : {}}>
                          {page}
                        </p>
                      </div>
                    );
                  })
                : active > pages.length - 3
                ? pages
                    ?.slice(pages.length - 3, pages.length)
                    .map((page, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            handelnavigation("page", page);
                          }}
                          className="page"
                          style={
                            active === page
                              ? { backgroundColor: "#100DB1" }
                              : {}
                          }
                        >
                          {" "}
                          <p style={page === active ? { color: "white" } : {}}>
                            {page}
                          </p>
                        </div>
                      );
                    })
                : pages?.slice(active - 2, active + 2).map((page, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          handelnavigation("page", page);
                        }}
                        className="page"
                        style={
                          active === page ? { backgroundColor: "#100DB1" } : {}
                        }
                      >
                        {" "}
                        <p style={page === active ? { color: "white" } : {}}>
                          {page}
                        </p>
                      </div>
                    );
                  })
              : pages?.map((page, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        handelnavigation("page", page);
                      }}
                      className="page"
                      style={
                        active === page ? { backgroundColor: "#100DB1" } : {}
                      }
                    >
                      {" "}
                      <p style={page === active ? { color: "white" } : {}}>
                        {page}
                      </p>
                    </div>
                  );
                })}

            {active < pages.length - 2 && pages.length > 3 ? (
              <div className="ect">...</div>
            ) : (
              ""
            )}
            <div
              className="frwrd"
              onClick={() => {
                handelnavigation("frwrd");
              }}
              style={
                active === pages.at(-1)
                  ? {
                      backgroundColor: " #F5F5F5",
                      opacity: ".6",
                      cursor: "default",
                    }
                  : {}
              }
            >
              <div
                className="nav"
                style={
                  active === pages.at(-1) || pages.length === 0
                    ? {
                        backgroundColor: " #F5F5F5",
                        opacity: ".6",
                        cursor: "default",
                      }
                    : {}
                }
              >
                <img src={frwrd} alt="next" />
              </div>
            </div>
          </div>
        </div>
        <div className="h3">
          <p>Services</p>
        </div>

        <div className=" tablet">
          <table>
            <thead>
              <tr style={{ borderTop: "1px solid #EAECF0 " }}>
                <th>
                  <div className="th2">
                    <p>Id </p> 
                  </div>
                </th>
                <th>
                  <div className="th2">
                    <p>Nom </p> 
                  </div>
                </th>
                <th>
                  <div className="th2">
                    <p>Qte </p> 
                  </div>
                </th>
                <th>
                  <div className="th2">
                    <p>Type </p> 
                  </div>
                </th>
                <th>
                  <div className="th2">
                    <p>Date De Peremption </p> 
                  </div>
                </th>
                <th>
                  <div className="th2">
                    <p>Prix de vente </p> 
                  </div>
                </th>
                <th>
                  <div className="th3"></div>
                </th>
              </tr>
            </thead>
            {list?.filter((el) => el.type === "S")?.length ? (
              <tbody >
                {filterActive
                  ? filtred
                      .filter((element) => element.type === "S")
                      .slice((actives - 1) * 6, actives * 6)
                      .map((element, index) => {
                        let isValid = false;
                        if (element?.dateDePeremption) {
                          let perDate = new Date(element.dateDePeremption);
                          let today = new Date();
                          let notDate = new Date(today.getTime() + 259200000);
                          isValid = perDate.getTime() <= notDate.getTime();
                        }

                        return (
                          <tr
                            key={index}
                            style={{ borderTop: "1px solid #EAECF0 " }}
                          >
                            <td>
                              <div className="td2 lite">
                                <p>{element?.barCode} </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div className="td2">
                                <p>{element?.name} </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div
                                className={`td2 lite ${
                                  element.qtDispo <= element.minQt ? "red" : ""
                                }`}
                              >
                                <p>
                                  {element?.qtDispo ? element.qtDispo : "--"}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="td2 lite">
                                <p>{element.type}</p>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`td2 lite ${isValid ? "red" : ""}`}
                              >
                                <p>
                                  {element?.dateDePeremption
                                    ? `${
                                        new Date(element.dateDePeremption)
                                          .toISOString()
                                          .split("T")[0]
                                      }`
                                    : "aucune date"}{" "}
                                </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div className="td2 lite">
                                <p>
                                  {element?.prix.toLocaleString("en-DE")} DA
                                </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div
                                onClick={() => {
                                  chartHandler(element);
                                }}
                                className="add_btn"
                              >
                                Ajouter
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  : list
                      .filter((element) => element.type === "S")
                      .slice((actives - 1) * 6, actives * 6)
                      .map((element, index) => {
                        let isValid = false;
                        if (element?.dateDePeremption) {
                          let perDate = new Date(element.dateDePeremption);
                          let today = new Date();
                          let notDate = new Date(today.getTime() + 259200000);
                          isValid = perDate.getTime() <= notDate.getTime();
                        }
                        return (
                          <tr
                            key={index}
                            style={{ borderTop: "1px solid #EAECF0 " }}
                          >
                            <td>
                              <div className="td2 lite">
                                <p>{element?.barCode} </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div className="td2">
                                <p>{element?.name} </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div
                                className={`td2 lite ${
                                  element.qtDispo <= element.minQt ? "red" : ""
                                }`}
                              >
                                <p>
                                  {element?.qtDispo ? element.qtDispo : "--"}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="td2 lite">
                                <p>{element?.type}</p>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`td2 lite ${isValid ? "red" : ""}`}
                              >
                                <p>
                                  {element?.dateDePeremption
                                    ? `${
                                        new Date(element.dateDePeremption)
                                          .toISOString()
                                          .split("T")[0]
                                      }`
                                    : "aucune date"}{" "}
                                </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div className="td2 lite">
                                <p>
                                  {element?.prix.toLocaleString("en-DE")} DA
                                </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div
                                onClick={() => {
                                  chartHandler(element);
                                }}
                                className="add_btn"
                              >
                                Ajouter
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                {/* <tr style={{ borderTop: "1px solid #EAECF0 " }}>
                                    <td className="td1" style={{ width: "1rem" }}><div><input type="checkbox" /></div></td>
                                    <td><div className="td2"><p>Nom et Prénom </p> </div></td>
                                    <td><div className="td2 lite"><p>12/05/2023 </p> </div></td>
                                    <td><div className="td2 lite"><p>1000,00 DA </p> </div></td>
                                    <td><div className="td3" onClick={handelOptions}><img src={options} alt="options" /><div className="openProfile" style={activeOption ? { animation: "slideDown 0.5s ease-out" } : { display: "none" }} ><p>Oper Profile</p><img src={linkIcon} alt="profile" /></div></div></td>
                                </tr> */}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={8}>
                    <div className="no-fr">
                      <p>
                        Aucun service n'est retrouver ! veuillez Ajouter Un!
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        <div
          className="p-n"
          style={{ width: "100%", margin: "auto auto 1rem auto" }}
        >
          <div className="pagination">
            <div
              className="nav"
              onClick={() => {
                handelnavigations("return");
              }}
              style={
                actives === 1 || pagess.length === 0
                  ? {
                      backgroundColor: "#F5F5F5",
                      opacity: ".6",
                      cursor: "default",
                    }
                  : {}
              }
            >
              <img src={rtrn} alt="return" />
            </div>
            {actives >= 3 && pagess.length > 3 ? (
              <div className="ect">...</div>
            ) : (
              ""
            )}
            {pagess.length > 3
              ? actives < 3 && actives > 0
                ? pagess?.slice(0, 3).map((page, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          handelnavigations("page", page);
                        }}
                        className="page"
                        style={
                          actives === page ? { backgroundColor: "#100DB1" } : {}
                        }
                      >
                        {" "}
                        <p style={page === actives ? { color: "white" } : {}}>
                          {page}
                        </p>
                      </div>
                    );
                  })
                : actives > pagess.length - 3
                ? pagess
                    ?.slice(pagess.length - 3, pagess.length)
                    .map((page, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            handelnavigations("page", page);
                          }}
                          className="page"
                          style={
                            actives === page
                              ? { backgroundColor: "#100DB1" }
                              : {}
                          }
                        >
                          {" "}
                          <p style={page === actives ? { color: "white" } : {}}>
                            {page}
                          </p>
                        </div>
                      );
                    })
                : pagess?.slice(actives - 2, actives + 2).map((page, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          handelnavigations("page", page);
                        }}
                        className="page"
                        style={
                          actives === page ? { backgroundColor: "#100DB1" } : {}
                        }
                      >
                        {" "}
                        <p style={page === actives ? { color: "white" } : {}}>
                          {page}
                        </p>
                      </div>
                    );
                  })
              : pagess?.map((page, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        handelnavigations("page", page);
                      }}
                      className="page"
                      style={
                        actives === page ? { backgroundColor: "#100DB1" } : {}
                      }
                    >
                      {" "}
                      <p style={page === actives ? { color: "white" } : {}}>
                        {page}
                      </p>
                    </div>
                  );
                })}

            {actives < pagess.length - 2 && pagess.length > 3 ? (
              <div className="ect">...</div>
            ) : (
              ""
            )}
            <div
              className="frwrd"
              onClick={() => {
                handelnavigations("frwrd");
              }}
              style={
                actives === pagess.at(-1)
                  ? {
                      backgroundColor: " #F5F5F5",
                      opacity: ".6",
                      cursor: "default",
                    }
                  : {}
              }
            >
              <div
                className="nav"
                style={
                  actives === pagess.at(-1) || pagess.length === 0
                    ? {
                        backgroundColor: " #F5F5F5",
                        opacity: ".6",
                        cursor: "default",
                      }
                    : {}
                }
              >
                <img src={frwrd} alt="next" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
