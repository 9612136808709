import "./userProfile.css";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { instance,notify } from "../../api/api";
import toast,{ Toaster } from "react-hot-toast";

import Cookies from "js-cookie";
import UserProfileForm from "../../components/UserProfileForm/UserProfileForm";
export default function UserProfile() {
  const [activePage, setActivePage] = useState("informations")
  const [fournisseur,setFournisseur] = useState({})
  const [user,setUser] = useState({})
  const [validator,setValidator] = useState(false)
  let {id} = useParams()
  useEffect(()=>{
   
    const getuser = async ()=>{
      if(!id){ notify("error","ID is not valid !")}
      let url = `/users/${id}`
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
      };
      try{
      const res=await instance.get(url,{headers})
        if(!res?.data?.user){notify("error",`Error while connecting with server ${res.data.message}`)}
        setUser(res.data.user)
      }catch(err){notify('error',err.message)}

    }
    getuser()
  },[])
  useEffect(()=>{
   
    const getuser = async ()=>{
      if(!id){ notify("error","ID is not valid !")}
      let url = `/users/${id}`
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
      };
      try{
     const res=await instance.get(url,{headers})
        if(!res?.data?.user){notify("error",`Error while connecting with server ${res.data.message}`)}
        setUser(res.data.user)
      }catch(err){notify('error',err.message)}

    }
    getuser()
  },[validator])

  return (
    <div className="page_container">
        <Toaster
        toastOptions={{
          style: {
            background: "transparent",
            border: "none",
            boxShadow: "none",
            color: "#ffffff",
          },
        }}
      />
      <div className="fournisseur_profile">
        <div className="header">
          <h2>{user?.email?user.email:"User"}</h2>
          <p>utilisateur</p>
        </div>
        <div className="page">
          <div className={`${activePage==="informations"?"active":"not-active"}`} onClick={()=>{setActivePage("informations")}}> Informations </div>
        </div>
      <UserProfileForm handelvalidator={setValidator} user={user} handelfournisseur={setFournisseur} fournisseur={fournisseur} handelActivePage={setActivePage}/>
        
      </div>
    </div>
  );
}
