import React from 'react'
import "./unAuthorized.css"
import { useSelector } from 'react-redux';
const UnAuthorized = () => {
  const auth = useSelector((state: any) => state.authReducer.auth);
  console.log(auth.roles.length)
    const roles = auth&&auth.roles? auth.roles:"000000000000"
    const redirect = ()=>{
      let routes= ["Home","Clients","Animal","Produit","Ventes","caisse","RendezVous","Hospitalisation","Achats","Pointdevente","utilisateurs","CaisseGenerale"]
      if(roles === "000000000000"){
       window.location.href = "/login"
     } 
     window.location.href =`/${routes[roles.indexOf("1")]}`
    }
  return (
    <div className="unauthorized-container">
      <div className="unauthorized-content">
        <h1 className="unauthorized-heading">401</h1>
        <p className="unauthorized-text">Unauthorized Access</p>
        <p className="unauthorized-text">You are not authorized to view this page.</p>
        <p className="unauthorized-text">Please contact the administrator for further assistance.</p>
        <div onClick={redirect} className="unauthorized-button">Redirect</div>
      </div>
    </div>
  );
};

export default UnAuthorized;

