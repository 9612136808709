import { Box } from '@material-ui/core';
import React, { FunctionComponent,useRef, useState, useEffect } from 'react';
import Boxs from '../Produit/Box';
import '../../css/tablecaisse.css';
import { useDispatch, useSelector } from 'react-redux'
import { GetAllRdv,getFournisseurs,GetAllClient  } from '../../api/api';
import { useVetoContext } from '../../api/Context';
export interface Data {
    id: number;
    name: string;
    race: string;
    age: any;
    robe: string;
    startDate:any;
    endDate:any;
    client: any;
    servicesInformations: any;
    clientId: string;
  }
  
// interface caisseprops{
//     onCloseModal: () => void;

// }
const TableCaisse :FunctionComponent= ()=> {
    const { products,  notificationss}=useVetoContext();
    const auth = useSelector((state:any) =>(state.authReducer.auth)) 
    const [clients, setClients] = useState<any[]>([]);
 
  const cs_r = useSelector((state:any)=>(state.caisseReducer.caisse))
  console.log(auth)
  console.log(cs_r)
  const [dataRdv, setDataRdv] = useState<Data[]>([]);
  const [datafournis, setDatafournis] = useState([]);

  console.log("cs_r",cs_r)
  console.log(notificationss.data)
  console.log(auth)
  console.log(dataRdv)
  console.log(datafournis)
  console.log(products)

  console.log(clients)
  useEffect(() => {
    const fetchRdvs = async () => {
      try {
        const Response = await GetAllRdv();
        setDataRdv(Response);
      } catch (error) {
        console.error("Error fetching:", error);
      }
    };

    fetchRdvs();
  }, []);
  useEffect(() => {
    const fetchfournis = async () => {
      try {
        const Response = await getFournisseurs();
        setDatafournis(Response);
      } catch (error) {
        console.error("Error fetching:", error);
      }
    };
    fetchfournis();
  }, []);
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsResponse = await GetAllClient();
        setClients(clientsResponse.clients );
      } catch (error) {
        console.error("Error fetching clients:", error);
      } 
    };

    fetchClients();
  }, []);

  const day = new Date();
  const todayAppointments = dataRdv.filter(

    (appointment) => {
        
        const datee=new Date(appointment.startDate);
        return day.getMonth() === datee.getMonth() &&datee.getFullYear() === datee.getFullYear()&&day.getDate() === datee.getDate()
   
    }
  );
  return (
    <div className='caisse-body'>
        <div className='table-caisse'>
        <b >
        Bonjour, {auth.user.firstname} {auth.user.lastname} 
        </b> </div>
        <div className='date-caisse'>
        <b>{cs_r.createdAt ? new Date(cs_r.createdAt).toLocaleString() : ""}</b>
      </div>
       <div className='boxs-caisse'>
        <div className='box-fournisseur'>
            <Boxs titlebox='Fournisseurs' quantitie={datafournis&&datafournis.length?datafournis.length:0} dzd=''></Boxs>
        </div>
        <div className='box-Produits'>
            <Boxs titlebox='Produits' quantitie={products&&products.products&&products.products.length?products.products.length:0} dzd=''></Boxs>
        </div>
        <div className='box-Clients'>
            <Boxs titlebox='Clients' quantitie={clients&&clients.length?clients.length:0} dzd=''></Boxs>
            
        </div>
        <div className='box-Alertes'>
    <Boxs
        titlebox='Alertes'
        quantitie={
            notificationss.data &&
            notificationss.data.users &&
            notificationss.data.users.payement &&
            notificationss.data.users.assurance &&
            notificationss.data.products &&
            notificationss.data.products.peremption
                ? notificationss.data.users.payement.length +
                  notificationss.data.users.assurance.length +
                  notificationss.data.products.peremption.length
                : 0
        }
        dzd=''
    ></Boxs>
</div>
        <div className='box-Rendezvous'>
            <Boxs titlebox='Rendez-vous d’aujourd’hui' quantitie={todayAppointments.length} dzd=''></Boxs>
        </div>
        
        <div className='box-Caisses'>
            <Boxs titlebox='Caisse' quantitie={cs_r&&cs_r.ouverture?cs_r.ouverture:0} dzd=''></Boxs>
            <div className='dzd-caisse'>DZD</div>
        </div> 
       </div>
        
    </div>
    );
};
export default TableCaisse;