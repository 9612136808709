import React, { FunctionComponent, useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
  makeStyles,
} from "@material-ui/core";

interface Props {
  users: any[];
  selectedUserId: string | null;
  setSelectedUserId: React.Dispatch<React.SetStateAction<string | null>>;
}

const UtilisateursleTable: FunctionComponent<Props> = ({
  users,
  selectedUserId,
  setSelectedUserId,
}) => {
  return (
    <div>
      <Table className="tableutilisateur">
        <TableHead>
          <TableRow>
            <TableCell className="celltableachat">
              <TableSortLabel>Nom</TableSortLabel>
            </TableCell>
            <TableCell className="celltableachat">
              <TableSortLabel>Prénom</TableSortLabel>
            </TableCell>
            <TableCell className="celltableachat">
              <TableSortLabel>Téléphone</TableSortLabel>
            </TableCell>
            <TableCell className="celltableachat">
              <TableSortLabel>Payement</TableSortLabel>
            </TableCell>
            <TableCell className="celltableachat">
              <TableSortLabel>Assurance</TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, index) => (
            <TableRow
              key={index}
              onClick={() => setSelectedUserId(user.id)}
              selected={user.id === selectedUserId}
            >
              <TableCell className="celltableachat">{user.firstname}</TableCell>
              <TableCell className="celltableachat">{user.lastname}</TableCell>
              <TableCell className="celltableachat">{user.phone}</TableCell>
              <TableCell className="celltableachat">{user.salary}</TableCell>
              <TableCell className="celltableachat">
                {user.assuranceExp}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
export default UtilisateursleTable;
