import "./codeBars.css";
import JsBarcode from "jsbarcode";
import { useState, useEffect } from "react";

import React from "react";

export const CodeBars = React.forwardRef((props, ref) => {
  let { products,toPrint } = props;
  useEffect(() => {
    const generateBarcode = () => {
      if (products?.length && toPrint) {
        products.filter((product)=>product.barCode===toPrint).map((product) => {
          JsBarcode(`#qr${product.barCode}`, product.barCode);
        });
      }
      if (products?.length && !toPrint) {
        products.map((product) => {
          JsBarcode(`#qr${product.barCode}`, product.barCode);
        });
      }
    };
    generateBarcode();
  }, [products,toPrint]);

  return (
    <div className="codeBars" ref={ref}>
      {products?.length ? toPrint?(
        products?.filter((product)=>product.barCode===toPrint).map((product, index) => {
          return (
            Array.from({ length: parseInt(product.qt) }, (_, index) => ( <div className="codeBar" key={index}>
              <svg id={`qr${product.barCode}`}></svg>{" "}
            </div>))
           
          );
        })
      )  :(
        products?.map((product, index) => {
          return (
            Array.from({ length: parseInt(product.qt) }, (_, index) => ( <div className="codeBar" key={index}>
              <svg id={`qr${product.barCode}`}></svg>{" "}
            </div>))
           
          );
        })
      ) : (
        <p>AUCUN PRODUIT N'EST DETECTE</p>
      )}
    </div>
  );
});
