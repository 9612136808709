import React from 'react';

import { FunctionComponent, useState } from "react";
import "../../css/LoginForm.css";
import { login ,notify} from "../../api/api";
import { useDispatch } from "react-redux";

const LoginForm: FunctionComponent = () => {
  let dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  async function handleLogin() {
      if (!username ||!password) {
        notify('error','Veuillez remplir tous les champs correctement.')
        // window.location.reload();
        // throw new Error('Veuillez remplir tous les champs correctement.');
      }
      const response = await login(username,password,dispatch);

        
  }
  return (
    <div className="frame-parent">
      <div className="frame">
        <b className="connecter-vetapp">Connecter à VetApp</b>
      </div>
      <form>
        <div className="input-1">
          <div className="input-container">
            <input
              className="input-1-child"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <b className="utilisateur">Utilisateur</b>
          </div>
        </div>
        <div className="input-2">
          <div className="input-container">
            <input
              className="input-2-child"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <b className="utilisateur">Mot de passe</b>
          </div>
        </div>
        <div className="frame1">
          <button className="btn-2-blue-xl" type="button" onClick={handleLogin}>
            <div className="text">
              <b className="connexion">Connexion</b>
            </div>
          </button>
        </div>
      </form>
    </div>
  );
};
export default LoginForm;
