import React from 'react';
import AddHos from '../../components/Hospitalisation/Addhospitalisation';
import { FunctionComponent, useEffect,useState } from "react";
import "../../css/Hospitalisation.css";
import HospitalisationTable from "../../components/Hospitalisation/HospitalisationTable"
import MotifTable from "../../components/Hospitalisation/MotifTable"
import { GetAllhospitalisation } from '../../api/api';
import Buttons1 from '../../components/Clients/Buttons1';
const Hospitalisation: FunctionComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [Hospitalisations, sethospitalisations] = useState<any[]>([]);
  useEffect(() => {
    const fetchHospitalisation = async () => {
      try {
        const HospitalisationResponse = await GetAllhospitalisation();
        sethospitalisations(HospitalisationResponse);
      } catch (error) {
        console.error("Error fetching :", error);
      } 
    };
    fetchHospitalisation();
  }, []);
  console.log(Hospitalisations)
  const handlehospitalisationClick= () => {
    setIsModalOpen(true);
  }
  return (
    <div className="home-hospi anim">
      <div className="title1">
        <img className="logo" alt="" src="/logo-m-1.svg" />
        <b className="cltext">Hospitalisation</b>
      </div>
      <div className="bodyclient">
      <Buttons1
 icon="/Images/Clients/adduser.png"
    text=" Ajouter hosp"
    onClick={handlehospitalisationClick}
    background="background"
  />
<div className="tables">
      <HospitalisationTable data={Hospitalisations} />
      <MotifTable data={Hospitalisations}/>
      </div>
      {isModalOpen && <AddHos onCloseModal={handleCloseModal} />}
    </div>
    
    </div>
  );
}

export default Hospitalisation ;