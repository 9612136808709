import "./addProduct.css"


import React from 'react'
import ProductForm from "./ProductForm"

export default function AddProduct() {
  return (
    <div className="page_container">
        <ProductForm/>
    </div>
  )
}
