import React, { FunctionComponent, useState } from "react";
import "../../css/SearchArea.css";

interface SearchAreaProps {
  onSearch: (searchText: string) => void;
  onAnnuler: () => void;
}

const SearchArea: FunctionComponent<SearchAreaProps> = (props) => {
  const [searchText, setSearchText] = useState("");

  const handleSearch = () => {
    props.onSearch(searchText);
  };

  const handleAnnuler = () => {
    setSearchText("");
    props.onAnnuler();
  };

  return (
    <div className="search">
      <input
        className="inputsearch"
        id="search"
        placeholder="Search..."
        type="text"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <button className="submitsearch" id="submitbutton" onClick={handleSearch}>
        <img className="group-icon9" alt="" src="/Images/Clients/search.png" />
      </button>

      <button className="submitdelete" id="submitdelete" onClick={handleAnnuler}>
        <img className="group-icon9" alt="" src="/Images/Clients/annuler.png" />
      </button>
    </div>
  );
};

export default SearchArea;
