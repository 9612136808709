import "./fournisseurProfile.css";
import FournisseurProfileForm from "../../components/FornisseurProfileForm/FournisseurProfileForm"
import FournisseurProfileTransactions from "../../components/FournisseurProfileTransactions/FournisseurProfileTransactions";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { instance,notify } from "../../api/api";
import toast,{ Toaster } from "react-hot-toast";

import Cookies from "js-cookie";
export default function FournisseurProfile() {
  const [activePage, setActivePage] = useState("informations")
  const [fournisseur,setFournisseur] = useState({})
  const [inTotal,setInTotal] = useState({credit : 0,totale :0})
  const [transactions,setTransactions] = useState([])
  const [validator,setValidator] = useState(false)
  useEffect(()=>{setTransactions(fournisseur.transactions) },[fournisseur])

  const notify = (type, message) => {
    if (type === "err") {
      toast(<ToastErr message={message} />);
    }
    if (type === "errcatch") {
      toast(<ToastErr message={message} />);
    }
    if (type == "success") {
      toast(<ToastSuccess message={message} />, {
        position: "top-center",
      });
    }
  };
  let {id} = useParams()
  useEffect(()=>{
    const getdata = async ()=>{
      if(!id){ notify("error","ID is not valid !")}
      let url = `/fournisseurs/${id}`
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "fournisseurs",
      };
      try{
      const res=await instance.get(url,{headers})
        if(!res?.data?.fournisseur){notify("error",`Error while connecting with server ${res.data.message}`)}
        if(res.data.fournisseur?.transactions){
          let totale = res.data.fournisseur.transactions.reduce((acc,transaction)=>{acc += transaction.totale; return acc},0)
          let paye = res.data.fournisseur.transactions.reduce((acc,transaction)=>{acc += transaction.paye; return acc},0)
          let credit = totale - paye
          setInTotal({credit : credit,totale : totale})

        }
        setFournisseur(res.data.fournisseur)
      }catch(err){notify('error',err.message)}

    }
      getdata()
  },[])
  useEffect(()=>{
    const getdata = async ()=>{
      if(!id){ notify("error","ID is not valid !")}
      let url = `/fournisseurs/${id}`
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "fournisseurs",
      };
      try{
      const res=await instance.get(url,{headers})
        if(!res?.data?.fournisseur){notify("error",`Error while connecting with server ${res.data.message}`)}
        if(res.data.fournisseur?.transactions){
          let totale = res.data.fournisseur.transactions.reduce((acc,transaction)=>{acc += transaction.totale; return acc},0)
          let paye = res.data.fournisseur.transactions.reduce((acc,transaction)=>{acc += transaction.paye; return acc},0)
          let credit = totale - paye
          setInTotal({credit : credit,totale : totale})

        }
        setFournisseur(res.data.fournisseur)
      }catch(err){notify('error',err.message)}

    }
      getdata()
  },[validator])
  return (
    <div className="page_container">
        <Toaster
        toastOptions={{
          style: {
            background: "transparent",
            border: "none",
            boxShadow: "none",
            color: "#ffffff",
          },
        }}
      />
      <div className="fournisseur_profile">
        <div className="header">
          <h2>{fournisseur?.firstname?fournisseur.firstname:"fournisseur"}</h2>
          <p>Fournisseur</p>
        </div>
        <div className="page">
          <div className={`${activePage==="informations"?"active":"not-active"}`} onClick={()=>{setActivePage("informations")}}> Informations </div>
          <div className={`${activePage==="achats"?"active":"not-active"}`} onClick={()=>{setActivePage("achats")}}> Achats </div>
        </div>
        {activePage==="informations"?<FournisseurProfileForm handelfournisseur={setFournisseur} inTotal={inTotal} fournisseur={fournisseur} handelActivePage={setActivePage}/>:<FournisseurProfileTransactions validator={validator} handelvalidator={setValidator} transactions={transactions} handeltransactions={setTransactions} handelintotal={setInTotal} inTotal={inTotal} fournisseur={fournisseur}/>}
        
      </div>
    </div>
  );
}
