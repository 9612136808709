import React from 'react';
import '../../css/boxs.css';
type propss={
    titlebox:string;
    quantitie:number;
    dzd:string;
}

function Boxs (props:propss){
    return(
        <div className='boxbody'>
           <div className='titebox'>{props.titlebox}</div> 
           <div className='qtebox'> {props.quantitie} </div>
           <div className='dzdbox'> {props.dzd} </div>
        </div>
    );
};
export default Boxs;
