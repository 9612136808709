import "./fournisseurProfileForm.css";
import api from "../../api/api";
import React, { useEffect, useRef, useState } from "react";
import {
  errIcon,
  successIcon,
  cancelWhite,
  arrowIcon,
  editIcon,
  rightArrowIcon,
} from "../../assets/icons";
import toast, { Toaster } from "react-hot-toast";
import { instance,notify } from "../../api/api";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
export default function FournisseurProfileForm({
  handelActivePage,
  fournisseur,
  inTotal,
  handelfournisseur,
}) {
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState([]);
  const inputsRef = useRef([])


  const deletehandler = async () => {
    let url = `/fournisseurs/${fournisseur.id}`;


    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      pm_auth: `${Cookies.get("auth_token")}`,
      checkfor: "fournisseurs",
    };
    try{
    const res=await instance
      .delete(url, { headers })
        if (res?.data?.deleted?.id) {
          notify("success", "un fournisseurs a était supprimé avec succés !");
        
            window.location.href = "/fournisseurs";
         
        } else {
          notify("error", "Des Records sont liés avec ce fournisseur");
        }
      }
      catch(err)  {
        notify("error", err.message);
      };
  };
  const inputHandler = (event) => {
    event.preventDefault();
    setInputs((state) => {
      let news = state;
      news[event.target.name] = event.target.value;
      return news;
    });
  };
  const submitHandler = async (event) => {
    event.preventDefault();
    setErrors([]);
    let url = `/fournisseurs/${fournisseur.id}`;

    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      pm_auth: `${Cookies.get("auth_token")}`,
      checkfor: "fournisseurs",
    };
    let data = inputs;

    if(! Object.keys(inputs).length){
      notify("error","Acun changement n'a était éffectué !")
      return
    }
    if(inputs?.phone){
      if (inputs?.phone?.length !== 12 && inputs?.phone?.length !== 10) {
        setErrors((state) => {
          let news = state;
          news.push({
            field: "phone",
            message:
              "Invalid Number, the phone number should have 10 charachters or 12 !",
          });
          return news;
        });
        return;
      }
      
  
    }
    
    try{
    const res=await instance
      .put(url, { data: data }, { headers })
        if (res?.data?.updated) {
          notify("success","Modifié avec succés")
          handelfournisseur(res.data.updated)
          inputsRef.current[0].value = ""
          inputsRef.current[1].value = ""
          inputsRef.current[2].value = ""
          inputsRef.current[3].value = ""
          inputsRef.current[4].value = ""


        } else {
          notify("error", "quelle que chose s'est mal passé");
        }

        return;
      }
      catch(err) {
        notify("error", err.message);
      };
    return;
  };
  return (
    <div className="container">
      <div className="form">
        <div className="p1">
          <label>
            Nom
            {errors.filter((err) => err.field == "firstname")?.length ? (
              <p className="err-field-msg">{`${
                errors.filter((err) => err.field == "firstname")[0].message
              }*`}</p>
            ) : (
              ""
            )}
            <input
                          ref={(el)=>inputsRef.current[0] = el}
              type="text"
              name="firstname"
              placeholder={`${
                fournisseur?.firstname ? fournisseur.firstname : "Nom*"
              }`}
              onChange={inputHandler}
            />
          </label>
          <label>
          Prénom
          {errors.filter((err) => err.field == "lastname")?.length ? (
            <p className="err-field-msg">{`${
              errors.filter((err) => err.field == "lastname")[0].message
            }*`}</p>
          ) : (
            ""
          )}
          <input
            ref={(el) => (inputsRef.current[0] = el)}
            type="text"
            name="lastname"
            placeholder={`${
              fournisseur?.lastname ? fournisseur.lastname: "prenom*"
            }`}
            onChange={inputHandler}
          />
        </label>
          <label>
            Téléphone
            {errors.filter((err) => err.field == "phone")?.length ? (
              <p className="err-field-msg">{`${
                errors.filter((err) => err.field == "phone")[0].message
              }*`}</p>
            ) : (
              ""
            )}
            <input
                          ref={(el)=>inputsRef.current[1] = el}

              type="number"
              name="phone"
              placeholder={`${
                fournisseur?.phone ? fournisseur.phone : "Téléphone"
              }`}
              onChange={inputHandler}
            />
          </label>
          <label>
            RC
            {errors.filter((err) => err.field == "RC")?.length ? (
              <p className="err-field-msg">{`${
                errors.filter((err) => err.field == "RC")[0].message
              }*`}</p>
            ) : (
              ""
            )}
            <input
                                      ref={(el)=>inputsRef.current[2] = el}

              type="text"
              name="RC"
              placeholder={`${fournisseur?.RC ? fournisseur.RC : "rc*"}`}
              onChange={inputHandler}
            />
          </label>
          <label>
            NIF
            {errors.filter((err) => err.field == "NIF")?.length ? (
              <p className="err-field-msg">{`${
                errors.filter((err) => err.field == "NIF")[0].message
              }*`}</p>
            ) : (
              ""
            )}
            <input
                                      ref={(el)=>inputsRef.current[3] = el}

              type="text"
              name="NIF"
              placeholder={`${fournisseur?.NIF ? fournisseur.NIF : "NIF"}`}
              onChange={inputHandler}
            />
         
          </label>
          <label>
            NIS
            {errors.filter((err) => err.field == "NIS")?.length ? (
              <p className="err-field-msg">{`${
                errors.filter((err) => err.field == "NIS")[0].message
              }*`}</p>
            ) : (
              ""
            )}
            <input
                                      ref={(el)=>inputsRef.current[4] = el}

              type="text"
              name="NIS"
              placeholder={`${fournisseur?.NIS ? fournisseur.NIS : "NIS"}`}
              onChange={inputHandler}
            />
          </label>
        </div>

        <div className="btns">
          <div className="cancel" onClick={submitHandler}>
            <img src={editIcon} alt="cancel" /> <p>Modifier</p>
          </div>
          <div className="delete" onClick={deletehandler}>
            <img src={cancelWhite} alt="delete" /> <p>Supprimer</p>
          </div>
        </div>
      </div>
      <div className="options">
        <div className="option c1">
          <p>Total Achats :</p>
          <p className="num">{inTotal.totale.toLocaleString("de-DE")} DZD</p>
        </div>
        <div className="option c2">
          <p>Crédit</p>
          <p className="num">{inTotal.credit.toLocaleString("en-DE")} DZD</p>
        </div>
        <div
          className="option c3"
          onClick={() => {
            handelActivePage("achats");
          }}
        >
          <img src={rightArrowIcon} alt="transactions" />
          <p className="num">Voire les transactions</p>
        </div>
      </div>
    </div>
  );
}
