import React, { FunctionComponent, useState, useEffect } from "react";
import SearchArea from "../Clients/SearchArea";
import "../../css/tableproduit.css";
import ButtonAjouter from "../Clients/ButtonAjouer";
import ProduitTable from "../Produit/ProduitTable";
import { useNavigate } from 'react-router-dom';
import Boxs from "./Box";
import { useVetoContext } from '../../api/Context';
const Tableproduits = () => {
  const navigate=useNavigate();
  const {products,notificationss}=useVetoContext();
  const [filteredProducts, setFilteredProducts] = useState(products.products);
  const [searchText, setSearchText] = useState("");
  const handleAjouterClick = () => {
    navigate('/addproduct')

  };
  useEffect(() => {
    if(products &&products.products){
    const filtered = products.products
      .map((product: { name: string }) => ({ ...product, name: product.name.toLowerCase() })) 
      .filter((product: { name: string }) => product.name.includes(searchText.toLowerCase()));
    setFilteredProducts(filtered);
    }
  }, [searchText, products.products]);
  const handleSearch = (text: string) => {
    setSearchText(text);
  };
  const handleAnnuler = () => {
    setSearchText("");
  };
  return (
    <div className="bodyproduitservice">
      <div className="titres">
        <h1 className="titre-produit">Produits physiques </h1>
        {/* <h1 className=" titre-produit1">0</h1> */}
      </div>
      <div className="tableprod">
        <ButtonAjouter onajouter={handleAjouterClick} />
        <div className="boxs">
          <div className="box-produit">
            <Boxs titlebox="Produits" quantitie={products.nbproduits?products.nbproduits:0} dzd="" />
          </div>

          <div className="box-stock">
            <Boxs titlebox="Total stock" quantitie={products.stock?products.stock:0} dzd="" />
          </div>
          <div className="box-alert">
            <Boxs titlebox="Alertes" quantitie={notificationss.count?notificationss.count:0} dzd="" />
          </div>
        </div>
        <div className="secondpart">
          <b className="tableprodd">Produit</b>
          <h5>Liste Des Produits</h5>
          <div className="search-barprod">
            <SearchArea onSearch={handleSearch} onAnnuler={handleAnnuler} />
          </div>
          <div className="prod-table">
          <ProduitTable products={filteredProducts} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tableproduits;
