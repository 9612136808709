import React, { createContext, useContext, useState, useEffect } from 'react';
import { GetAllAnimals,getAllProducts,getAllServices,getnotifications} from './api';
const VetoContext = createContext();

export const VetoProvider = ({ children }) => {
  const [animals, setanimals] = useState([]);
  const [products,setproducts]=useState([]);
  const [services,setservices]=useState([]);
  const [notificationss,setnotifications]=useState([]);
  useEffect(() => {
    const fetnotifications = async () => {
      try {
        setnotifications( await getnotifications());
      } catch (error) {
        console.error('Erreur lors de la récupération des notifications:', error);
      }
    }; 
    fetnotifications();
  }, []);
  useEffect(() => {
    const fetchanimals = async () => {
      try {
        setanimals( await  GetAllAnimals());
  
      } catch (error) {
        console.error('Erreur lors de la récupération des animaux:', error);
      }
    }; 
    fetchanimals();
  }, []);
  useEffect(() => {
    const fetchproducts = async () => {
      try {
        setproducts( await  getAllProducts());

      } catch (error) {
        console.error('Erreur lors de la récupération des produits:', error);
      }
    }; 
    fetchproducts();
  }, []);
  useEffect(() => {
    const fetchservices = async () => {
      try {
        setservices( await getAllServices());
      } catch (error) {
        console.error('Erreur lors de la récupération des animaux:', error);
      }
    }; 
    fetchservices();
  }, []);
  const value = {
   animals, 
   products,
   services,
   notificationss
  };
  return (
    <VetoContext.Provider value={value}>
      {children}
    </VetoContext.Provider>
  );
};

export const useVetoContext = () => {
  const context = useContext(VetoContext);
  if (!context) {
    throw new Error('VetoContext must be used within a VetoProvider');
  }
  return context;
};
