import React, { FunctionComponent, useState,useEffect } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
} from '@material-ui/core';
import '../../css/Clientstable.css';
import { Data } from './Tables'; 
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

interface ClientstableProps {
  data: Data[];
  onClientSelect: (client: Data, animals: { id: number, name: string; race: string }[]) => void;
  onDeleteClient: (clientId: number) => void; 
}


const Clientstable: FunctionComponent<ClientstableProps> = ({ data, onClientSelect, onDeleteClient }) => {
  const [selected, setSelected] = useState<number | null>(null);
  const [orderBy, setOrderBy] = useState<keyof Data>('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const handleClick = (
    event: React.MouseEvent<unknown>,
    id: number,
    animals: { id:number, name: string; race: string }[]
  ) => {
    const isItemSelected = selected === id;
  
    if (isItemSelected) {
      setSelected(null);
    } else {
      setSelected(id);
      const selectedClient = data.find((client) => client.id === id);
      if (selectedClient) {
        onClientSelect(selectedClient, animals);
      }
    }
  };
  

  const handleRequestSort = (property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isSelected = (id: number) => selected === id;

  const stableSort = (array: Data[], comparator: (a: Data, b: Data) => number) => {
    return array.slice().sort(comparator);
  };

  const getComparator = (order: 'asc' | 'desc', orderBy: keyof Data) => {
    return order === 'desc'
      ? (a: Data, b: Data) => descendingComparator(a, b, orderBy)
      : (a: Data, b: Data) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a: Data, b: Data, orderBy: keyof Data) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const createSortHandler = (property: keyof Data) => () => {
    handleRequestSort(property);
  };

  return (
    <div className="clientstable">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sortDirection={orderBy === 'id' ? order : false} onClick={createSortHandler('id')}>
              <TableSortLabel active={orderBy === 'id'} direction={orderBy === 'id' ? order : 'asc'}>
                ID
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === 'client' ? order : false} onClick={createSortHandler('client')}>
              <TableSortLabel active={orderBy === 'client'} direction={orderBy === 'client' ? order : 'asc'}>
                Client
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === 'phone' ? order : false} onClick={createSortHandler('phone')}>
              <TableSortLabel active={orderBy === 'phone'} direction={orderBy === 'phone' ? order : 'asc'}>
                Telephone
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === 'credit' ? order : false} onClick={createSortHandler('credit')}>
              <TableSortLabel active={orderBy === 'credit'} direction={orderBy === 'credit' ? order : 'asc'}>
                Crédit
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === 'derniereTransaction' ? order : false} onClick={createSortHandler('derniereTransaction')}>
              <TableSortLabel active={orderBy === 'derniereTransaction'} direction={orderBy === 'derniereTransaction' ? order : 'asc'}>
                Dernière Transaction
              </TableSortLabel>
            </TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(data, getComparator(order, orderBy)).map((row) => (
            <TableRow
              key={row.id}
              hover
              onClick={(event) => handleClick(event, row.id, row.animals)}
              role="checkbox"
              selected={isSelected(row.id)}
              aria-checked={isSelected(row.id)}
              style={{
                backgroundColor: isSelected(row.id) ? 'var(--color-mediumturquoise2)' : undefined,
              }}
            >
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.client}</TableCell>
              <TableCell>{row.phone}</TableCell>
              <TableCell>{row.credit}</TableCell>
              <TableCell>{row.derniereTransaction}</TableCell>
              <TableCell>
                <IconButton aria-label="delete" onClick={() => onDeleteClient(row.id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default Clientstable;