import React from 'react'
import "./notFound.css"
import { useSelector } from 'react-redux';
const NotFound = () => {
  const auth = useSelector(state =>(state.authReducer.auth)) 
   const roles = auth.roles
   const redirect = ()=>{
    let routes =  ["Home","Clients","Animal","Produit","Ventes","caisse","RendezVous","Hospitalisation","Achats","Pointdevente","utilisateurs","CaisseGenerale"]
    if(roles === "000000000000"){

      window.location.href = "/"
    } else{window.location.href =`/${routes[roles.indexOf("1")]}`}
    
   }
   redirect()
  return (
    <div className="unauthorized-container">
      <div className="unauthorized-content">
        <h1 className="unauthorized-heading">404</h1>
        <p className="unauthorized-text">Not Found</p>
        <p className="unauthorized-text">The page you are searching for is not found.</p>
        <p className="unauthorized-text">Please contact the administrator for further assistance.</p>
        <div onClick={redirect} className="unauthorized-button">Redirect</div>
      </div>
    </div>
  );
};

export default NotFound;

