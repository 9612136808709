import React, { FunctionComponent, useEffect, useState } from "react";
import '../../css/AnimalRendez.css';
import { GetserviceInformationsById } from "../../api/api";
import { useParams } from "react-router-dom";
import ServiceDetails from "./Servicedetail";
import MotifDetails from "./MotifdescriptionInfors";
import VenteDetails from "./VenteInfos";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
} from '@material-ui/core';
// import Buttons1 from "../Clients/Buttons1";
interface serviceInformationsData {
  id: string;
  animalId: string;
  serviceId: string;
  venteId: string;
  motif: string;
  description: string;
  service: any;
  vente: any;
}
const RendezAnimal:FunctionComponent = () =>  {
  const [serviceInformations, setServiceInformations] = useState<serviceInformationsData[]>([]);
  const [selectedCell, setSelectedCell] = useState<string | null>(null);
  const { id } = useParams(); 
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [serviceinfos,setsericesinfos]=useState<any| null>(null);
  const [motifinfos,setmotifinfos]=useState<any| null>(null);
  const [venteinfos,setventeinfos]=useState<any| null>(null);
  const handleservicecClick = (row:any) => {
    setIsModalOpen1(true);
    setSelectedCell('serviceId');
    setsericesinfos(row.service)
  };
  const handlemotifcClick = (row:any) => {
    setIsModalOpen3(true);
    setSelectedCell('motif');
    setmotifinfos(row)
  };
  const handleventecClick = (row:any) => {
    setIsModalOpen2(true);
    setSelectedCell('venteId');
    setventeinfos(row.vente)
  };
  useEffect(() => {
    console.log(id);
    GetserviceInformationsById(id)
      .then((data) => {
        // Assurez-vous que data est un tableau avant de le définir
        if (Array.isArray(data)) {
          setServiceInformations(data);
        } else {
          console.error("Les données récupérées ne sont pas un tableau :", data);
        }
      })
      .catch((error) => {
        console.error("Une erreur s'est produite lors de la récupération des données de l'animal : ", error);
      });
  }, []);
   
  const handleCloseModal1 = () => {
    setIsModalOpen1(false);
    setSelectedCell(null);
  };
  const handleCloseModal3 = () => {
    setIsModalOpen3(false);
    setSelectedCell(null);
  };
  const handleCloseModal2 = () => {
    setIsModalOpen2(false);
    setSelectedCell(null);
  };
  return (
    <div className="animalendez" >
         {/* <Buttons1
          icon="/Images/Clients/adduser.png"
          text="Ajout Service"
          onClick={handleAjouterRdvClick }
          background="background"
        />    */}
        <div className="TableInfor">
        <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel>
                serviceId
              </TableSortLabel>
            </TableCell>
            <TableCell >
              <TableSortLabel >
                venteId
              </TableSortLabel>
            </TableCell>
            <TableCell >
              <TableSortLabel>
                motif
              </TableSortLabel>
            </TableCell>
            <TableCell >
              <TableSortLabel >
                description
              </TableSortLabel>
            </TableCell>
            <TableCell >
              <TableSortLabel >
                Date
              </TableSortLabel>
            </TableCell>
            <TableCell>Clientname</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {serviceInformations ? (
          serviceInformations.map((row) => (
            <TableRow
              key={row.id}
              hover
              role="checkbox"
            >
              <TableCell
              onClick={() => handleservicecClick(row)}
              style={{
                backgroundColor: selectedCell === 'serviceId' ? 'var(--color-mediumturquoise2)' : undefined,
              }}
              >{row.serviceId}</TableCell>
              <TableCell
              onClick={() => handleventecClick(row)}
              style={{
                backgroundColor: selectedCell === 'venteId' ? 'var(--color-mediumturquoise2)' : undefined,
              }}
              >{row.venteId}</TableCell>
              <TableCell
                    onClick={() => handlemotifcClick(row)}
                    style={{
                      backgroundColor: selectedCell === 'motif' ? 'var(--color-mediumturquoise2)' : undefined,
                    }}
              >{row.motif}</TableCell>
              <TableCell
                       onClick={() => handlemotifcClick(row)}
                       style={{
                         backgroundColor: selectedCell === 'motif' ? 'var(--color-mediumturquoise2)' : undefined,
                       }}
              >{row.description}</TableCell>
                        <TableCell>{row.vente.createdAt}</TableCell>
              <TableCell>{row.vente.client.lastname} {row.vente.client.firstname}</TableCell>
              <TableCell>
                {/* <IconButton aria-label="delete" onClick={() => onDeleteClient(row.id)}>
                  <DeleteIcon />
                </IconButton> */}
              </TableCell>
            </TableRow>
     ))
     ) : (
       <TableRow>
         <TableCell colSpan={6}>Aucune information de service disponible.</TableCell>
       </TableRow>
     )}
        </TableBody>
      </Table>
        </div>
        {isModalOpen1 && <ServiceDetails onCloseModal={handleCloseModal1} values={serviceinfos} />}
        {isModalOpen2 && <VenteDetails onCloseModal={handleCloseModal2} values={venteinfos} />}
        {isModalOpen3 && <MotifDetails onCloseModal={handleCloseModal3} values={motifinfos} />}
    </div>
    
  );
};

export default RendezAnimal;
