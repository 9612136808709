import React from 'react';
import {arrowIcon,cancelBlack,cancelWhite} from '../../assets/icons'
import { FunctionComponent,useState,useEffect, useRef  } from "react";
import "../../css/CaisseContainer.css";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { notify,instance } from '../../api/api';
import { useDispatch, useSelector } from 'react-redux'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Cookies from 'js-cookie'
export interface Data {
  id: number;
  name: string;
  race: string;
  age: any;
  robe: string;
  startDate:any;
  endDate:any;
  client: any;
  servicesInformations: any;
  clientId: string;
}
const CaisseContainer: FunctionComponent = () => {
  const auth = useSelector((state:any) =>(state.authReducer.auth)) 
  const cs_r = useSelector((state:any)=>(state.caisseReducer.caisse))
  const dispatch = useDispatch()
  const [motif,setMotif] = useState("")
  const [retraitOverlay,setRetraitOverlay] = useState(false)
  const [retrait, setRetrait] = useState<number>(0);
  const handelmotif = (event:any) => {
    setMotif(event.target.value);
  };
  const handelretrait = (event:any) => {
    console.log("retrait",retrait)
    setRetrait(event.target.value);
  };
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {

        setOverlay(false);
        setRetraitOverlay(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);
  const handelRetrait = async (e:any) => {
    e.preventDefault(); 
    try {
      if (!cs_r?.id) {
        notify("error", "no open Caisse");
        return;
      }
   console.log(retrait)
      let limit = parseInt(cs_r.ouverture) + parseInt(cs_r.gains) - parseInt(cs_r.retraits.reduce((acc: number, retrait: any) => {
        acc += retrait.montant;
        return acc;
      }, 0));

      // Ensuring retrait is a string before using parseInt
      if (parseInt(retrait.toString()) === 0 || !retrait || parseInt(retrait.toString()) > limit) {
        notify("error", "montant invalide");
        return;
      }

      if (!cs_r) {
        notify("error", "vous devez selectioner une caisse!");
        return;
      }

      let url = `/retrait`;
      let data = {
        montant: parseInt(retrait.toString()),
        motif: motif,
        caisseId: cs_r.id,
      };
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "fournisseurs",
      };
        const res = await instance
          .post(url, { data }, { headers })
          console.log(res)
            if (res?.data?.retrait) {
              notify("success", "Retrait Validé !");
              setOverlay(false);
              setRetraitOverlay(false)
              setRetrait(0);
            }
    
            notify("error", res.data.message);
    } catch (err: any) {
      notify("error", err.message);
    }
  };

  console.log(cs_r)
  const [overlay,setOverlay] = useState(false)
  const [ouverture,setOuverture] = useState(0)
  const user = auth&&auth.user?auth.user:null;
  const roles = auth&&auth.roles? auth.roles:null;
  const dernierCaractere = roles.charAt(roles.length - 1);
  const checkcaisse = async (type:any)=>{
    if(dernierCaractere==="1"){
      if(!user){notify("error","no user");return}
      try{
        let url = "/caisse/isAuthorized/"
        let data = {type: type, user:user}
        let headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          pm_auth: `${Cookies.get("auth_token")}`,
          checkfor: "notification",
        };
        const res= await instance.post(url,{data},{headers})
          if(type ==="pos"&&res?.data?.auth){
            dispatch({ type: 'setCaisse', payload: res?.data.lastCaisse });
            window.location.href = "/Pointdevente"
            return
          }
          console.log("auttt",res?.data?.auth)
          if(type ==="ouverture"&&res?.data?.auth){
            setOverlay(true)
            return
          }
          if(type==="retrait && res?.data?.auth"){
            dispatch({ type: 'setCaisse', payload: res?.data.lastCaisse });
          }
          console.log('rgsdgd')
          notify("error","unAuthorized access : check user or open caisse!")
          return
    
      }
      catch(err:any){
        notify("error",err.message)
      }
    }
      else{
        notify('error','unauthorized')
    return
    }
  }
  const handelCloture = async () => {
    try {

      let user = auth?.user;

      if (user?.id !== cs_r?.userId) {
        notify("error", "caisse opened by another user!");
        return;
      }
      if (!cs_r?.open) {
        notify("error", "caisse deja cloturée!");
        return;
      }
      let url = `/caisse/${cs_r?.id}`;
      let data = { open: false };
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "fournisseurs",
      };
   const res=await instance.put(url, { data }, { headers })
          if (res?.data?.updated) {
            notify("success", "Caisse Cloturée");
            dispatch({ type: "setCaisse", payload: {} });
            return;
          }
          notify("err", res.data.message);
    } catch (error:any) {
      notify("err", error.message);
    }
  };
  
  const addCaisse = async () => {
    if (!user) {
      notify("error", "no user");
      return;
    }
  
    if (ouverture < 0) {
      notify("error", "montant d'ouverture not set!");
      return;
    }
  
    try {
      let url = "caisse/";
      let data = { userId: user.id, ouverture: ouverture };
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "notification",
      };
  
      const res = await instance.post(url, { data }, { headers });
      console.log("created",res)
      if (res?.data?.created) {
        dispatch({ type: 'setCaisse', payload: res?.data?.created });
        setOverlay(false);
        notify("success", "caisse opened");
        setTimeout(() => {
          window.location.href = "/CaisseGenerale";
        }, 200); 
        setOuverture(0);
      }
      return null;
    } catch (err:any) {
      notify("error", err.message);
      return null;
    }
  };
  
  const handelOverlay = () => {
    setOverlay((state) => !state);
  };
  const handelOuverture = (event:any) => {
    console.log(event.target.value)
    setOuverture(event.target.value);        
  };
  const handelRetraitOverlay = (e:any) => {
    e.preventDefault(); 
    setRetraitOverlay((state:any) => !state);
  };
  return (
    <div className="ouvrir-une-caisse-parent">
      <div className="ouvrir-une-caisse">Ouvrir une caisse</div>
      <div className="clturer-la-caisse">Clôturer la caisse</div>
      <button className="ouvrir-wrapper" id="ouvrir" onClick={()=>{
        checkcaisse("ouverture")}}>
        <div className="ouvrir">Ouvrir</div>
      </button>
      <button
        className="caisse-generale-wrapper"
        id="caisse-generale"
        onClick={() => {
          if (cs_r&&cs_r.ouverture) {
      
            window.location.href = "/CaisseGenerale";
          }
          else{
            notify("error", "veuillez ouvrir une caisse");
          }
        }}
      >
             <div className="ouvrir">Génerale</div>
      </button>
      <button className="clturer-wrapper" id="cloture"
      onClick={()=>{
       handelCloture()}}
      >
        <div className="ouvrir">Clôturer</div>
      </button>
      <button className="clturer-wrapperr" id="retrait"
      onClick={(e)=>{
        handelRetraitOverlay (e)}}
      >
        <div className="ouvrir">Retrait</div>
      </button>
  <img className="frame-child" alt="" src="/Images/HomePage/undefined3.png" />
      <img className="frame-item" alt="" src="/Images/HomePage/undefined4.png" />
      <img className="frame-inner" alt="" src="/Images/HomePage/undefined5.png" /> 
      <Dialog
        open={overlay}
        onClose={handelOverlay}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Le montant d'ouverture :{" "}</DialogTitle>
        <DialogContent>
          <TextField
              onChange={handelOuverture}
              name="ouverture"
              type="number"

          />
       <p> DZD</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handelOverlay}  color="primary">
            Annuler
          </Button>
          <Button   
           onClick={()=>{
                  addCaisse()
         
                }} color="primary"
                disabled={ouverture <= 0} 
                >
            Envoyer
          </Button>
        </DialogActions>
      </Dialog>
      {retraitOverlay ? (
        <div className="overlay">
          <div className="add-payement-window">
            <img src={cancelBlack} alt="cancel" onClick={handelRetraitOverlay} />

            <div className={`amount `}>
              <p>Le montant :</p>{" "}
              <input
                onChange={(event) => {
                  handelretrait(event);
                }}
                name="retrait"
                type="number"
              />
              <p> DZD</p>
            </div>
{parseInt(cs_r.ouverture) + parseInt(cs_r.gains) < parseInt(retrait.toString()) || parseInt(retrait.toString()) === 0 ? (
  <p style={{ color: "#FF5B5B", fontSize: "smaller" }}>
    Un retrait valide doit être entre 0 DZD et{" "}
    {cs_r.retraits ? parseInt(cs_r.ouverture) + parseInt(cs_r.gains) - parseInt(cs_r.retraits.reduce((acc: number, retrait: { montant: number }) => { acc += retrait.montant; return acc; }, 0)) : 0} DZD
  </p>
) : (
  ""
)}


            <div className="motif">
              <label>
                Motife du retrait
                <input
                  onChange={(event) => {
                    handelmotif(event);
                  }}
                  name="retrait"
                  type="text"
                />
              </label>
            </div>
            {/* <p className="p">{fournisseur.firstname}</p> */}
            <div className="btns">
              <div
                className="ajoutersss"
                onClick={(event) => {
                  if (!cs_r?.id) {
                    notify("error", "Vous devez ouvrir au moin une Caisse !");
                    return;
                  }
                  handelRetrait(event);
                  // handelRetraitOverlay(event)
                }}
              >
                <img src={cancelWhite} alt="delete" /> <p>Ajouter</p>
              </div>
              <div
                className="cancel"
                onClick={(event:any) => {
                  handelRetraitOverlay(event);
                  setRetrait(0);
                  setMotif("");
                }}
              >
                <img src={arrowIcon} alt="cancel" /> <p>Annuler</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CaisseContainer;
