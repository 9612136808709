import React, { useEffect, useRef, useState } from "react";
import { FunctionComponent } from "react";
import "../../css/AjoutUtilisateur.css";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { AddUser } from '../../api/api';
import AddAnimal from "../Clients/addAnimal";
interface AddAchatProps {
  onCloseModal: () => void;
}

const AjoutUtilisateurs: FunctionComponent<AddAchatProps> = ({
  onCloseModal,
}) => {
  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (!target.closest(".popuputilisateur")) {
      onCloseModal();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCloseModal]);
  
  const [inputs, setInputs] = useState<Record<string, any>>({});

  const [errors, setErrors] = useState([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [payementday, setPayementDay] = useState<string>("");
  const [gender, setGender] = useState<string>("HOMME");
  
  const handleCheckboxChange = (value: string) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter((item) => item !== value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleAddUser = async () => {
    try {
      setErrors([]);
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      let data = {
          ...inputs,// Assuming selected is the property you want
        paymentDate: parseInt(payementday),
        gender: gender,
        roles: [...selected],
      };
      console.log(data);

      const response = await AddUser(data);
      const userId = response.data.id; 
      console.log(userId)
 

    } catch (error: any) {
      console.error("Erreur lors de l'ajout du client:", error.message);
    }
  };

  return (
    <div className="popuputilisateur anim2">
      <div>
        <p className="paragraph3">Utilisateurs</p>
        <p className="paragraph4">Ajouter un Utilisateur</p>
      </div>
      <div className="form-addutilisateur">
        <div className="formcard2-utilisateur">
          <div>
            <input
              className="input-achat"
              name="lastname"
              placeholder="Nom*"
              type="text"
              onChange={inputHandler}
            />
            <p className="paragraphutilisateurs">
              Entrez le nom de l’utilisateur
            </p>
          </div>
          <div>
            <input
              className="input-achat"
              name="firstname"
              type="text"
              placeholder="Prénom*"
              onChange={inputHandler}
            />
            <p className="paragraphutilisateurs">
              Entrez le prénom de l’utilisateur
            </p>
          </div>
          <div>
            <input
              className="input-achat"
              name="phone"
              type="number"
              placeholder="Téléphone"
              onChange={inputHandler}
            />
            <p className="paragraphutilisateurs">
              Entrez le numéro du téléphone
            </p>
          </div>
          <div>
            <input
              className="input-achat"
              name="salary"
              type="number"
              placeholder="0"
              onChange={inputHandler}
            />
            <p className="paragraphutilisateurs">
              Entrez le salaire de l’utilisateur en DZD
            </p>
          </div>
          <div>
            <input
              className="input-achat"
              type="date"
              name="birthdate"
              onChange={inputHandler}
            />
            <p className="paragraphutilisateurs">
              Entrez la date de naissance de l’utilisateur
            </p>
          </div>
          <div>
            <input
              className="input-achat"
              type="date"
              name="assuranceExp"
              onChange={inputHandler}
            />
            <p className="paragraphutilisateurs">
              Entrez la date d’expiration de l’assurance de l’utilisateur
            </p>
          </div>
          <div>
            <select
              name="gender"
              className="input-achat"
              onChange={(e) => setGender(e.target.value)}
            >
              <option
                value="genre"
                disabled={true}
                className="input-achat"
              >
                Sexe
              </option>
              <option value="HOMME" className="input-achat">
              HOMME
              </option>
              <option value="FEMME" className="input-achat">
                FEMME
              </option>
            </select>
            <p className="paragraphutilisateurs">
              Sélectionnez le sexe de l’utilisateur
            </p>
            </div>
                  <div>
            <input
              className="input-achat"
              type="password"
              name="password"
              placeholder="Mot de passe"
              onChange={inputHandler}
            />
            <p className="paragraphutilisateurs">Entrez le mot de passe</p>
          </div>
          <div>
            <select
              name="paymentDate"
              className="input-achat dateutil"
              onChange={(e) => setPayementDay(e.target.value)}
            >
              <option value="" disabled selected>
                Jour du salaire
              </option>
              {Array.from({ length: 31 }, (_, index) => index + 1).map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </select>
            <p className="paragraphutilisateurs2 ">
              Selectionnez le jour du salaire
            </p>
          </div>
                  <div>

           <input
              className="input-achat"
              type="email"
              name="email"
              placeholder="Email"
              onChange={inputHandler}
            />
            <p className="paragraphutilisateurs">Entrez lemail</p>
          </div>
        </div>
      </div>
      <p className="paragraph9 ">
        Veuillez selectionner les privilèges du nouveau utilisateur :
      </p>
      <div className="chechbox-utilisateur">
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={selected.includes("Hospitalisation")}
              onChange={() => handleCheckboxChange("Hospitalisation")}
            />
          }
          label="Hospitalisation"
          className="colorutil"
        />
              <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={selected.includes("CaisseGenerale")}
              onChange={() => handleCheckboxChange("CaisseGenerale")}
            />
          }
          label="CaisseGenerale"
          className="colorutil"
        />
              <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={selected.includes("utilisateurs")}
              onChange={() => handleCheckboxChange("utilisateurs")}
            />
          }
          label="utilisateurs"
          className="colorutil"
        />
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={selected.includes("Produit")}
              onChange={() => handleCheckboxChange("Produit")}
            />
          }
          label="Produits et Services"
          className="colorutil"
        />
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={selected.includes("RendezVous")}
              onChange={() => handleCheckboxChange("RendezVous")}
            />
          }
          label="RendezVous"
          className="colorutil"
        />
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={selected.includes("achats")}
              onChange={() => handleCheckboxChange("achats")}
            />
          }
          label="Achats"
          className="colorutil"
        />
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={selected.includes("ventes")}
              onChange={() => handleCheckboxChange("ventes")}
            />
          }
          label="Ventes"
          className="colorutil"
        />
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={selected.includes("Pointdevente")}
              onChange={() => handleCheckboxChange("Pointdevente")}
            />
          }
          label="Pointdevente"
          className="colorutil"
        />
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={selected.includes("Animal")}
              onChange={() => handleCheckboxChange("Animal")}
            />
          }
          label="Notifications"
          className="colorutil"
        />
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={selected.includes("Clients")}
              onChange={() => handleCheckboxChange("Clients")}
            />
          }
          label="Clients"
          className="colorutil"
        />
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={selected.includes("Home")}
              onChange={() => handleCheckboxChange("Home")}
            />
          }
          label="Home"
          className="colorutil"
        />
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={selected.includes("caisse")}
              onChange={() => handleCheckboxChange("caisse")}
            />
          }
          label="caisse"
          className="colorutil"
        />
      </div>
      {/* <p className="paragraph9 ">Plus d’informations sur l’utilisateur :</p>
      <button className="plusdinf">Plus d’informations </button> */}
      <button className="ajoututil" onClick={handleAddUser}>
        Ajouter
      </button>

    </div>
  );
};  

export default AjoutUtilisateurs;
