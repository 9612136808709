import React, { FunctionComponent, useRef, useEffect, useState } from "react";
import "../../css/AddClient.css";
import { UpdateserviceInformationsById } from "../../api/api";
interface MotifProps {
  onCloseModal: () => void;
  values:any
}

const MotifDetails: FunctionComponent<MotifProps> = ({ onCloseModal,values }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [motif, setMotif] = useState(values.motif);
  const [description, setdescription] = useState(values.description);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (
      modalRef.current &&
      !modalRef.current.contains(target) &&
      !target.closest(".addclientt") &&
      !target.closest(".inputt-1")  &&
      !target.closest(".inputt-2") 
    ) {
      onCloseModal();
    }
  };
  // const handleAddClient = async () => {
  //   try {
  //     if (!firstName || !lastName || !validatePhone(phone)) {
  //       notify('')
  //       throw new Error('Veuillez remplir tous les champs correctement.');
  //     }
  //     const response = await AddClientapi(firstName, lastName, phone);
  //   } catch (error: any) {
  //     console.error('Erreur lors de l\'ajout du client:', error.message);
  //   }
  // };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCloseModal]);
  const handleButtonClick = async () => {
    try {
      if (!motif ||!description) {
        console.error('Veuillez remplir tous les champs correctement.');
        return;
      }
      const updatedValues = {
         id:values.id,
         animalId:values.animalId,
         serviceId:values.serviceId,
         venteId:values.venteId,
         motif:motif,
         description:description, 
      };
       await UpdateserviceInformationsById(updatedValues);
    } catch (error:any) {
      console.error('Erreur lors de la modification', error.message);
    }
  };
  return (
    <div className="addclientt" ref={modalRef}>
      <button className="buttonn"  onClick={handleButtonClick}>
        <div className="backgroundd" />
        <div className="suivantt">Suivant</div>
      </button>
      <input className="inputt-2" 
      value={motif}
      placeholder="CodeBar" type="text"
      onChange={(e) => setMotif(e.target.value)} />
            <input className="inputt-1" 
      value={description}
      placeholder="CodeBar" type="text" onChange={(e) => setdescription(e.target.value)} />
      <div className="headd">
        <div className="dotss">
          <div className="dotss-child" />
          <div className="dotss-item" />
        </div>
        <div className="ajouter-clientt">Details du motif et description:</div>
      </div>
    </div>
  );
};

export default MotifDetails;
