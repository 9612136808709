const initialAuthState = {
  caisse: { },
};

function caisseReducer(state = initialAuthState, action) {
  if (action.type === "setCaisse") {
    if (action?.payload) {
      let caisse = action.payload;
      return {
        caisse: caisse,
      };
    }
  }
  if (action.type === "removeCaisse") {
      return {
        caisse:initialAuthState,
    }
  }
  return state;
}

export default caisseReducer;
