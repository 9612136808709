import React from 'react';
import '../../css/boxserv.css';
type propserv={
    titleboxserv:string;
    
}

function BoxServ (props:propserv){
    return(
        <div className='boxservbody'>
           <div className='titeboxserv'>{props.titleboxserv}</div> 
           
        </div>
    );
};
export default BoxServ;
