import React from "react";

import { FunctionComponent } from "react";
import Notifications from "../../components/Home/Notifications";
import CaisseContainer from "../../components/Home/CaisseContainer";
import CardContainer from "../../components/Home/CardContainer";
import "../../css/Home.css";
import Cookies from "js-cookie";
import { useVetoContext } from "../../api/Context";
import { useDispatch, useSelector } from 'react-redux'
import { notify,instance } from "../../api/api";
const Home: FunctionComponent = () => {
  const cs_r = useSelector((state:any)=>(state.caisseReducer.caisse))
  const auth = useSelector((state:any) =>(state.authReducer.auth)) 
  console.log(cs_r)
  const dispatch = useDispatch();
  const handelCloture = async () => {
    try {

      let user = auth?.user;

      if (user?.id !== cs_r?.userId) {
        notify("error", "caisse opened by another user!");
        return;
      }
      if (!cs_r?.open) {
        notify("error", "caisse deja cloturée!");
        return;
      }
      let url = `/caisse/${cs_r?.id}`;
      let data = { open: false };
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "fournisseurs",
      };
   const res=await instance.put(url, { data }, { headers })
          if (res?.data?.updated) {
            notify("success", "Caisse Cloturée");
            dispatch({ type: "setCaisse", payload: {} });
            return;
          }
          notify("err", res.data.message);
    } catch (error:any) {
      notify("err", error.message);
    }
  };
  const handellogout = async () => {
    Cookies.remove("auth_token");
    sessionStorage.removeItem("isLoggedIn");
    await handelCloture()
    window.location.href = "/";
  };
  const { notificationss } = useVetoContext();
  console.log(notificationss);
  return (
    <div className="home">
      <div className="cards2">
        <Notifications notifications={notificationss} />
        <CaisseContainer />
        <img
          className="logout"
          src="./Images/HomePage/logout2.png"
          alt="logout"
          onClick={handellogout}
        />
      </div>
      <div className="title">
        <img className="logo-m-1" alt="" src="/logo-m-1.svg" />
        <b className="bienvenu-au-vetapp">Bienvenu au VetApp</b>
      </div>

      <CardContainer />
    </div>
  );
};

export default Home;
