import React from 'react'
import { useSelector } from 'react-redux'
import UnAuthorized from '../components/unauthorized/unauthorized'
export default function 

RequireAuth({checkFor, children}) {

    function rolesDeCoder(input) {
       if(!input){
        return []
       }
       try{
        let arr = ["Home","Clients","Animal","Produit","Ventes","caisse","RendezVous","Hospitalisation","Achats","Pointdevente","utilisateurs","CaisseGenerale"]
       
       if (typeof input === "string") {
         if (input.length === 12 && /^[01]+$/.test(input)) {
           const result = input.split('').map((digit, index) => digit === '1' ? arr[index] : null).filter(Boolean);
           return result;
         } else {
           throw new Error("Invalid input string. Expected a 10-character string consisting of '0' or '1'.");
         }
       } else if (Array.isArray(input)) {
         const result = arr.map((element) => input.includes(element) ? '1' : '0').join('');
         return result;
       } else{
         throw new Error("Invalide input ???")
       }
      }catch(err){
       throw new Error(err.message)
      }
         }
       
    const auth = useSelector(state =>(state.authReducer.auth)) 
    const userRoles = rolesDeCoder(auth.roles)
    const authorized = userRoles.includes(checkFor)
         if(!authorized) {return <UnAuthorized/>}
  return (
   children
  )
}
