import {
  plusIcon,
  deleteIcon,
  frwrd,
  moreIcon,
  options,
  rtrn,
  searchIcon,
  downIcon,
  minusIcon,
  arrowUpIcon,
  cancelBlack,
  cancelWhite,
  arrowIcon,
} from "../../assets/icons";
import Buttons1 from "../../components/Clients/Buttons1";
import "./transactionList.css";
import {instance,notify} from '../../api/api';
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
const pager = (length, perPage) => {
  let actualLength = length / perPage;
  let pages = [];
  for (let i = 0; i < actualLength; i = i + 1) {
    pages[i] = i + 1;
  }
  return pages;
};

export default function TransactionList() {
  const perPage = 11;
  const [fetched, setFetched] = useState(0)
  const [active, setActive] = useState(1);
  const [activeTransaction, setActiveTransaction] = useState({});
  const [deleteWindow, setDeleteWindow] = useState(false);
  const [selected, setSelected] = useState([]);
  const [activeOption, setActiveOption] = useState("");
  const [filtred, setFiltred] = useState([]);
  const [filterActive, setFilterActive] = useState("");
  const [pages, setPages] = useState([]);
  const navigate=useNavigate();
  const [numbers, setNumbers] = useState({
    achats: 0,
    credit: 0,
    transactions: 0,
  });
  const [validator,setValidator] = useState(false)
  const [transactions, setTransactions] = useState([]);
  const [overlay, setOverlay] = useState(false);
  const [activeStatus, setActiveStatus] = useState("not-payed");
  const [payement, setPayement] = useState(0);
  const [sortType, setSortType] = useState("name");
  const [sortModifier, setSortModifier] = useState("asc");
  const handelSort = (type) => {
    let newSortModifier;
    if (sortModifier === "asc") {
      newSortModifier = "desc";
    } else {
      newSortModifier = "asc";
    }
    if (type === "name") {
      if (sortType !== "name") {
        setSortType("name");
      }
      if (newSortModifier === "asc") {
        let newTransactionsList = transactions.sort((a, b) => {
          let nameA = ""
          let nameB = ""
          if(a.type === "T"){
             nameA = a.fournisseur.firstname.toUpperCase();

          }else{ nameA = a.caisse.user.email.toUpperCase();
          }
          if(b.type === "T"){
             nameB = b.fournisseur.firstname.toUpperCase();

          }else{
             nameB = b.caisse.user.email.toUpperCase();

          }
          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1;
          } else {
            return 0;
          }
        });
        setTransactions([...newTransactionsList]);
        setSortModifier(newSortModifier);
      }
      if (newSortModifier === "desc") {
        let newTransactionsList = transactions.sort((a, b) => {
          let nameA = ""
          let nameB = ""
          if(a.type === "T"){
             nameA = a.fournisseur.firstname.toUpperCase();

          }else{ nameA = a.caisse.user.email.toUpperCase();
          }
          if(b.type === "T"){
             nameB = b.fournisseur.firstname.toUpperCase();

          }else{
             nameB = b.caisse.user.email.toUpperCase();

          }
          if (nameA > nameB) {
            return -1;
          } else if (nameA < nameB) {
            return 1;
          } else {
            return 0;
          }
        });
        setTransactions([...newTransactionsList]);
        setSortModifier(newSortModifier);
      }
    }
    if (type === "date") {
      if (sortType !== "date") {
        setSortType("date");
      }
      if (newSortModifier === "asc") {
        let newTransactionsList = transactions.sort((a, b) => {
          var dateA = new Date(a.createdAt);
          var dateB = new Date(b.createdAt);

          return dateA - dateB;
        });
        setTransactions([...newTransactionsList]);
        setSortModifier(newSortModifier);
      }
      if (newSortModifier === "desc") {
        let newTransactionsList = transactions.sort((a, b) => {
          var dateA = new Date(a.createdAt);
          var dateB = new Date(b.createdAt);

          return dateB - dateA;
        });
        setTransactions([...newTransactionsList]);
        setSortModifier(newSortModifier);
      }
    }
  };

  useEffect(() => {
    if (transactions?.length) {
      let achats = transactions.filter(transaction=>transaction.type === "T").reduce((acc, transaction) => {
        acc += transaction.totale;
        return acc;
      }, 0);
      let credit = transactions.filter(transaction=>transaction.type === "T").reduce((acc, transaction) => {
        acc += transaction.totale - transaction.paye;
        return acc;
      }, 0);
      setNumbers({
        achats: achats,
        credit: credit,
        transactions: transactions.length,
      });
    }
  }, [transactions]);

  const handelOverlay = () => {
    setOverlay((state) => !state);
  };

  const handelpayement = (event) => {
    setPayement(event.target.value);
  };
  const handleclickfour =()=>{
    navigate('/Fournisseurs')
  }
  const handelAddPayement = async (event) => {
    event.preventDefault();
    let url = `/transaction/${activeTransaction?.id}`;

    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      pm_auth: `${Cookies.get("auth_token")}`,
      checkfor: "fournisseurs",
    };
    let data = {
      totale: activeTransaction.totale,
      paye: parseInt(activeTransaction.paye) + parseInt(payement),
    };

    if (
      parseInt(payement) === 0 ||
      activeTransaction?.totale - activeTransaction?.payed < payement
    ) {
     notify("error", "Montant invalide, veuillez le corriger !");
      return;
    }
 try{
    const res=await instance
      .put(url, { data: data }, { headers })
        if (res?.data?.updated) {
          notify("success", "Payement Ajouté");
          if (res.data?.transactions) {
            setTransactions([])
            setFetched(0);
          }
          setPayement(0);
          setOverlay(false);
        } else {
          notify("error", "quelle que chose s'est mal passé");
        }

        return;
      }
      catch(err) {
        notify("error", err.message);
      };
    return;
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.classList.contains("clicked")) {
        handelOptions("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [activeOption]);
  useEffect(() => {
    try {
      setPages(pager(transactions?.length, perPage));
    } catch (err) {
      notify("error", err.message);
    }
  }, [transactions]);

  useEffect(() => {
    const gettransaction = async () => {
      let url = "/transaction";
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "produit",
      };
      try{
      const res=await instance
        .get(url, { headers })
          if (res?.data?.transactions) {

           let newTransactionsList = [...res?.data?.transactions].sort((a, b) => {
          var dateA = new Date(a.createdAt);
          var dateB = new Date(b.createdAt);

          return dateB - dateA;
        });
            newTransactionsList = newTransactionsList.map((transaction)=>{transaction.type = "T";return transaction})
            setTransactions(state=>{ return [...state,...newTransactionsList]});
          }
          // if (res?.data?.message) {
          //  notify("error", res.data.message);
          // }
        }
        catch(err) {
          notify("error", err.message);
        };
    };
    const getretraits = async () => {
      let url = "/retrait/all";
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "produit",
      };
      try{
      const res=await instance
        .get(url, { headers })
          if (res?.data?.retraits) {

            let newretraitsList = [...res?.data?.retraits].sort((a, b) => {
              var dateA = new Date(a.createdAt);
              var dateB = new Date(b.createdAt);
    
              return dateB - dateA;
            });
            newretraitsList = newretraitsList.map((retrait)=>{retrait.type = "R";return retrait})

            setTransactions(state=>{return [...state,...newretraitsList]});
          }
          if (res?.data?.message) {
           notify("error", res.data.message);
          }
        }
        catch(err)  {
          notify("error", err.message);
        };
    };
    try {
     if(fetched === 0){
      gettransaction();
      getretraits()
     }
      setFetched(1)
    } catch (err) {
     notify("error", err.message);
    }
  }, [fetched]);
  useEffect(() => {
    if (filterActive) {
      setPages(pager(filtred.length, perPage));
      setActive(1);
    }
  }, [filterActive, filtred]);
  const handeldeletewindow = () => {
    
    if (selected?.length) {
      let isValid = true;

      if (isValid) {
        setDeleteWindow((state) => !state);
        return;
      } else {
       notify("error", "Des records sont liée avec ce produit !");
        return;
      }
    }
   notify("error", "Vous devez selectioné un produit !");
    return;
  };
  const handelselect = (id) => {
    if (selected[0] === id) {
      setSelected([]);
    } else {
      setSelected([id]);
    }
  };
  // const handelselect = (id) => {
  //   const isFound = selected.some((element) => {
  //     if (element === id) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });
  //   if (isFound) {
  //     setSelected((state) => state.filter((idt) => idt != id));
  //   } else {
  //     setSelected((state) => [...state, id]);
  //   }
  // };
  const handelnavigation = (type, page) => {
    if (type === "return") {
      if (active > 1) {
        setActive((state) => state - 1);
      }
    }
    if (type === "frwrd") {
      if (active < pages.at(-1)) {
        setActive((state) => state + 1);
      }
    }
    if (type === "page") {
      setActive(page);
    }
  };
  const handelOptions = (id) => {
    setActiveOption((state) => {
      if (state === id) {
        return "";
      }
      if (id === "") {
        return "";
      }
      return id;
    });
  };
  useEffect(() => {
    const searchHandler = () => {
      setActive(1);
      setFiltred((state) =>
        transactions.filter((transaction) => {
          if (transaction?.type === "T") {
            return transaction?.fournisseur?.firstname
              .toLowerCase()
              .includes(filterActive.toLowerCase());
          }
          if (transaction?.type === "R") {
            return transaction?.caisse?.user.email
              .toLowerCase()
              .includes(filterActive.toLowerCase());
          }
          return false;
        })
      );
    };
    if (filterActive) {
      searchHandler();
    }
  }, [filterActive,transactions]);

  const handeldelete = async () => {
    try {
      if (!selected.length) {
       notify("error", "you should at least select one transaction");
        return;
      }
      let isTransaction = transactions.filter((transaction)=>transaction.id === selected[0])[0].type === "T"
      let url = ""
      if(isTransaction){
         url = `/transaction/${selected[0]}`;

      }else{       url = `/retrait/${selected[0]}`;
    }
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        checkfor: "product",
        pm_auth: `${Cookies.get("auth_token")}`,
      };
      try{
      let data = { hidden: true };
      const res=await instance
        .put(url, { data: data }, { headers })
          if (res?.data?.updated) {
            notify(
              "success",
              `caché(s) avec succés !`
            );
            setTransactions([])
            setFetched(0)
            setDeleteWindow(false);
            setSelected([]);
            setActive(1);

          } else {
           notify("error", "Aucun produit n'est caché, debug nécessaire !");
          }
        }
        catch(err) {
          notify("error", err.message);
        };
    } catch (err) {
      notify("error", err.message);
    }
  };
  return (
    <div className="page_container">
      <Toaster
        toastOptions={{
          style: {
            background: "transparent",
            border: "none",
            boxShadow: "none",
            color: "#ffffff",
          },
        }}
      />
      {deleteWindow ? (
        <div className="delete_overlay">
          <div className="delete-window">
            <img src={cancelBlack} alt="cancel" onClick={handeldeletewindow} />
            <p>Voulez-vous cacher {transactions?.filter(
                    (transaction) => transaction?.id === selected[0])[0].type === "T"?"cette transaction":"ce retrait"} ?</p>
            <p>
              {selected?.length === 0
                ? "Aucun Produit n'est selectioné"
                : selected?.length === 1
                ? transactions?.filter(
                    (transaction) => transaction?.id === selected[0]
                  )[0].id
                : `${selected?.length} transaction sélectionnés`}
            </p>
            <div className="btns">
              <div
                className="delete"
                onClick={() => {
                  if (selected?.length === 0) {
                    notify(
                      "err",
                      "Vous devez selectioné au moin une transaction à cacher !"
                    );
                    return;
                  }
                  handeldelete();
                }}
              >
                <img src={cancelWhite} alt="delete" /> <p>Cacher</p>
              </div>
              <div className="cancel" onClick={handeldeletewindow}>
                <img src={arrowIcon} alt="cancel" /> <p>Annuler</p>
              </div>

            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {overlay ? (
        <div className="overlay">
          <div className="add-payement-window">
            <img src={cancelBlack} alt="cancel" onClick={handelOverlay} />
            {activeStatus === "not-payed" ? (
              <div className={`amount `}>
                <p>Le montant :</p>{" "}
                <input
                  onChange={handelpayement}
                  name="payement"
                  type="number"
                />
                <p>.00 DZD</p>
              </div>
            ) : (
              <p style={{ color: "#FF5B5B", fontSize: " smaller" }}>
                Cette transaction est déja payée !
              </p>
            )}
            {parseInt(activeTransaction.totale) -
              parseInt(activeTransaction.paye) <
              parseInt(payement) ||
            (parseInt(payement) === 0 && activeStatus === "not-payed") ? (
              <p style={{ color: "#FF5B5B", fontSize: "smaller" }}>
                Un payement valide doit être entre 0 DZD et{" "}
                {parseInt(activeTransaction.totale) -
                  parseInt(activeTransaction.paye)}{" "}
                DZD
              </p>
            ) : (
              ""
            )}
            {/* <p className="p">{fournisseur.firstname}</p> */}
            <div className="btns">
              <div
                className="ajouter"
                onClick={(event) => {
                  if (!activeTransaction?.id) {
                    notify(
                      "err",
                      "Vous devez selectioné au moin une transaction à Modifier !"
                    );
                    return;
                  }
                  handelAddPayement(event);
                }}
              >
                <img src={cancelWhite} alt="delete" /> <p>Ajouter</p>
              </div>
              
              <div
                className="cancel"
                onClick={() => {
                  handelOverlay();
                  setActiveStatus("not-payed");
                  setActiveTransaction({});
                  setPayement(0);
                }}
              >
                <img src={arrowIcon} alt="cancel" /> <p>Annuler</p>
              </div>
            </div>

          </div>
        </div>
      ) : (
        ""
      )}
     
      <div className="transactions_list">
        <div className="p1">
          <div className="container c1">
            <p>Transactions</p>
            <p className="num">
              {numbers?.transactions ? numbers.transactions : "0"}
            </p>
          </div>
          <div className="container c2">
            <p>Total Achat </p>
            <p className="num">
              {numbers?.achats ? numbers.achats.toLocaleString("en-DE") : "0"}{" "}
              DZD
            </p>
          </div>
          <div className="container c3">
            <p>Credit</p>
            <p className="num">
              {numbers?.credit ? numbers.credit.toLocaleString("en-DE") : "0"}{" "}
              DZD
            </p>
          </div>
        </div>
        {/* <div className="p1">
                    <div className="btn"><p>+Ajouter</p></div>
                    <div className="bih_holder "></div>
                    <div className="search_bar">
                        <img src={search} alt="search" />
                        <input name="search" placeholder="Recherche d’un fournisseur" type="text" />
                    </div>
                    <div className="little_holder"></div>
                    <div className="little_holder"></div>
                    <div className="little_holder"></div>

                </div> */}
        <div className="p2">
          <div className="hr">
            <p>Achats</p>
            <p className="faded">Liste Des Achats</p>
          </div>
          <div className="searchss">
            <input
              placeholder="Search"
              onChange={(event) => {
                setFilterActive(event.target.value);
              }}
            />
            <img src={searchIcon} alt="search" />
          </div>
          <div className="element" onClick={handeldeletewindow}>
            <img src={deleteIcon} alt="delete" />
            <p>Cacher</p>
          </div>
   <Buttons1
          icon="/Images/Clients/adduser.png"
          text="fournisseurs "
          onClick={handleclickfour }
          background="background"
        /> 
          <div
            className="btn"
            onClick={() => {
              window.location.href = "/addProduct";
            }}
          >
            <img src={moreIcon} alt="more" />
            <p>Ajouter</p>
          </div>
        </div>
        <div className="p3">
          <div className=" table">
            <table>
              <thead>
                <tr style={{ borderTop: "1px solid #EAECF0 " }}>
                  <th className="th1" style={{ width: "1rem" }}>
                    <div className="th1d">
                      <img src={minusIcon} alt="minus" />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Id </p>{" "}
                      <img
                        style={{ cursor: "pointer" }}
                        src={sortModifier === "desc" ? arrowUpIcon : downIcon}
                        alt="down"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Fournisseur / User </p>{" "}
                      <img
                        style={{ cursor: "pointer" }}
                        src={sortModifier === "desc" ? arrowUpIcon : downIcon}
                        alt="down"
                        onClick={() => {
                          handelSort("name");
                        }}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Date </p>{" "}
                      <img
                        style={{ cursor: "pointer" }}
                        src={sortModifier === "desc" ? arrowUpIcon : downIcon}
                        alt="down"
                        onClick={() => {
                          handelSort("date");
                        }}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Montant </p>
                      <img
                        style={{ cursor: "pointer" }}
                        src={sortModifier === "desc" ? arrowUpIcon : downIcon}
                        alt="down"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Reste </p>{" "}
                      <img
                        style={{ cursor: "pointer" }}
                        src={sortModifier === "desc" ? arrowUpIcon : downIcon}
                        alt="down"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Status </p>{" "}
                      <img
                        style={{ cursor: "pointer" }}
                        src={sortModifier === "desc" ? arrowUpIcon : downIcon}
                        alt="down"
                      />
                    </div>
                  </th>
                  <th>
                    <div className="th3"></div>
                  </th>
                </tr>
              </thead>
              {transactions?.length ? (
                <tbody>
                  {filterActive
                    ? filtred
                        .filter((transaction) => !transaction.hidden)
                        .slice((active - 1) * 11, active * 11)
                        .map((transaction, index) => {

                          let newId = transaction?.id.toString();
                          if(transaction.type === "T"){
                          while (newId.length < 6) newId = "0" + newId;
                          }
                           return (
                            <tr
                              key={index}
                              style={{ borderTop: "1px solid #EAECF0 " }}
                            >
                              <td className="td1" style={{ width: "1rem" }}>
                                <div className="td1d">
                                  <div
                                    className={`checkBox ${
                                      selected.indexOf(transaction?.id) >= 0
                                        ? "checked"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      handelselect(transaction?.id);
                                    }}
                                  ></div>
                                </div>
                              </td>
                              <td>
                                <div className="td2 lite">
                                  <p>{transaction.type ==="T"?newId:transaction?.motif?transaction.motif :"aucun motif"} </p>{" "}
                                </div>
                              </td>
                              <td>
                                <div className="td2">
                                  <p>
                                    {transaction.type ==="T"
                                      ? transaction.fournisseur?.firstname
                                      : transaction.type ==="R"? transaction.caisse.user.email :"Not Mentioned"}{" "}
                                  </p>{" "}
                                </div>
                              </td>
                              <td>
                                <div className={`td2 lite `}>
                                  <p>
                                    {transaction?.createdAt
                                      ? `${
                                          new Date(transaction?.createdAt)
                                            .toISOString()
                                            .split("T")[0]
                                        }`
                                      : "aucune date"}{" "}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="td2 lite">
                                  <p>
                                    {transaction.type ==="T"? transaction.totale.toLocaleString(
                                          "en-DE"
                                        )
                                      :transaction.type ==="R"? transaction.montant.toLocaleString(
                                          "en-DE"
                                        ) : "0"}
                                    DA
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className={`td2 lite `}>
                                  <p>
                                    {transaction.type ==="T"
                                      ? (
                                          transaction?.totale -
                                          transaction?.paye
                                        ).toLocaleString("en-DE")
                                      : "0"}{" "}
                                    DA
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="td2 lite">
                                {transaction.type ==="R" ?<div className="tag-green">
                                        <span></span>
                                        <p className="tag-p">Retrait</p>
                                      </div>:""}
                                    {
                                      transaction.totale === transaction.paye && transaction.type === "T" ? (
                                      <div className="tag-green">
                                        <span></span>
                                        <p className="tag-p">Payé</p>
                                      </div>
                                    ) :  transaction.type === "T"?(
                                      <div className="tag-gray">
                                        <span></span>
                                        <p className="tag-p">Non-Payé</p>
                                      </div>
                                    ):""}
                                </div>
                              </td>
                              <td>
                                {transaction.type ==="R"?"":<div
                                  className="td3 clicked"
                                  onClick={() => {
                                    handelOptions(transaction?.id);
                                  }}
                                >
                                  <img
                                    className="clicked"
                                    src={options}
                                    alt="options"
                                  />
                                  <div
                                    onClick={() => {
                                      if (
                                        parseInt(transaction.paye) >=
                                        parseInt(transaction.totale)
                                      ) {
                                        notify(
                                          "success",
                                          "Transaction dejà payée"
                                        );
                                        return;
                                      }
                                      setActiveTransaction(transaction);
                                      handelOverlay();
                                    }}
                                    className="add-payement"
                                    style={
                                      activeOption === transaction?.id
                                        ? {
                                            animation:
                                              "slideDown 0.5s ease-out",
                                          }
                                        : { display: "none" }
                                    }
                                  >
                                    <p>Ajouter un payment</p>
                                    <img src={plusIcon} alt="profile" />
                                  </div>
                                </div>}
                              </td>
                            </tr>
                          );
                        })
                    : transactions
                        .slice((active - 1) * 11, active * 11)
                        .map((transaction, index) => {

                          let newId = transaction?.id.toString();
                          if(transaction.type === "T"){
                          while (newId.length < 6) newId = "0" + newId;
                          }
                          return (
                            <tr
                              key={index}
                              style={{ borderTop: "1px solid #EAECF0 " }}
                            >
                              <td className="td1" style={{ width: "1rem" }}>
                                <div className="td1d">
                                  <div
                                    className={`checkBox ${
                                      selected.indexOf(transaction?.id) >= 0
                                        ? "checked"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      handelselect(transaction?.id);
                                    }}
                                  ></div>
                                </div>
                              </td>
                              <td>
                                <div className="td2 lite">
                                  <p>{transaction.type ==="T"?newId:transaction?.motif?transaction.motif :"aucun motif"} </p>{" "}
                                </div>
                              </td>
                              <td>
                                <div className="td2">
                                  <p>
                                    {transaction.type ==="T"
                                      ? transaction.fournisseur?.firstname
                                      : transaction.type ==="R"? transaction.caisse.user.email :"Not Mentioned"}{" "}
                                  </p>{" "}
                                </div>
                              </td>
                              <td>
                                <div className={`td2 lite `}>
                                  <p>
                                    {transaction?.createdAt
                                      ? `${
                                          new Date(transaction?.createdAt)
                                            .toISOString()
                                            .split("T")[0]
                                        }`
                                      : "aucune date"}{" "}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="td2 lite">
                                  <p>
                                    {transaction.type ==="T"? transaction.totale.toLocaleString(
                                          "en-DE"
                                        )
                                      :transaction.type ==="R"? transaction.montant.toLocaleString(
                                          "en-DE"
                                        ) : "0"}
                                    DA
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className={`td2 lite `}>
                                  <p>
                                    {transaction.type ==="T"
                                      ? (
                                          transaction?.totale -
                                          transaction?.paye
                                        ).toLocaleString("en-DE")
                                      : "0"}{" "}
                                    DA
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="td2 lite">
                                {transaction.type ==="R" ?<div className="tag-green">
                                        <span></span>
                                        <p className="tag-p">Retrait</p>
                                      </div>:""}
                                    {
                                      transaction.totale === transaction.paye && transaction.type === "T" ? (
                                      <div className="tag-green">
                                        <span></span>
                                        <p className="tag-p">Payé</p>
                                      </div>
                                    ) :  transaction.type === "T"?(
                                      <div className="tag-gray">
                                        <span></span>
                                        <p className="tag-p">Non-payé</p>
                                      </div>
                                    ):""}
                                </div>
                              </td>
                              <td>
                                {transaction.type ==="R"?"":<div
                                  className="td3 clicked"
                                  onClick={() => {
                                    handelOptions(transaction?.id);
                                  }}
                                >
                                  <img
                                    className="clicked"
                                    src={options}
                                    alt="options"
                                  />
                                  <div
                                    onClick={() => {
                                      if (
                                        parseInt(transaction.paye) >=
                                        parseInt(transaction.totale)
                                      ) {
                                        notify(
                                          "success",
                                          "Transaction dejà payée"
                                        );
                                        return;
                                      }
                                      setActiveTransaction(transaction);
                                      handelOverlay();
                                    }}
                                    className="add-payement"
                                    style={
                                      activeOption === transaction?.id
                                        ? {
                                            animation:
                                              "slideDown 0.5s ease-out",
                                          }
                                        : { display: "none" }
                                    }
                                  >
                                    <p>Ajouter un payment</p>
                                    <img src={plusIcon} alt="profile" />
                                  </div>
                                </div>}
                              </td>
                            </tr>
                          );
                        })}
                  {/* <tr style={{ borderTop: "1px solid #EAECF0 " }}>
                                    <td className="td1" style={{ width: "1rem" }}><div><input type="checkbox" /></div></td>
                                    <td><div className="td2"><p>Nom et Prénom </p> </div></td>
                                    <td><div className="td2 lite"><p>12/05/2023 </p> </div></td>
                                    <td><div className="td2 lite"><p>1000,00 DA </p> </div></td>
                                    <td><div className="td3" onClick={handelOptions}><img src={options} alt="options" /><div className="openProfile" style={activeOption ? { animation: "slideDown 0.5s ease-out" } : { display: "none" }} ><p>Oper Profile</p><img src={linkIcon} alt="profile" /></div></div></td>
                                </tr> */}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={8}>
                      <div className="no-fr">
                        <p>
                          Aucun produit n'est retrouver ! veuillez Ajouter Un!
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          <div
            className="p-n"
            style={{ width: "100%", margin: "auto auto 1rem auto" }}
          >
            <div className="pagination">
              <div
                className="nav"
                onClick={() => {
                  handelnavigation("return");
                }}
                style={
                  active === 1 || pages.length === 0
                    ? {
                        backgroundColor: "#F5F5F5",
                        opacity: ".6",
                        cursor: "default",
                      }
                    : {}
                }
              >
                <img src={rtrn} alt="return" />
              </div>
              {active >= 3 && pages.length > 3 ? (
                <div className="ect">...</div>
              ) : (
                ""
              )}
              {pages.length > 3
                ? active < 3 && active > 0
                  ? pages?.slice(0, 3).map((page, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            handelnavigation("page", page);
                          }}
                          className="page"
                          style={
                            active === page
                              ? { backgroundColor: "#100DB1" }
                              : {}
                          }
                        >
                          {" "}
                          <p style={page === active ? { color: "white" } : {}}>
                            {page}
                          </p>
                        </div>
                      );
                    })
                  : active > pages.length - 3
                  ? pages
                      ?.slice(pages.length - 3, pages.length)
                      .map((page, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              handelnavigation("page", page);
                            }}
                            className="page"
                            style={
                              active === page
                                ? { backgroundColor: "#100DB1" }
                                : {}
                            }
                          >
                            {" "}
                            <p
                              style={page === active ? { color: "white" } : {}}
                            >
                              {page}
                            </p>
                          </div>
                        );
                      })
                  : pages?.slice(active - 2, active + 2).map((page, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            handelnavigation("page", page);
                          }}
                          className="page"
                          style={
                            active === page
                              ? { backgroundColor: "#100DB1" }
                              : {}
                          }
                        >
                          {" "}
                          <p style={page === active ? { color: "white" } : {}}>
                            {page}
                          </p>
                        </div>
                      );
                    })
                : pages?.map((page, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          handelnavigation("page", page);
                        }}
                        className="page"
                        style={
                          active === page ? { backgroundColor: "#100DB1" } : {}
                        }
                      >
                        {" "}
                        <p style={page === active ? { color: "white" } : {}}>
                          {page}
                        </p>
                      </div>
                    );
                  })}

              {active < pages.length - 2 && pages.length > 3 ? (
                <div className="ect">...</div>
              ) : (
                ""
              )}
              <div
                className="frwrd"
                onClick={() => {
                  handelnavigation("frwrd");
                }}
                style={
                  active === pages.at(-1)
                    ? {
                        backgroundColor: " #F5F5F5",
                        opacity: ".6",
                        cursor: "default",
                      }
                    : {}
                }
              >
                <div
                  className="nav"
                  style={
                    active === pages.at(-1) || pages.length === 0
                      ? {
                          backgroundColor: " #F5F5F5",
                          opacity: ".6",
                          cursor: "default",
                        }
                      : {}
                  }
                >
                  <img src={frwrd} alt="next" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
