import "./addFournisseur.css"
import FournisseurForm from "../../components/FornisseurForm/FournisseurForm"
import React from 'react'

export default function AddFournisseur() {
  return (
    <div className="page_container">
        <FournisseurForm/>
    </div>
  )
}
