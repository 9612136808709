import React, { FunctionComponent } from 'react';
import '../../css/servicetable.css';
import { useBarcode } from '@createnextapp/react-barcode';
import IconButton from '@material-ui/core/IconButton';
import { DeleteService } from '../../api/api';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';

interface Product {
  produitId: string;
  serviceId: string;
  assignedAt: string;
  produit: any;
  qt: number;
}

interface Service {
  id: string;
  barCode: string;
  createdAt: string;
  name: string;
  prix: number;
  hidden: boolean;
  products: Product[];
}

interface ServiceTableProps {
  services: Service[];
}

const ServiceTable: FunctionComponent<ServiceTableProps> = ({ services }) => {
  const Deleteservice = async (serviceId: any) => {
    console.log(serviceId);
    const reponse = await DeleteService(serviceId);
  };
  return (
    <div className="servicestable">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className='cellprod'>
              <TableSortLabel>Code</TableSortLabel>
            </TableCell>
            <TableCell className='cellprod'>
              <TableSortLabel>Nom</TableSortLabel>
            </TableCell>
            <TableCell className='cellprod'>
              <TableSortLabel>Produits</TableSortLabel>
            </TableCell>
            <TableCell className='cellprod'>
              <TableSortLabel>Action</TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services?.map((service) => (
            <TableRow key={service.id}>
              <TableCell>
                <div>
                  {service.barCode && (
                    <BarcodeRenderer value={service.barCode} />
                  )}
                </div>
              </TableCell>
              <TableCell>{service.name}</TableCell>
              <TableCell>
                {service.products?.map((product) => (
                  <div key={product.produitId}>
                    <div>Nom : {product.produit.name}</div>
                    <div>Qté: {product.qt}</div>
                    <div>date de Peremption: {new Date(
                                          product.produit.dateDePeremption
                                        ).toISOString().split("T")[0]}</div>
                    <div>-----</div>
                  </div>
                ))}
              </TableCell>
              <TableCell>
                <div className="action-buttons">
                  <IconButton aria-label="delete"
                   onClick={() => DeleteService(service.id)}
                   >
                    <DeleteIcon />
                  </IconButton>
                  {/* <IconButton aria-label="edit" >
                    <EditIcon />
                  </IconButton> */}
                  </div>
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const BarcodeRenderer: FunctionComponent<{ value: string }> = ({ value }) => {
  const { inputRef } = useBarcode({
    value,
    options: {
      background: '#ffffff',
      fontSize: 20,
      margin: 30,
      fontOptions: 'bold',
      width: 1,
      height: 70,
    },
  });

  return <svg ref={inputRef} />;
};

export default ServiceTable;
