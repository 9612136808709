import React, { FunctionComponent,useState } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import '../../css/AnimeauxTable.css'
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useNavigate } from 'react-router-dom';

interface AnimauxTableProps {
  selectedClientId: number | null;
  animals: { id: number; name: string; race: string }[];
  onDeleteAnimal: (animalIndex: number) => void;
}

const AnimauxTable: FunctionComponent<AnimauxTableProps> = ({ selectedClientId, animals, onDeleteAnimal }) => {
  const navigate = useNavigate();
  const [isRowChecked, setIsRowChecked] = useState(false);
  const handleClickAnimal = (animal: { id: number; name: string; race: string }) => {
    setIsRowChecked(!isRowChecked);
    navigate(`/Animal/${selectedClientId}/${animal.id}`);
  };
  return (
    <div className="animeauxtable">
      {selectedClientId !== null ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Animal</TableCell>
              <TableCell>Race</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {animals.map((animal, index) => (
              <TableRow key={index}
              onClick={() => handleClickAnimal(animal)}
              style={{
                backgroundColor: isRowChecked ? 'var(--color-mediumturquoise2)' : undefined,
              }}
             >
                <TableCell>
                  <span
                  >
                    {animal.name}
                  </span>
                </TableCell>
                <TableCell>{animal.race}</TableCell>
                <TableCell>
                  <IconButton aria-label="delete" onClick={() => onDeleteAnimal(index)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <p>Sélectionnez un client pour afficher ses animaux.</p>
      )}
    </div>
  );
};

export default AnimauxTable;
