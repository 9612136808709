import { useReactToPrint } from "react-to-print";

import {
  plusIcon,
  filtersIcon,
  frwrd,
  options,
  rtrn,
  searchIcon,
  downIcon,
  arrowUpIcon,
  cancelBlack,
  cancelWhite,
  arrowIcon,
  printIcon,
} from "../../assets/icons";
import "./ventesList.css";
import toast, { Toaster } from "react-hot-toast";
import { instance,notify } from "../../api/api";
import React, { useEffect, useRef, useState } from "react";

import Cookies from "js-cookie";
import { Reciept } from "../../components/Reciept_outer/Reciept";
const pager = (length, perPage) => {
  let actualLength = length / perPage;
  let pages = [];
  for (let i = 0; i < actualLength; i = i + 1) {
    pages[i] = i + 1;
  }
  return pages;
};

export default function VentesList() {
  const [ventes, setVentes] = useState([]);
  const perPage = 11

  const [active, setActive] = useState(1);
  const [activeTransaction, setActiveTransaction] = useState({});
  const [activeOption, setActiveOption] = useState("");
  const [filtred, setFiltred] = useState([]);
  const [filterActive, setFilterActive] = useState("");
  const [pages, setPages] = useState([]);
  const [filtertype, setfiltertype] = useState(0)
  const [numbers, setNumbers] = useState({
    total: {products: 0, services: 0},
    credit: 0,
    transactions: 0,
  });
  const [overlay, setOverlay] = useState(false);
  const [activeStatus, setActiveStatus] = useState("not-payed");
  const [payement, setPayement] = useState(0);
  const [validator, setValidator] = useState(false);
  const componentRef2 = useRef(null);
  const [timeFilter,setTimeFilter] = useState([])

  const [sortType,setSortType] = useState("name")
  const [sortModifier,setSortModifier] = useState("asc")
 const  handelSort =(type)=>{
        let newSortModifier;
        if(sortModifier==="asc"){newSortModifier = "desc"}else{newSortModifier ="asc"}
        if(type ==="name"){
          if(sortType !== "name") {setSortType("name")}
          if(newSortModifier==="asc"){
          let newVentesList = ventes.sort((a,b)=>{
            let nameA =a?.client? a.client?.firstname.toUpperCase():null
            let nameB = b?.client? b.client?.firstname.toUpperCase():null
            if (nameA === null && nameB === null) {
              return 0; // If both dates are null, maintain the order
            } else if (nameA === null) {
              return 1; // Place null values at the end
            } else if (nameB === null) {
              return -1; // Place null values at the end
            } else if (nameA < nameB) {
              return -1;
            } else if (nameA > nameB) {
              return 1;
            } else {
              return 0;
            }
          })
          setVentes([...newVentesList])
          setSortModifier(newSortModifier)
          }
          if(newSortModifier==="desc"){
            let newVentesList = ventes.sort((a,b)=>{
              let nameA = a?.client? a.client?.firstname.toUpperCase():null
              let nameB = b?.client? b.client?.firstname.toUpperCase():null
              if (nameA === null && nameB === null) {
                return 0; // If both dates are null, maintain the order
              } else if (nameA === null) {
                return -1; // Place null values at the end
              } else if (nameB === null) {
                return 1; // Place null values at the end
              } else if (nameA > nameB) {
                return -1;
              } else if (nameA < nameB) {
                return 1;
              } else {
                return 0;
              }
            })
            setVentes([...newVentesList])
            setSortModifier(newSortModifier)
            }
        }
        if(type ==="date"){
          if(sortType !== "date") {setSortType("date")}
          if(newSortModifier==="asc"){
          let newVentesList = ventes.sort((a,b)=>{
            var dateA = new Date(a.createdAt);
            var dateB = new Date(b.createdAt);
        
            return dateA - dateB;
          })
          setVentes([...newVentesList])
          setSortModifier(newSortModifier)
          }
          if(newSortModifier==="desc"){
            let newVentesList = ventes.sort((a,b)=>{
              var dateA = new Date(a.createdAt);
              var dateB = new Date(b.createdAt);
          
              return dateB - dateA;})
              setVentes([...newVentesList])
              setSortModifier(newSortModifier)
            }
        }


 }












  const handlePrint = useReactToPrint({
    content: () => componentRef2.current,
  });





 useEffect(()=>{
   const filterVentesByDate = ()=>{
    const day = new Date()
    const currentWeekStart = new Date(day);

    currentWeekStart.setDate(day.getDate() - day.getDay());
    currentWeekStart.setHours(0, 0, 0, 0);

    const currentMonthStart = new Date(day.getFullYear(), day.getMonth(), 1);
    if (filtertype === 1) { // Filter by today
      notify("success","daily filter active")
      return ventes.filter(vente => {
        const venteDate = new Date(vente.createdAt);
        return venteDate.toDateString() === day.toDateString();
      })
    } else if (filtertype === 2) { // Filter by current week
      notify("success","weekly filter active")

      return ventes.filter(vente => {
        const venteDate = new Date(vente.createdAt);
        return venteDate >= currentWeekStart && venteDate <= day;
      });
    } else if (filtertype === 3) { // Filter by current month
      notify("success","monthly filter active")

      return ventes.filter(vente => {
        const venteDate = new Date(vente.createdAt);
        return venteDate >= currentMonthStart && venteDate <= day;
      });
    } else {
      notify("error","Filter disabled")
      return ventes;
    }
   }
  
  

  
if(ventes?.length){
  let f = filterVentesByDate(ventes,filtertype)
  setTimeFilter(f)
  setFiltred(f)

}
 },[filtertype])
  


const handelfiltertype = ()=>{
  if(filtertype===3){
    setfiltertype(0)
    return
  }
  setfiltertype(state=>state+1)
}


































  useEffect(() => {

    if (filtred?.length || filtertype !== 0) {
      let totalp = filtred.reduce((acc, vente) => {
       if(vente?.produits?.length){
        acc += vente.produits.reduce((acc,produit)=>{
          acc += produit.qt *produit.produit.prix
          return acc
        },0)
       }
       return acc
      }, 0);
      let credit = filtred.reduce((acc, vente) => {
        acc += vente.prix - vente.payed;
        return acc;
      }, 0);
      let totals = filtred.reduce((acc, vente) => {
        if(vente?.services?.length){
         acc += vente.services.reduce((acc,service)=>{
           acc += service.qt *service.service.prix
           return acc
         },0)
        }
        return acc
       }, 0);

      setNumbers({ total: {products :totalp,services :totals}, credit: credit, ventes: ventes.length });
      return
    }
    if (ventes?.length ) {
      let totals = ventes.reduce((acc, vente) => {
        if(vente?.services?.length){
         acc += vente.services.reduce((acc,service)=>{
           acc += service.qt *service.service.prix
           return acc
         },0)
        }
        return acc
       }, 0);
       let totalp = ventes.reduce((acc, vente) => {
        if(vente?.produits?.length){
         acc += vente.produits.reduce((acc,produit)=>{
           acc += produit.qt *produit.produit.prix
           return acc
         },0)
        }
        return acc
       }, 0);
      let credit = ventes.reduce((acc, vente) => {
        acc += vente.prix - vente.payed;
        return acc;
      }, 0);
      setNumbers({ total: {products :totalp,services :totals}, credit: credit, ventes: ventes.length });
    }
  }, [ventes,filtred]);

  const handelOverlay = () => {
    setOverlay((state) => !state);
  };

  const handelpayement = (event) => {
    setPayement(filterActive);
  };
  const handelAddPayement = async (event) => {
    event.preventDefault();
    let url = `/vente/${activeTransaction.id}`;

    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      pm_auth: `${Cookies.get("auth_token")}`,
      checkfor: "fournisseurs",
    };
    let data = {
      payed: parseInt(activeTransaction.payed) + parseInt(payement),
    };

    if (
      parseInt(payement) === 0 ||
      activeTransaction?.prix - activeTransaction?.payed < payement
    ) {
      notify("error", "Montant invalide, veuillez le corriger !");
      return;
    }

    instance
      .put(url, { data: data }, { headers })
      .then((res) => {
        if (res?.data?.updated) {
          notify("success", "Payement Ajouté");
          setValidator((state) => !state);
          setPayement(0);
          setOverlay(false);
        } else {
          notify("error", "quelle que chose s'est mal passé");
        }

        return;
      })
      .catch((err) => {
        notify("error", err.message);
      });
    return;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.classList.contains("clicked")) {
        handelOptions("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [activeOption]);
  useEffect(() => {
    try {
      setPages(pager(ventes?.length, perPage));
    } catch (err) {
      notify("error", err.message);
    }
  }, [ventes]);
  useEffect(() => {

    const getventes = async () => {
      let url = "/vente";
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "produit",
      };
          const res=await instance.get(url, { headers })
        .then(async (res) => {

          if (res?.data?.ventes) {
            setVentes(res.data.ventes);
          }
        })
        .catch((err) => {
          notify("error", err.message);
        });
    };

    try {
      getventes();
    } catch (err) {
      notify("error", err.message);
    }
  }, []);
  useEffect(() => {
    const getventes = async () => {
      let url = "/vente";
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "produit",
      };
          const res=await instance.get(url, { headers })
        .then(async (res) => {

          if (res?.data?.ventes) {
            let newVentesList = res.data.ventes.sort((a,b)=>{
              let nameA = a?.client? a.client?.firstname.toUpperCase():null
              let nameB = b?.client? b.client?.firstname.toUpperCase():null
              if (nameA === null && nameB === null) {
                return 0; // If both dates are null, maintain the order
              } else if (nameA === null) {
                return -1; // Place null values at the end
              } else if (nameB === null) {
                return 1; // Place null values at the end
              } else if (nameA > nameB) {
                return -1;
              } else if (nameA < nameB) {
                return 1;
              } else {
                return 0;
              }
            })
            setVentes(newVentesList);
          }
        })
        .catch((err) => {
          notify("error", err.message);
        });
    };

    try {
      getventes();
    } catch (err) {
      notify("error", err.message);
    }
  }, [validator]);
  useEffect(() => {
    if(filtred?.length){
      setPages(pager(filtred.length, perPage));
      setActive(1);
    }
  }, [filtred]);

  // const handelselect = (id) => {
  //   const isFound = selected.some((element) => {
  //     if (element === id) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });
  //   if (isFound) {
  //     setSelected((state) => state.filter((idt) => idt != id));
  //   } else {
  //     setSelected((state) => [...state, id]);
  //   }
  // };
  const handelnavigation = (type, page) => {
    if (type === "return") {
      if (active > 1) {
        setActive((state) => state - 1);
      }
    }
    if (type === "frwrd") {
      if (active < pages.at(-1)) {
        setActive((state) => state + 1);
      }
    }
    if (type === "page") {
      setActive(page);
    }
  };
  const handelOptions = (id) => {
    setActiveOption((state) => {
      if (state === id) {
        return "";
      }
      if (id === "") {
        return "";
      }
      return id;
    });
  };
  useEffect(() => {
    const searchHandler = () => {
      setActive(1);
      let newV = [];
  
      if (filtertype !== 0) {
        newV = timeFilter.filter((vente) => {
          return applyFilter(vente);
        });
      } else {
        newV = ventes.filter((vente) => {
          return applyFilter(vente);
        });
      }
  
      console.log(newV);
      setFiltred(newV);
    };
  
    const applyFilter = (vente) => {
      const clientMatch =
        vente?.client &&
        (vente?.client?.firstname.toLowerCase().includes(filterActive.toLowerCase()) ||
          vente?.client?.lastname.toLowerCase().includes(filterActive.toLowerCase()));
  
      const idMatch = vente?.id.toString().toLowerCase().includes(filterActive.toLowerCase());
      const guestMatch = "Guest".toLowerCase().includes(filterActive.toLowerCase());
  
      const dateMatch =
        filterActive &&
        new Date(vente.createdAt).toISOString().split('T')[0] === filterActive;
  
      return clientMatch || idMatch || guestMatch || dateMatch;
    };
  
    if (filterActive || filtertype !== 0) {
      searchHandler();
    }
  }, [filterActive, filtertype, ventes, timeFilter]);
  
  return (
    <div className="page_container">
      {activeTransaction?.id?<div style={{ display: "none" }}>
        <Reciept vente={activeTransaction} ref={componentRef2}/>
      </div>:<></>}
      <Toaster
        toastOptions={{
          style: {
            background: "transparent",
            border: "none",
            boxShadow: "none",
            color: "#ffffff",
          },
        }}
      />

      {overlay ? (
        <div className="overlay">
          <div className="add-payement-window">
            <img src={cancelBlack} alt="cancel" onClick={handelOverlay} />
            {activeStatus === "not-payed" ? (
              <div className={`amount `}>
                <p>Le montant :</p>{" "}
                <input
                  onChange={handelpayement}
                  name="payement"
                  type="number"
                />
                <p> DZD</p>
              </div>
            ) : (
              <p style={{ color: "#FF5B5B", fontSize: " smaller" }}>
                Cette transaction est déja payée !
              </p>
            )}
            {parseInt(activeTransaction.prix) -
              parseInt(activeTransaction.payed) <
              parseInt(payement) ||
            (parseInt(payement) === 0 && activeStatus === "not-payed") ? (
              <p style={{ color: "#FF5B5B", fontSize: "smaller" }}>
                Un payement valide doit être entre 0 DZD et{" "}
                {parseInt(activeTransaction.totale) -
                  parseInt(activeTransaction.paye)}{" "}
                DZD
              </p>
            ) : (
              ""
            )}
            {/* <p className="p">{fournisseur.firstname}</p> */}
            <div className="btns">
              <div
                className="ajouter"
                onClick={(event) => {
                  if (!activeTransaction?.id) {
                    notify(
                      "err",
                      "Vous devez selectioné au moin une transaction à Modifier !"
                    );
                    return;
                  }
                  handelAddPayement(event);
                }}
              >
                <img src={cancelWhite} alt="delete" /> <p>Ajouter</p>
              </div>
              <div
                className="cancel"
                onClick={() => {
                  handelOverlay();
                  setActiveStatus("not-payed");
                  setActiveTransaction({});
                  setPayement(0);
                }}
              >
                <img src={arrowIcon} alt="cancel" /> <p>Annuler</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="ventes_list">
        <div className="p1">
          <div className="container c1">
            <p>Nombre De Ventes</p>
            <p className="num">{numbers?.ventes ? numbers.ventes : "0"}</p>
          </div>
          <div className="container c2">
            <p>Ventes Produits</p>
            <p className="num">
              {numbers?.total.products ? numbers.total.products.toLocaleString("en-DE") : "0"} DZD
            </p>
          </div>
          <div className="container c2">
            <p>Ventes Services</p>
            <p className="num">
              {numbers?.total ? numbers.total.services.toLocaleString("en-DE") : "0"} DZD
            </p>
          </div>
          <div className="container c3">
            <p>Credit</p>
            <p className="num">
              {numbers?.credit ? numbers.credit.toLocaleString("en-DE") : "0"}{" "}
              DZD
            </p>
          </div>
        </div>
        {/* <div className="p1">
                    <div className="btn"><p>+Ajouter</p></div>
                    <div className="bih_holder "></div>
                    <div className="search_bar">
                        <img src={search} alt="search" />
                        <input name="search" placeholder="Recherche d’un fournisseur" type="text" />
                    </div>
                    <div className="little_holder"></div>
                    <div className="little_holder"></div>
                    <div className="little_holder"></div>

                </div> */}
        <div className="p2">
          <div className="hr">
            <p>Ventes</p>
            <p className="faded">Liste Des Ventes</p>
          </div>
          <div className="searchss">
            <input
              placeholder="Search"
              onChange={(event) => {
                setFilterActive(event.target.value);
              }}
            />
              <input
    type="date"
    onChange={(event) => {
      setFilterActive(event.target.value);

    }}
  />
            {/* <img src={searchIcon} alt="search" /> */}
          </div>

          <div className="element" onClick={handelfiltertype}>
            <img src={filtersIcon} alt="filters" />
            <p>Filters</p>
          </div>
        </div>
        <div className="p3">
          <div className=" table">
            <table>
              <thead>
                <tr style={{ borderTop: "1px solid #EAECF0 " }}>
                  <th>
                    <div className="th2">
                      <p>Id </p> <img style={{cursor : "pointer"}} src={sortModifier==="desc"?arrowUpIcon:downIcon} alt="down"  />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Client </p> <img style={{cursor : "pointer"}} src={sortModifier==="desc"?arrowUpIcon:downIcon} alt="down" onClick={()=>{handelSort("name")}} />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Date </p> <img style={{cursor : "pointer"}} src={sortModifier==="desc"?arrowUpIcon:downIcon} alt="down" onClick={()=>{handelSort("date")}} />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Montant </p> <img style={{cursor : "pointer"}} src={sortModifier==="desc"?arrowUpIcon:downIcon} alt="down"  />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Reste </p> <img style={{cursor : "pointer"}} src={sortModifier==="desc"?arrowUpIcon:downIcon} alt="down"  />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Etat </p> <img style={{cursor : "pointer"}} src={sortModifier==="desc"?arrowUpIcon:downIcon} alt="down"  />
                    </div>
                  </th>
                  <th>
                    <div className="th3"></div>
                  </th>
                </tr>
              </thead>
              {ventes?.length ? (
                <tbody>
                  {filterActive || filtertype !== 0 
                    ? filtred
                        .filter((vente) => !vente.hidden)
                        .slice((active - 1) * 11, active * 11)
                        .map((vente, index) => {
                          let newId = vente.id.toString();
                          while (newId.length < 6) newId = "0" + newId;
                          return (
                            <tr
                              key={index}
                              style={{ borderTop: "1px solid #EAECF0 " }}
                            >
                              <td>
                                <div className="td2 lite">
                                  <p>{newId} </p>{" "}
                                </div>
                              </td>
                              <td>
                                <div className="td2">
                                  <p>
                                    {vente?.client
                                      ? vente.client.firstname +
                                        " " +
                                        vente.client.lastname
                                      : "Guest"}{" "}
                                  </p>{" "}
                                </div>
                              </td>
                              <td>
                                <div className={`td2 lite `}>
                                  <p>
                                    {vente?.createdAt
                                      ? `${
                                          new Date(vente?.createdAt)
                                            .toISOString()
                                            .split("T")[0]
                                        }`
                                      : "aucune date"}{" "}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="td2 lite">
                                  <p>
                                    {vente?.prix
                                      ? vente.prix.toLocaleString("en-DE")
                                      : "0"}{" "}
                                    DA
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className={`td2 lite `}>
                                  <p>
                                    {vente
                                      ? (
                                          vente?.prix - vente?.payed
                                        ).toLocaleString("en-DE")
                                      : "0"}{" "}
                                    DA
                                  </p>{" "}
                                </div>
                              </td>
                              <td>
                                <div className="td2 lite">
                                  {vente.prix === vente.payed ? (
                                    <div className="tag-green">
                                      <span></span>
                                      <p className="tag-p">Payé</p>
                                    </div>
                                  ) : (
                                    <div className="tag-gray">
                                      <span></span>
                                      <p className="tag-p">Non-payé</p>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div
                                  className="td3 clicked"
                                  onClick={() => {
                                    handelOptions(vente.id);
                                  }}
                                >
                                  <img
                                    className="clicked"
                                    src={options}
                                    alt="options"
                                  />
                                  <div
                                    className="slides"
                                    style={
                                      activeOption === vente.id
                                        ? {
                                            animation:
                                              "slideDown 0.5s ease-out",
                                          }
                                        : { display: "none" }
                                    }
                                  >
                                    <div
                                      onClick={() => {
                                        if (
                                          parseInt(vente.payed) >=
                                          parseInt(vente.prix)
                                        ) {
                                          notify(
                                            "success",
                                            "Transaction dejà payée"
                                          );
                                          return;
                                        }
                                        setActiveTransaction(vente);
                                        handelOverlay();
                                      }}
                                      className="add-payement"
                                    >
                                      <p>Ajouter un payment</p>
                                      <img src={plusIcon} alt="profile" />
                                    </div>
                                    <div
                                      onClick={handlePrint}
                                      className="print-receipt"
                                    >
                                      <p>Imprimer Le Ticket</p>
                                      <img src={printIcon} alt="profile" />
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                    : ventes
                        .slice((active - 1) * 11, active * 11)
                        .map((vente, index) => {
                          let newId = vente.id.toString();
                          while (newId.length < 6) newId = "0" + newId;
                          return (
                            <tr
                              key={index}
                              style={{ borderTop: "1px solid #EAECF0 " }}
                            >
                              <td>
                                <div className="td2 lite">
                                  <p>{newId} </p>{" "}
                                </div>
                              </td>
                              <td>
                                <div className="td2">
                                  <p>
                                    {vente?.client
                                      ? vente.client.firstname +
                                        " " +
                                        vente.client.lastname
                                      : "Guest"}{" "}
                                  </p>{" "}
                                </div>
                              </td>
                              <td>
                                <div className={`td2 lite `}>
                                  <p>
                                    {vente?.createdAt
                                      ? `${
                                          new Date(vente?.createdAt)
                                            .toISOString()
                                            .split("T")[0]
                                        }`
                                      : "aucune date"}{" "}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="td2 lite">
                                  <p>
                                    {vente?.prix
                                      ? vente.prix.toLocaleString("en-DE")
                                      : "0"}{" "}
                                    DA
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className={`td2 lite `}>
                                  <p>
                                    {vente
                                      ? (
                                          vente?.prix - vente?.payed
                                        ).toLocaleString("en-DE")
                                      : "0"}{" "}
                                    DA
                                  </p>{" "}
                                </div>
                              </td>
                              <td>
                                <div className="td2 lite">
                                  {vente.prix === vente.payed ? (
                                    <div className="tag-green">
                                      <span></span>
                                      <p className="tag-p">Payé</p>
                                    </div>
                                  ) : (
                                    <div className="tag-gray">
                                      <span></span>
                                      <p className="tag-p">Non-payé</p>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div
                                  className="td3 clicked"
                                  onClick={() => {
                                    handelOptions(vente.id);
                                   setActiveTransaction(vente)
                                  }}
                                >
                                  <img
                                    className="clicked"
                                    src={options}
                                    alt="options"
                                  />
                                
                                  <div
                                  
                                    className="slides clicked"
                                    style={
                                      activeOption === vente.id
                                        ? {
                                            animation:
                                              "slideDown 0.5s ease-out",
                                          }
                                        : { display: "none" }
                                    }
                                  >
                                    <div
                                      onClick={() => {
                                        if (
                                          parseInt(vente.payed) >=
                                          parseInt(vente.prix)
                                        ) {
                                          notify(
                                            "success",
                                            "Transaction dejà payée"
                                          );
                                          return;
                                        }
                                        handelOverlay();
                                      }}
                                      className="add-payement clicked"
                                    >
                                      <p className="clicked">Ajouter un payment</p>
                                      <img src={plusIcon} alt="profile"  className="clicked"/>
                                    </div>
                                    <div
                                      onClick={handlePrint}
                                      className="print-receipt clicked"
                                    >
                                      <p className="clicked">Imprimer Le Ticket</p>
                                      <img src={printIcon} alt="profile" className="clicked"/>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                  {/* <tr style={{ borderTop: "1px solid #EAECF0 " }}>
                                    <td className="td1" style={{ width: "1rem" }}><div><input type="checkbox" /></div></td>
                                    <td><div className="td2"><p>Nom et Prénom </p> </div></td>
                                    <td><div className="td2 lite"><p>12/05/2023 </p> </div></td>
                                    <td><div className="td2 lite"><p>1000,00 DA </p> </div></td>
                                    <td><div className="td3" onClick={handelOptions}><img src={options} alt="options" /><div className="openProfile" style={activeOption ? { animation: "slideDown 0.5s ease-out" } : { display: "none" }} ><p>Oper Profile</p><img src={linkIcon} alt="profile" /></div></div></td>
                                </tr> */}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={8}>
                      <div className="no-fr">
                        <p>
                          Aucun produit n'est retrouver ! veuillez Ajouter Un!
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          <div
            className="p-n"
            style={{ width: "100%", margin: "auto auto 1rem auto" }}
          >
            <div className="pagination">
              <div
                className="nav"
                onClick={() => {
                  handelnavigation("return");
                }}
                style={
                  active === 1 || pages.length === 0
                    ? {
                        backgroundColor: "#F5F5F5",
                        opacity: ".6",
                        cursor: "default",
                      }
                    : {}
                }
              >
                <img src={rtrn} alt="return" />
              </div>
              {active >= 3 && pages.length > 3 ? (
                <div className="ect">...</div>
              ) : (
                ""
              )}
              {pages.length > 3
                ? active < 3 && active > 0
                  ? pages?.slice(0, 3).map((page, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            handelnavigation("page", page);
                          }}
                          className="page"
                          style={
                            active === page
                              ? { backgroundColor: "#100DB1" }
                              : {}
                          }
                        >
                          {" "}
                          <p style={page === active ? { color: "white" } : {}}>
                            {page}
                          </p>
                        </div>
                      );
                    })
                  : active > pages.length - 3
                  ? pages
                      ?.slice(pages.length - 3, pages.length)
                      .map((page, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              handelnavigation("page", page);
                            }}
                            className="page"
                            style={
                              active === page
                                ? { backgroundColor: "#100DB1" }
                                : {}
                            }
                          >
                            {" "}
                            <p
                              style={page === active ? { color: "white" } : {}}
                            >
                              {page}
                            </p>
                          </div>
                        );
                      })
                  : pages?.slice(active - 2, active + 2).map((page, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            handelnavigation("page", page);
                          }}
                          className="page"
                          style={
                            active === page
                              ? { backgroundColor: "#100DB1" }
                              : {}
                          }
                        >
                          {" "}
                          <p style={page === active ? { color: "white" } : {}}>
                            {page}
                          </p>
                        </div>
                      );
                    })
                : pages?.map((page, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          handelnavigation("page", page);
                        }}
                        className="page"
                        style={
                          active === page ? { backgroundColor: "#100DB1" } : {}
                        }
                      >
                        {" "}
                        <p style={page === active ? { color: "white" } : {}}>
                          {page}
                        </p>
                      </div>
                    );
                  })}

              {active < pages.length - 2 && pages.length > 3 ? (
                <div className="ect">...</div>
              ) : (
                ""
              )}
              <div
                className="frwrd"
                onClick={() => {
                  handelnavigation("frwrd");
                }}
                style={
                  active === pages.at(-1)
                    ? {
                        backgroundColor: " #F5F5F5",
                        opacity: ".6",
                        cursor: "default",
                      }
                    : {}
                }
              >
                <div
                  className="nav"
                  style={
                    active === pages.at(-1) || pages.length === 0
                      ? {
                          backgroundColor: " #F5F5F5",
                          opacity: ".6",
                          cursor: "default",
                        }
                      : {}
                  }
                >
                  <img src={frwrd} alt="next" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
