import React, { FunctionComponent, useRef, useEffect, useState } from "react";
import "../../css/AddClient.css";
import { AddClientapi,notify } from '../../api/api';
import AddAnimal from "./addAnimal";

interface AddClientProps {
  onCloseModal: () => void;
}

const AddClient: FunctionComponent<AddClientProps> = ({ onCloseModal }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [createdclient,setcreatedclient]=useState<number>()
  const [modalan,setmodalan]=useState(false)
  const handleCloseModalan = () =>{
    setmodalan(false)
  }
  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (
      modalRef.current &&
      !modalRef.current.contains(target) &&
      !target.closest(".addclientt") &&
      !target.closest(".inputt-1") &&
      !target.closest(".inputt-2") &&
      !target.closest(".inputt-3")
    ) {
      onCloseModal();
    }
  };

  const validatePhone = (value: string) => {
    const phoneRegex = /^(0[5-7])\d{8}$/;
    const isValid = phoneRegex.test(value);
    if(!isValid){
    notify('error', 'Le numéro de téléphone doit commencer par 05, 06 ou 07 et être suivi de 8 chiffres.');}
    return isValid;
  };

  const handleAddClient = async () => {
    try {
      if (!firstName || !lastName || !validatePhone(phone)) {
        notify('')
        throw new Error('Veuillez remplir tous les champs correctement.');
      }
      const response = await AddClientapi(firstName, lastName, phone);
      const clientId=response?.client?.id
      setcreatedclient(clientId)
      setmodalan(true)
    } catch (error: any) {
      console.error('Erreur lors de l\'ajout du client:', error.message);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCloseModal]);

  return (
    <div className="addclientt" ref={modalRef}>
      <button className="buttonn" onClick={handleAddClient}>
        <div className="backgroundd" />
        <div className="suivantt">Suivant</div>
      </button>
      <input className="inputt-2" placeholder="Prénom" type="text" onChange={(e) => setFirstName(e.target.value)} />
      <input className="inputt-3" placeholder="Téléphone" type="text" onChange={(e) => setPhone(e.target.value)} />
      <input className="inputt-1" placeholder="Nom" type="text" onChange={(e) => setLastName(e.target.value)} />
      <div className="headd">
        <div className="dotss">
          <div className="dotss-child" />
          <div className="dotss-item" />
        </div>
        <div className="ajouter-clientt">Ajouter Client</div>
      </div>
      {modalan && createdclient && (
  <AddAnimal selectedClientId={createdclient} onCloseModal={handleCloseModalan} />
)}
    </div>
  );
};

export default AddClient;
