import React, { FunctionComponent, useState, useEffect } from 'react';
import Clientstable from './Clientstable';
import AnimauxTable from './AnimauxTable';
import SearchArea from './SearchArea';
import '../../css/Tables.css';
import { DeleteClient,DeleteAnimal} from '../../api/api';
import ButtonsBar from "../../components/Clients/Buttonsbar";
interface Props {
  clients: any[];
}

export interface Data {
  id: number;
  client: string;
  phone: string;
  credit: number;
  derniereTransaction: string;
  animals: {id:number, name: string; race: string }[];
}

const Tables: FunctionComponent<Props> = ({ clients }) => {
  const [selectedClient, setSelectedClient] = useState<Data | null>(null);
  const [selectedClientAnimaux, setSelectedClientAnimaux] = useState<{id:number, name: string; race: string }[]>([]);
  const [filteredData, setFilteredData] = useState<Data[]>(clients.map(client => ({
    ...client,
    client: `${client.firstname}-${client.lastname}`,
  })));

  const handleClientSelection = (client: Data, animals: { id: number, name: string; race: string }[]) => {
    setSelectedClient(client);
    setSelectedClientAnimaux(animals);
  };
  const handleDeleteClient = async (clientId: number) => {
       const reponse=await DeleteClient(clientId);
  };
  const handleDeleteAnimal = async (animalIndex: number) => {
    try {
      const animalToDelete = selectedClientAnimaux[animalIndex];
      if (animalToDelete) {
        await DeleteAnimal(animalToDelete.id);
        const updatedAnimaux = [...selectedClientAnimaux];
        updatedAnimaux.splice(animalIndex, 1);
        setSelectedClientAnimaux(updatedAnimaux);
      }
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'animal depuis l\'API:', error);
    }
  };

  const handleSearch = (searchText: string) => {
    const lowerCaseSearch = searchText.toLowerCase();
    if (lowerCaseSearch.trim() === "") {
      setFilteredData(clients.map(client => ({
        ...client,
        client: `${client.firstname}-${client.lastname}`,
      })));
    } else {
      const filtered = clients.filter((client) =>
        `${client.firstname}-${client.lastname}`.toLowerCase().includes(lowerCaseSearch)
      ).map(client => ({
        ...client,
        client: `${client.firstname}-${client.lastname}`,
      }));
      setFilteredData(filtered);
    }
  };

  const handleAnnuler = () => {
    setFilteredData(clients.map(client => ({
      ...client,
      client: `${client.firstname}-${client.lastname}`,
    })));
  };
  useEffect(() => {
    setFilteredData(clients.map(client => ({
      ...client,
      client: `${client.firstname}-${client.lastname}`,
    })));
  }, [clients]);

  return (
    <div className="bodyclient">
      <ButtonsBar selectedClient={selectedClient}/>
    <div className="tables">
      <SearchArea onSearch={handleSearch} onAnnuler={handleAnnuler} />
      <Clientstable data={filteredData} onClientSelect={handleClientSelection} onDeleteClient={handleDeleteClient} />
      <AnimauxTable selectedClientId={selectedClient ? selectedClient.id : null} animals={selectedClientAnimaux} onDeleteAnimal={handleDeleteAnimal} />

    </div>
    </div>
  );
};

export default Tables;
