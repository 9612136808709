import { logo } from "../../assets/icons";
import "./receit.css"


import React, { useState } from 'react';

export const Reciept = React.forwardRef((props, ref) => {
  let {vente}= props
  

 return (
  <div className="receipt" ref={ref}>
    <div className="header">
      <div>
        <img src={logo} alt="Logo" />
        <h2>FARTAS VET</h2>
      </div>
      <div>
        <span>{vente.client?.firstname?vente.client.firstname +" "+vente.client.lastname:"Guest"}</span>
        <span>Receipt {vente.id}</span>
      </div>
    </div>
    <p>-------------------------------------------</p>
    <ul className="product-list">
      <li>
        <span>P/S</span>
        <span>Quantity</span>
        <span>Price</span>
      </li>
      {vente?.produits.map((product) => (

        <li key={product.id} >
          <span>{product.produit.name}</span>
          <span className="li">{product.qt}</span>
          <span>{product.produit.prix*product.qt}</span>
        </li>
      ))}
      {vente?.services.map((service) => (

<li key={service.id} >
  <span>{service.service.name}</span>
  <span className="li">{service.qt}</span>
  <span>{service.service.prix*service.qt}</span>
</li>
))}
    </ul>
    <p>-------------------------------------------</p>

    <div className="total-price">
      <span>Total Price:</span>
      <span>{vente.prix.toLocaleString("en-DE")} DZD</span>
    </div>
    <div className="total-price">
      <span>Total Versment:</span>
      <span>{parseInt(vente.payed).toLocaleString("en-DE") } DZD</span>
    </div>
    <p>-------------------------------------------</p>

    <div className="footer">
      Please check the receipt before you leave.
    </div>
  </div>
);
});
