import React from 'react';
import "../../css/Produitsection.css";
import { FunctionComponent, useEffect,useState } from "react";
import Tableproduits from '../../components/Produit/Tableproduits';
import SearchArea from '../../components/Clients/SearchArea';
import Tablesrvices from '../../components/Produit/Tableservices';

const Produit : FunctionComponent = () => {
  return (
    <div className='prod' >
    <div className="title2">
        <img className="logo" alt="" src="/logo-m-1.svg" />
        <b className="prodtext">Produits/Services</b>
      </div>
      <div className='tables-position'>
        <div >
      <Tableproduits/>
      </div>
      <div >
      <Tablesrvices/>
      </div>
      </div>
    </div>
  );
};

export default Produit ;