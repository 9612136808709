import { persistReducer, persistStore } from "redux-persist";
import currentReducer from "./current";
import { createStore, combineReducers } from "redux";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import authReducer from "./auth";
import caisseReducer from "./caisse";
const persistConfig = {
  key: "root",
  storage,
};
const rootReducer = combineReducers({
  authReducer,
  caisseReducer,
  currentReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);
const persistor = persistStore(store);
export default store;
export { persistor };
