
import React, { FunctionComponent, useState, useEffect } from 'react';
import { format } from 'date-fns-tz';
import '../../css/tablerendezvous.css';
import { useVetoContext } from '../../api/Context';
import Buttons1 from '../Clients/Buttons1';
import {CreateRendezVous }from '../../api/api';
export interface Data {
  id: number;
  name: string;
  race: string;
  age: any;
  startDate:any;
  endDate:any;
  robe: string;
  client: any;
  servicesInformations: any;
  clientId: string;
}

const TableRendezVous: React.FunctionComponent = () => {
  const { animals } = useVetoContext();
  const [selectedAnimal, setSelectedAnimal] = useState<Data | null>(null);
  const [title, settitle] = useState('');
  const [disc, Setdisc] = useState('');
  const [startDate, setstartDate]= useState('');
  const [endDate, setendDate] = useState('');
  const handleAnimalChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedAnimalId = e.target.value;
    const animal = animals.find((a: Data) => a.id.toString() === selectedAnimalId);
    setSelectedAnimal(animal || null);
  };
  const handleAjouterRdvClick = () => {
    if (selectedAnimal) {
      const startDateTime =  startDate?new Date(startDate):null;
      const endDateTime = endDate?new Date(endDate):null;
      const algeriaTimeZone = 'Africa/Algiers';
      const formattedStartDate = startDateTime?format(startDateTime, "yyyy-MM-dd'T'HH:mm", { timeZone: algeriaTimeZone }):null;
      const formattedEndDate = endDateTime?format(endDateTime, "yyyy-MM-dd'T'HH:mm", { timeZone: algeriaTimeZone }):null;
      const rendezVousDetails = {
        animalId: selectedAnimal.id,
        title,
        disc,
        endDate: formattedEndDate,
        clientId: selectedAnimal.clientId,
        startDate: formattedStartDate,
      };
      console.log(rendezVousDetails);
      CreateRendezVous(rendezVousDetails)
        .then((response) => {
          console.log('Rendez-vous créé avec succès!', response);
        })
        .catch((error) => {
          console.error('Erreur lors de la création du rendez-vous', error);
        });
    }
  };

    return (
      <div className='rdv-body'>
        <div className='table-rdv'>
          <b>Ajouter un rendez-vous</b>
        </div>
        <div className='select-rdv'>
          <div>
            <select
              className='selectt'
              value={selectedAnimal ? selectedAnimal.id.toString() : ''}
              onChange={handleAnimalChange}
            >
              <option value="" disabled>choisir un animal</option>
              {animals&&animals.map((animal: Data) => (
                <option
                  key={animal.id}
                  style={{ backgroundColor: 'pink', color: 'black' }}
                  value={animal.id.toString()}
                >
                  {animal.name}
                </option>
              ))}
            </select>
            <legend className='client-select'>Entrez le nom de l’animal</legend>
          </div>
          <div>
            <input
              className='inputss'
              key={selectedAnimal?.client.id}
              type='text'
              value={selectedAnimal ? selectedAnimal.client.firstname+' '+selectedAnimal.client.lastname : ''}
              readOnly
            />
            <legend className='animal-select'>Le nom du client</legend>
          </div>
        </div>
   <div className='inputs-rdv'>
   <div>
        <input 
        value={title}
        onChange={(e) => settitle(e.target.value)}
        className='inputss' type='text' placeholder='Titre*'
        required></input>
        <legend className='titre-input'>Entrez le titre du rendez-vous</legend>
        </div>
     <div>
        <input
        value={disc}
        onChange={(e) => Setdisc(e.target.value)}
        className='inputss' type='text' placeholder='Description*' required></input>
        <legend className='description-input'>Entrez la description du rendez-vous</legend>
       </div>
       
       </div>
       <div className='date-inputs'>
       <div >
        <input 
        value={startDate}
        onChange={(e) =>setstartDate(e.target.value) }
        className='inputss-date'  type="datetime-local" required/>
        <legend className='debut-rdv'>Entrez la date et l’heure de debut du rendez-vous</legend>
       </div>
       <div >
        <input 
        value={endDate}
        onChange={(e) => setendDate(e.target.value)}
        className='inputss-date'  type="datetime-local" required/>
        <legend className='fin-rdv'>Entrez la date et l’heure de fin du rendez-vous</legend>
       </div>
       </div>  
       <div>
        <Buttons1
                  icon="/Images/Clients/adduser.png"
                  text="Ajouter Rdv"
                  onClick={handleAjouterRdvClick}
                  background="background3"
        ></Buttons1>
        </div>   
    </div>
    );
};
export default TableRendezVous;