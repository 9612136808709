import "./fournisseurForm.css";
import { instance,notify } from "../../api/api";
import React, { useEffect, useState } from "react";
import { errIcon, successIcon } from "../../assets/icons";
import toast, { Toaster } from "react-hot-toast";

import Cookies from "js-cookie";
export default function FournisseurForm() {
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState([]);
  const inputHandler = (event) => {
    event.preventDefault();
    setInputs((state) => {
      let news = state;
      news[event.target.name] = event.target.value;
      return news;
    });
  };
  const submitHandler = async (event) => {
    event.preventDefault();
    setErrors([]);
    let url = "./fournisseurs";
    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      pm_auth: `${Cookies.get("auth_token")}`,
      checkfor: "fournisseurs",
    };
    let data = inputs;
    if (!inputs?.firstname) {
      setErrors((state) => {
        let news = [...state];
        news.push({ field: "firstname", message: "Required" });
        return news;
      });
    }
    if (!inputs?.lastname) {
      setErrors((state) => [
        ...state,
        { field: "lastname", message: "Required" },
      ]);
    }

    if (!inputs?.RC) {
      setErrors((state) => {
        let news = [...state];
        news.push({ field: "RC", message: "Required" });
        return news;
      });
    }
    if (!inputs?.NIF) {
      setErrors((state) => {
        let news = [...state];
        news.push({ field: "NIF", message: "Required" });
        return news;
      });
    }
    if (!inputs?.NIS) {
      setErrors((state) => {
        let news = [...state];
        news.push({ field: "NIS", message: "Required" });
        return news;
      });
    }
    if (inputs?.phone?.length !== 12 && inputs?.phone?.length !== 10) {
      setErrors((state) => {
        let news = state;
        news.push({
          field: "phone",
          message:
            "Numéro invalide, le numéro de téléphone doit comporter 10 caractères ou 12 !",
        });
        return news;
      });
      return
    }
    if(!inputs?.firstname||!inputs?.RC||!inputs?.NIF||!inputs?.NIS )   {
        return } 
 try{
        const res=await instance
      .post(url, { data: data }, { headers })
    
        if (res?.data?.fournisseur) {
          notify("success");
          setTimeout(() => {
            window.location.href = "/fournisseurs";
          }, 1000);
        }
        if (res?.data?.message) {
          notify("error", res.data.message);
        }

        return;
      }
      catch(err)  {
        notify("error", err.message);
      };
    return;
  };

  return (
    <div className="fournisseur_f">
      <Toaster
        toastOptions={{
          style: {
            background: "transparent",
            border: "none",
            boxShadow: "none",
            color: "#ffffff",
          },
        }}
      />
      <div className="header">
        <h2>Fournisseurs</h2>
        <p>Ajouter un fournisseur</p>
      </div>
      <div className="form">
        <div className="p1">
          <label>
            {errors.filter((err) => err.field == "firstname")?.length ? (
              <p className="err-field-msg">{`${errors.filter((err) => err.field == "firstname")[0].message}*`}</p>
            ) : (
              ""
            )}
            <input
              type="text"
              name="firstname"
              placeholder="Nom*"
              onChange={inputHandler}
            />
            Entrez le nom de fournisseurs
          </label>
          <label>
            {errors.filter((err) => err.field === "lastname")?.length ? (
              <p className="err-field-msg">{`${errors.filter(
                (err) => err.field === "lastname"
              )[0].message}*`}</p>
            ) : (
              ""
            )}
            <input
              type="text"
              name="lastname"
              placeholder="Prénom*"
              onChange={inputHandler}
            />
            Entrez le prénom du fournisseur
          </label>
          <label>
          {errors.filter((err) => err.field == "phone")?.length ? (
              <p className="err-field-msg">{`${errors.filter((err) => err.field == "phone")[0].message}*`}</p>
            ) : (
              ""
            )}
            <input
              type="number"
              name="phone"
              placeholder="Téléphone"
              onChange={inputHandler}
            />
            Entrez le numero telephone
          </label>
          <label>
          {errors.filter((err) => err.field == "RC")?.length ? (
              <p className="err-field-msg">{`${errors.filter((err) => err.field == "RC")[0].message}*`}</p>
            ) : (
              ""
            )}

            <input
              type="text"
              name="RC"
              placeholder="RC*"
              onChange={inputHandler}
            />
            Entrez le numero Registre Commerce
          </label>
          <label>
          {errors.filter((err) => err.field == "NIF")?.length ? (
              <p className="err-field-msg">{`${errors.filter((err) => err.field == "NIF")[0].message}*`}</p>
            ) : (
              ""
            )}

            <input
              type="text"
              name="NIF"
              placeholder="NIF"
              onChange={inputHandler}
            />
            Entrez le numero d'identification fiscale
          </label>
          <label>
          {errors.filter((err) => err.field == "NIS")?.length ? (
              <p className="err-field-msg">{`${errors.filter((err) => err.field == "NIS")[0].message}*`}</p>
            ) : (
              ""
            )}
            <input
              type="text"
              name="NIS"
              placeholder="NIS"
              onChange={inputHandler}
            />
            Entrez le numero d'identification statistique
          </label>
        </div>

        <div className="btn" onClick={submitHandler}>
          <p>
            <span id="plus">+</span> Ajouter
          </p>
        </div>
      </div>
    </div>
  );
}
