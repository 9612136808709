const initialCurrentState = {
    current: { clientId: "none"},
  };
  
  function currentReducer(state = initialCurrentState, action) {
    if (action.type === "set") {
      if (action?.payload) {
        let current = action.payload;
        return {
          current: { clientId: current.client },
        };
      }
    }
  
    return state;
  }
  
  export default currentReducer;
  