import React from 'react';

import { FunctionComponent, useEffect,useState } from "react";
import "../../css/Clientsection.css";
import Tables from "../../components/Clients/Tables";
import { GetAllClient, GetClientCredit} from "../../api/api";
const Clients: FunctionComponent = () => {
  const [clients, setClients] = useState<any[]>([]);
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll("[data-animate-on-scroll]");

    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsResponse = await GetAllClient();
        const clientsWithCredits = await Promise.all(
          clientsResponse.clients.map(async (client: any) => {
            const creditResponse = await GetClientCredit(client.id);
            return { ...client, credit: creditResponse.totalUnpaidCredits };
          })
        );
        setClients(clientsWithCredits);
      } catch (error) {
        console.error("Error fetching clients:", error);
      } 
    };

    fetchClients();
  }, []);

  console.log(clients);
  return (
    <div className="cl" data-animate-on-scroll>
      <div className="title1">
        <img className="logo" alt="" src="/logo-m-1.svg" />
        <b className="cltext">Clients</b>
      </div>
      <Tables clients={clients} />
    </div>
  );
};

export default Clients;
