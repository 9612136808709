import "../../css/ButtonAjouter.css";
import React from "react";
import { FunctionComponent, useState } from "react";
interface ButtonAjouterprops {
  onajouter: () => void;
}
const ButtonAjouter: FunctionComponent<ButtonAjouterprops> = ({
  onajouter,
}) => {
  return (
    <div>
      <button
        className="ajouterproduit"
        id="Ajouterproduit"
        onClick={onajouter}
      >
        <div className="background" />
        <div className="ajouter"> Ajouter </div>
        <img className="add-icon" alt="" src="/Images/Clients/add.png" />
      </button>
    </div>
  );
};
export default ButtonAjouter;
