import React, { FunctionComponent, useState, useEffect } from 'react';
import SearchArea from '../Clients/SearchArea';
import "../../css/tableservice.css";
import ButtonAjouter from '../Clients/ButtonAjouer';
import Boxs from './Box';
import ServiceTable from './ServiceTable';
import AddService from "../Produit/AddService";
import { useNavigate } from 'react-router-dom';
import { useVetoContext } from '../../api/Context';
const Tablesrvices  = () => {
  const {services}=useVetoContext();
  const navigator=useNavigate();
  console.log(services)
  const [filtredservices, setfiltredservices] = useState(
    services&&services.services? services.services:null
    );
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleAjouterClick = () => {
   navigator("/addservice")
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleSearch = (text: string) => {
    setSearchText(text);
  }
  const handleAnnuler = () => {
    setSearchText("");
  }
  useEffect(() => {
    if(services &&services.services){
    const filtered = services.services
      .map((service: { name: string }) => ({ ...service, name: service.name.toLowerCase() })) 
      .filter((product: { name: string }) => product.name.includes(searchText.toLowerCase()));
      setfiltredservices(filtered);
    }
  }, [searchText, services.services]);
    return(
        <div className="bodyservice">
      <div className='titresserv'>
      <h1 className='titre-produit'>Services </h1>
      {/* <h1 className=' titre-produit1'>0</h1> */}
      </div>
    <div className="tableprod">
    <ButtonAjouter onajouter={handleAjouterClick} />
          {isModalOpen && <AddService onCloseModal={handleCloseModal} />}
   <div className='boxs-serv'>
   <div className='box-services'>
   <Boxs titlebox='Services' quantitie={services.services ? services.services.length : 0} dzd='' />

   </div>
   <div className='box-prodserv'>
   <Boxs titlebox='Produits' quantitie={services.totalProducts} dzd=''/>
   </div>
   </div>
   <div className='secondpart'>
   <b className='tableprodd'>Services</b>
   <h5>Liste Des Services</h5>
   <div className='search-barserv'>
    <SearchArea onSearch={handleSearch} onAnnuler={handleAnnuler}/>
    </div>
   <ServiceTable services={filtredservices}/>
    </div>
    </div>
    </div>
    )
}
export default Tablesrvices;