import React from "react";

import {
  Routes,
  Route,
  BrowserRouter,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import Home from "./pages/Home/Home";
import { useEffect } from "react";
import Clients from "./pages/Clients/Clientsection";
import RequireAuth from "./requiredauth/requiredAuth";
import Produit from "./pages/Produit/produit";
import Ventes from "./pages/Ventes/Ventes";
import Caisse from "./pages/Caisse/Caisse";
import TransactionList from "./pages/TransactionsList/TransactionsList";
import Rendezvous from "./pages/Rendez-vous/Rendezvous";
import Hospitalisation from "./pages/Hospitalisation/Hospitalisation";
import Achats from "./pages/Achats/Achats";
import NotFound from './pages/NotFound/NotFound';
import Cookies from 'js-cookie';
import Updateanimal from "./pages/UpdateAnimalstuff/Updateanimal";
import PointDeVente from "./pages/PointDeVente/PointDeVente";
import Utilisateurs from "./pages/Utilisateurs/Utilisateurs";
import CaisseGenerale from "./pages/CaisseGénérale/CaisseGénérale";
import AddProduct from "./components/addProduct/AddProduct";
import UserProfile from "./pages/UserProfile/UserProfile";
import CaissesList from "./pages/CaissesList/CaissesList";
import Pos from "./pages/Pos/Pos";
import { notify,instance } from "./api/api";
import { useDispatch, useSelector } from 'react-redux'
import FournisseursList from "./pages/FournisseursList/FournisseursList";
import FournisseurProfile from "./pages/FournisseurProfile/FournisseurProfile";
import AddFournisseur from "./pages/AddFournisseur/AddFournisseur";
import AddService from "./pages/AddService/AddService";
import VentesList from "./pages/VentesList/VentesList";
// import jwt_decode from "jwt-decode";
const parseJwt = (token:any) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64));
  return JSON.parse(jsonPayload);
};
 function App() {
  const cs_r = useSelector((state:any)=>(state.caisseReducer.caisse))
  const auth = useSelector((state:any) =>(state.authReducer.auth)) 
  console.log(cs_r)
  const dispatch = useDispatch();
  const handelCloture = async () => {
    try {

      let user = auth?.user;

      if (user?.id !== cs_r?.userId) {
        notify("error", "caisse opened by another user!");
        return;
      }
      if (!cs_r?.open) {
        notify("error", "caisse deja cloturée!");
        return;
      }
      let url = `/caisse/${cs_r?.id}`;
      let data = { open: false };
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "fournisseurs",
      };
   const res=await instance.put(url, { data }, { headers })
          if (res?.data?.updated) {
            notify("success", "Caisse Cloturée");
            dispatch({ type: "setCaisse", payload: {} });
            return;
          }
          notify("err", res.data.message);
    } catch (error:any) {
      notify("err", error.message);
    }
  };
  useEffect(() => {
    const checkToken = async () => {
      let token = Cookies.get('auth_token');
      if (token) {
        const decodedToken = parseJwt(token);
        console.log(decodedToken);
        const isTokenExpired = decodedToken.exp < Date.now() / 1000;
        if (isTokenExpired) {
          await handelCloture();
          console.log("expired");
          Cookies.remove("auth_token");
          sessionStorage.removeItem('isLoggedIn');
          return (<LoginPage/>)
        }
      }
      if (!sessionStorage.getItem("isLoggedIn")) {
        return (<LoginPage/>)
      }
    };

    checkToken();
  }, []); 
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
  <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path='/Home'  element={ <RequireAuth  checkFor={"Home"}>
      <Home />
    </RequireAuth>}/>
    <Route path='/Clients'  element={ <RequireAuth  checkFor={"Clients"}>
      <Clients />
    </RequireAuth>}/>
    <Route path='/addproduct'  element={ <RequireAuth  checkFor={"Produit"}>
      <AddProduct />
    </RequireAuth>}/>
    <Route path='/addservice'  element={ <RequireAuth  checkFor={"Produit"}>
      <AddService />
    </RequireAuth>}/>
    <Route path='/Animal/:selectedClientId/:id'  element={ <RequireAuth  checkFor={"Animal"}>
      <Updateanimal />
    </RequireAuth>}/>
    <Route path='/Produit'  element={ <RequireAuth  checkFor={"Produit"}>
      <Produit />
    </RequireAuth>}/>
    <Route path='/Ventes'  element={ <RequireAuth  checkFor={"Ventes"}>
      <VentesList />
    </RequireAuth>}/>
    <Route path='/caisse'  element={ <RequireAuth  checkFor={"caisse"}>
      <Caisse />
    </RequireAuth>}/>
    <Route path='/RendezVous'  element={ <RequireAuth  checkFor={"RendezVous"}>
      <Rendezvous />
    </RequireAuth>}/>
    <Route path='/Hospitalisation'  element={ <RequireAuth  checkFor={"Hospitalisation"}>
      <Hospitalisation />
    </RequireAuth>}/>
    <Route path='/Achats'  element={ <RequireAuth  checkFor={"Achats"}>
      <TransactionList />
    </RequireAuth>}/>
    <Route path='/Fournisseurs'  element={ <RequireAuth  checkFor={"Achats"}>
      <FournisseursList/>
    </RequireAuth>}/>
    <Route path='/AddFournisseur'  element={ <RequireAuth  checkFor={"Achats"}>
      <AddFournisseur />
    </RequireAuth>}/>
    <Route path='/Fournisseur/:id'  element={ <RequireAuth  checkFor={"Achats"}>
      <FournisseurProfile />
    </RequireAuth>}/>
    <Route path='/Pointdevente'  element={ <RequireAuth  checkFor={"Pointdevente"}>
      <Pos />
    </RequireAuth>}/>
    <Route path='/utilisateurs'  element={ <RequireAuth  checkFor={"utilisateurs"}>
      <Utilisateurs />
    </RequireAuth>}/>
    <Route path='/utilisateurs/:id'  element={ <RequireAuth  checkFor={"utilisateurs"}>
      <UserProfile />
    </RequireAuth>}/>
    <Route path='/CaisseGenerale'  element={ <RequireAuth  checkFor={"CaisseGenerale"}>
      <CaissesList />
    </RequireAuth>}/>
    <Route path='*' element={<NotFound/>}/>
      </Routes>
  );
}
export default App;
