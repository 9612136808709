import React from 'react';
import { FunctionComponent } from 'react';
import '../../css/Buttons1.css';

interface Buttons1Props {
  icon: string; 
  text: string; 
  background:string;
  onClick: () => void;
}

const Buttons1: FunctionComponent<Buttons1Props> = ({ icon, text, onClick,background }) => {
  return (
      <button className="ajouterclient" id="AjouterClient" onClick={onClick}>
        <div className={background} />
        <div className="ajouter-client">{text}</div>
        <img className="add-user-icon" alt="" src={icon} />
      </button>
  );
};

export default Buttons1;
