import React from 'react';
import "../../css/Caissesection.css";
import { FunctionComponent,useRef, useEffect,useState } from "react";
import TableCaisse from '../../components/Caisse/TableCaisse';
import CaisseTable from '../../components/Caisse/CaisseTable';
import {getventes} from '../../api/api';
import { useNavigate } from 'react-router-dom';
const Caisse :FunctionComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigator= useNavigate();
  const handleTblecaisse = () => {
    setIsModalOpen(true);
  };
  const handlenavig = () => {
 
    navigator('/Home');
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [todaysventes, setTodaysventes] = useState<{ ventes: any; todaysgains: any; } | undefined>(undefined);
  console.log(todaysventes)
  useEffect(() => {
    const fetchventes = async () => {
      try {
        const ventee = await getventes();
        setTodaysventes(ventee);
      } catch (error) {
        console.error("Error fetching clients:", error);
        setTodaysventes({ ventes: null, todaysgains: null }); 
      } 
    };
  
    fetchventes();
  }, []);
  
  return (
    <div className='caisse' >
    <div className="title6">
        <img className="logo" alt="" src="/logo-m-1.svg" />
        <b className="caissetext">Caisse</b>
        <div className='google-alarm-discount'>
          <a href='/Rendezvous'>
            <button className='btn-caisse'>
        <img className="Google-Calendar" alt="" src="/Images/Clients/Google Calendar.png" /> 
        </button>
        </a>
        <button className='btn-caisse'>
        <img className="Alarm" alt="" src="/Images/Clients/Alarm.png" onClick={handlenavig}/> </button>
        <button className='btn-caisse' onClick={handleTblecaisse} >
          
            
        <img className="Get-Discount" alt="" src="/Images/Clients/Get a Discount.png" /> 
       
        </button>
        {isModalOpen && <CaisseTable onCloseModal={handleCloseModal} ventes={todaysventes} />}
        </div>
        <div className='caisse-tab'> 
        <TableCaisse ></TableCaisse>
        </div>
       
      </div>
      
    </div>
  );
};

export default Caisse ;