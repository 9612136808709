import "./productForm.css";

import React, { useEffect, useRef, useState } from "react";
import { deleteIcon, errIcon, printIcon1, printIcon2, successIcon } from "../../assets/icons";
import Cookies from "js-cookie";
import Select from "react-select";
import { downIcon, minusIcon, linkIcon,delete_itemIcon } from "../../assets/icons";
import { CodeBars } from "../CodeBars/CodeBars";
import { useReactToPrint } from "react-to-print";
import { notify,instance } from "../../api/api";
import toast, { Toaster } from "react-hot-toast";
export default function ProductForm() {
  const [inputs, setInputs] = useState({
    id:"",
    name: "",
    dateDePeremption: "",
    qt: 0,
    barCode: "",
    prix: 0,
    cout: 0,
    unite: "",
  });
  const [products, setProducts] = useState([]);
  const [errors, setErrors] = useState([]);
  const [fournisseur,setFournisseur] = useState("")
  const [total, setTotal] = useState(0)
  const [fournisseurs, setFournisseurs] = useState([])
  const [selected,setSelected] = useState("")
  const [options,setOptions] = useState([])
  const [payed,setPayed] = useState(0)
  const componentRef = useRef(null);
  const [toPrint,setToPrint] = useState("")
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const printHandler = async (product)=>{
    if(!products?.length){
      notify("err","products empty")
      return
    }
   

    handlePrint();
    setToPrint(null)


  }
  // refs
  const ref0 = useRef("");
  const ref1 = useRef("");
  const ref2 = useRef("");
  const ref3 = useRef("");
  const ref4 = useRef("");
  const ref5 = useRef("");
  const ref6 = useRef("");
  const ref7 = useRef("");

  // refs
useEffect(()=>{  if(products?.length && toPrint !== null){console.log(toPrint);printHandler()}},[toPrint])
// handelfilter
useEffect(()=>{

  const getFournisseurs = async () => {
    setErrors([]);
    let url = "./fournisseurs";
    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      pm_auth: `${Cookies.get("auth_token")}`,
      checkfor: "fournisseurs",
    };
  
    const res=await instance.get(url, { headers })
     try{
        if (res?.data?.fournisseurs) {
          let fr = res.data.fournisseurs.map((fournisseur)=>{return {...fournisseur,label:fournisseur.firstname}})
          setFournisseurs(fr)
        }
        if (res?.data?.message) {
          notify("error", res.data.message);
        }

        return;
      }catch(err) {
        notify("error", err.message);
      };
    return;
  };

getFournisseurs()


},[])




useEffect(()=>{
  setOptions(fournisseurs)
},[fournisseurs])





  useEffect((
   
  )=>{ if(products?.length) {
    let t = products.reduce((acc, product) => {
      acc += product?.qt * product?.cout;
      return acc
    }, 0)
    setTotal(t)
  }},[products])

  const inputHandler = (event) => {
    event.preventDefault();
    setInputs((state) => {
      let news = state;
      news[event.target.name] = event.target.value;
      console.log(news)
      return news;
    });
  };

  const addProductHandler = () => {
    setErrors([]);
    if (!inputs?.name) {
      setErrors((state) => {
        let news = [...state];
        news.push({ field: "name", message: "Required" });
        return news;
      });
    }
    if (!inputs?.id) {
      setErrors((state) => {
        let news = [...state];
        news.push({ field: "id", message: "Required" });
        return news;
      });
    }
    if (!inputs?.minQt) {
      setErrors((state) => {
        let news = [...state];
        news.push({ field: "minQt", message: "Required" });
        return news;
      });
    }
    if (!inputs?.dateDePeremption) {
      setErrors((state) => {
        let news = [...state];
        news.push({ field: "dateDePeremption", message: "Required" });
        return news;
      });
    }
    if (!inputs?.qt) {
      setErrors((state) => {
        let news = [...state];
        news.push({ field: "qt", message: "Required" });
        return news;
      });
      return;
    }

    setProducts((state) => [...state, inputs]);
    setInputs({
      name: "",
      dateDePeremption: "",
      qt: 0,
      barCode: "",
      prix: 0,
      cout: 0,
      unite: "",
    });
    if(ref0.current)     ref0.current.value = "";
    if(ref1.current)     ref1.current.value = "";
    if(ref2.current)     ref2.current.value = "";
    if(ref3.current)     ref3.current.value = "";
    if(ref4.current)     ref4.current.value = "";
    if(ref5.current)     ref5.current.value = "";
    if(ref6.current)     ref6.current.value = "";
    if(ref7.current)     ref6.current.value = "";





    // ref2.current.value = "";
    // ref3.current.value = "";
    // ref4.current.value = "";
    // ref5.current.value = "";
    // ref6.current.value = "";
  };
  const submitHandler = async (event) => {
    event.preventDefault();
    setErrors([]);
    let url = "./transaction";
    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      pm_auth: `${Cookies.get("auth_token")}`,
      checkfor: "fournisseurs",
    };
    if (!selected?.id) {
    notify("error", "Vous devez sélectionner un fournisseur")
    return
    }
    if (!products?.length) {
      notify("error", "Vous devez ajouter au moins un produit")
      return
    }
   
    let data = { fournisseurId : selected.id, products : products,totale : total, paye : payed };
    if (!inputs?.name) {
      setErrors((state) => {
        let news = [...state];
        news.push({ field: "name", message: "Required" });
        return news;
      });
    }
   
    const res=await instance
      .post(url, { ...data }, { headers })
      try{
        if (res?.data?.transaction) {
          notify("success","transaction effectuée");
          setProducts([])
          setErrors([])
          window.location.href = "/Produit"
        }
        if (res?.data?.message) {

          notify("error", res.data.message);
        }

        return;
      }
      catch(err) {
        notify("error", err.message);
      };
    return;
  };
 
  return (
    <div className="transaction_container">
    {products?.length ?<div style={{ display: "none" }}>
        <CodeBars
          toPrint={toPrint}
          products ={products}
          ref={componentRef}
        />
      </div>:""}
      <div className="header">
        <h2>ajouter achat</h2>
        <p>Ajouter un produit</p>
      </div>
      <div className="inputs">
        <div className="product_f">
          <Toaster
            toastOptions={{
              style: {
                background: "transparent",
                border: "none",
                boxShadow: "none",
                color: "#ffffff",
              },
            }}
          />
          <label>
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "black" : "#D8DDE3",
                  width: "88%",
                  margin: "0 auto",
                  color: "#768898",
                  fontFamily: "Poppins",
                  fontSize: ".9rem",
                  fontWeight: "500",
                }),
                
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected ? "black" : "#768898",

                  width: "100%",
                }),
                menu: (provided, state) => ({
                  ...provided,
                  width: "88%",
                  marginLeft: "2.7rem",
                }),
              }}
              onChange={(e)=>{setSelected(e)}}
              options={options}
              getOptionValue = {(option)=>option.label}

            />
            <p> Veuillez sélectionner un fournisseur</p>
          </label>
          <div className="form">
            <div className="p1">
              <label>
                {errors.filter((err) => err.field == "id")?.length ? (
                  <p className="err-field-msg">{`${
                    errors.filter((err) => err.field == "id")[0].message
                  }*`}</p>
                ) : (
                  ""
                )}
                <input
                  ref={ref7}
                  type="text"
                  name="id"
                  placeholder="Idproduit"
                  onChange={inputHandler}
                />
               Entrez le ID de produits
              </label>
              <label>
                {errors.filter((err) => err.field == "barCode")?.length ? (
                  <p className="err-field-msg">{`${
                    errors.filter((err) => err.field == "barCode")[0].message
                  }*`}</p>
                ) : (
                  ""
                )}
                <input
                  ref={ref0}
                  type="text"
                  name="barCode"
                  placeholder="EAN13"
                  onChange={inputHandler}
                />
             Entrez le codebar du produit
              </label>
              <div className="unite">
                {" "}
                <label>
                  {errors.filter((err) => err.field == "name")?.length ? (
                    <p className="err-field-msg">{`${
                      errors.filter((err) => err.field == "name")[0].message
                    }*`}</p>
                  ) : (
                    ""
                  )}
                  <input
                    ref={ref1}
                    type="text"
                    name="name"
                    placeholder="produit"
                    onChange={inputHandler}
                  />
                  Entrez le nom du produit
                </label>
                <label>
                  {errors.filter((err) => err.field == "dateDePeremption")
                    ?.length ? (
                    <p className="err-field-msg">{`${
                      errors.filter((err) => err.field == "dateDePeremption")[0]
                        .message
                    }*`}</p>
                  ) : (
                    ""
                  )}
                  <input
                    ref={ref2}
                    type="date"
                    name="dateDePeremption"
                    placeholder="date de peremption"
                    onChange={inputHandler}
                  />
                  Entrez la date de peremption
                </label>
              </div>
              <div className="unite">
                {" "}
                <label>
                  {errors.filter((err) => err.field == "unite")?.length ? (
                    <p className="err-field-msg">{`${
                      errors.filter((err) => err.field == "unite")[0].message
                    }*`}</p>
                  ) : (
                    ""
                  )}
                  <input
                    ref={ref3}
                    type="text"
                    name="unite"
                    placeholder="Unité"
                    onChange={inputHandler}
                  />
                  Entrez l'unité de mesure
                </label>
                <label>
                  {errors.filter((err) => err.field == "qt")?.length ? (
                    <p className="err-field-msg">{`${
                      errors.filter((err) => err.field == "qt")[0].message
                    }*`}</p>
                  ) : (
                    ""
                  )}
                  <input
                    ref={ref4}
                    type="number"
                    name="qt"
                    placeholder="Qte"
                    onChange={inputHandler}
                  />
                  Entrez la quantitée aquise
                </label>
              </div>
              <div className="unite">
                <label>
                  {errors.filter((err) => err.field == "cout")?.length ? (
                    <p className="err-field-msg">{`${
                      errors.filter((err) => err.field == "cout")[0].message
                    }*`}</p>
                  ) : (
                    ""
                  )}
                  <input
                    ref={ref5}
                    type="number"
                    name="cout"
                    placeholder="Prix unitaire d'achat"
                    onChange={inputHandler}
                  />
                  Entrez le prix d'achat
                </label>
                <label>
                  {errors.filter((err) => err.field == "prix")?.length ? (
                    <p className="err-field-msg">{`${
                      errors.filter((err) => err.field == "prix")[0].message
                    }*`}</p>
                  ) : (
                    ""
                  )}
                  <input
                    ref={ref6}
                    type="number"
                    name="prix"
                    placeholder="prix unitaire de vente"
                    onChange={inputHandler}
                  />
                  Entrez le prix de vente
                </label>
              </div>
              <div className="unite">
              <label>
                  {errors.filter((err) => err.field == "minQt")?.length ? (
                    <p className="err-field-msg">{`${
                      errors.filter((err) => err.field == "minQt")[0].message
                    }*`}</p>
                  ) : (
                    ""
                  )}
                  <input
                    ref={ref4}
                    type="number"
                    name="minQt"
                    placeholder="Quantitée minimale"
                    onChange={inputHandler}
                  />
                  Entrez la quantitée minimale de stock
                </label>
              </div>
            </div>
              
            <div className="btn" onClick={addProductHandler}>
              <p>Ajouter</p>
            </div>
          </div>
        </div>
        <div className="display">
          <div className="headLine">
            <p>Informations du fournisseur</p>
          </div>

          <div className="info">
            <p>
              Nom : <span>{selected?.firstname?selected.firstname:"Not Selected"}</span>
            </p>
            <p>
              Téléphone : <span>{selected?.phone?selected.phone:"Not Selected"}</span>
            </p>
          </div>
          <div className="headLine">
            <p>Information du transaction</p>
          </div>
          <div className="table">
            <table>
              <thead>
                <tr style={{ borderTop: "1px solid #EAECF0 " }}>
                  <th>
                    <div className="th2">
                      <p>Id </p> <img src={downIcon} alt="down" />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Produit </p> <img src={downIcon} alt="down" />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Qte </p> <img src={downIcon} alt="down" />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Prix Unitaire </p> <img src={downIcon} alt="down" />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Prix Totale </p> <img src={downIcon} alt="down" />
                    </div>
                  </th>
                  
                  <th>
                    <div className="th2">
                      <p>Prix Unitaire de vente </p>{" "}
                      <img src={downIcon} alt="down" />
                    </div>
                  </th>
          
                  <th>
                    <div className="th23">
                       <p> </p>
                    </div>
                  </th>
                  <th>
                    <div className="th23">
                       <p> </p>
                    </div>
                  </th>
                </tr>
              </thead>
              {products?.length?<tbody>
                {products
                  .filter((product) => !product.hidden)

                  .map((product, index) => {
                    let perDate = new Date(product.dateDePeremption);
                    let today = new Date();
                    let notDate = new Date(today.getTime() + 259200000);

                    return (
                      <tr
                        key={index}
                        style={{ borderTop: "1px solid #EAECF0 " }}
                      >
                        <td>
                          <div className="td2 lite">
                            <p>{product.barCode} </p>{" "}
                          </div>
                        </td>
                        <td>
                          <div className="td2">
                            <p>{product.name} </p>{" "}
                          </div>
                        </td>
                        <td>
                          <div className={`td2 lite`}>
                            <p>{product.qt}</p>
                          </div>
                        </td>
                        <td>
                          <div className="td2 lite">
                            <p>{product.cout.toLocaleString("en-DE")} DZD</p>
                          </div>
                        </td>
                        <td>
                          <div className={`td2 lite `}>
                            <p>{(product?.cout * product?.qt).toLocaleString("en-DE")} DZD</p>{" "}
                          </div>
                        </td>
                        <td>
                          <div className="td2 lite">
                            <p>{product.prix.toLocaleString("en-DE")} DA</p>{" "}
                          </div>
                        </td>
                        <td>
                          <div className="td2 lite" style={{cursor: "pointer"}} onClick={()=>{setToPrint(product?.barCode)}}>
                            <img src={printIcon2} alt="delete"/>
                          </div>
                        </td>
                        <td>
                          <div className="td2 lite" style={{cursor: "pointer"}} onClick={()=>{let news = products.filter((pr)=>{return product.barCode !== pr.barCode});setProducts(news)}}>
                            <img src={delete_itemIcon} alt="delete"/>
                          </div>
                        </td>
                        
                      </tr>
                    );
                  })}
                {/* <tr style={{ borderTop: "1px solid #EAECF0 " }}>
                                    <td className="td1" style={{ width: "1rem" }}><div><input type="checkbox" /></div></td>
                                    <td><div className="td2"><p>Nom et Prénom </p> </div></td>
                                    <td><div className="td2 lite"><p>12/05/2023 </p> </div></td>
                                    <td><div className="td2 lite"><p>1000,00 DA </p> </div></td>
                                    <td><div className="td3" onClick={handelOptions}><img src={options} alt="options" /><div className="openProfile" style={activeOption ? { animation: "slideDown 0.5s ease-out" } : { display: "none" }} ><p>Oper Profile</p><img src={linkIcon} alt="profile" /></div></div></td>
                                </tr> */}
              </tbody>:<tbody><tr ><td colSpan={7}><div style={{ height : "10rem"}} className="no-fr"><p>Aucun produit n'est Ajouter ! Veuillez ajouter Un produit !</p></div></td></tr></tbody>}
            </table>
          </div>
          <div className="payement">
            <div className="total">
              <p>
                Totale : {parseInt(total).toLocaleString("en-DE")} 
                {" "}
                DA
              </p>{" "}
            </div>
            <label>
              Veuillez entrer le montant payé en DZD
              <input 
                  type="number"
                    name="paye"
                    placeholder="Payé en DZD"
                    onChange={(e)=>{setPayed(e.target.value)}}
              />
            </label>{" "}
            <div className="btn yellow" onClick={()=>{setToPrint((state)=>{if(state===null){return ""}else{return null}})}}>
            <img src={printIcon1} alt="print"/>
              <p>
               Imprimer les codes à barres
              </p>
            </div>
            <div className="btn" onClick={submitHandler}>
              <p>
                <span id="plus">+</span> Valider
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
