import React, { FunctionComponent, useRef, useEffect, useState } from "react";
import "../../css/AddClient.css";
import { AddClientapi,notify } from '../../api/api';

interface VenteProps {
  onCloseModal: () => void;
  values:any
}

const VenteDetails: FunctionComponent<VenteProps> = ({ onCloseModal,values }) => {
  console.log('dagi values',values)
  const modalRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (
      modalRef.current &&
      !modalRef.current.contains(target) &&
      !target.closest(".addclientt") &&
      !target.closest(".inputt-1")  &&
      !target.closest(".inputt-2") 
    ) {
      onCloseModal();
    }
  };
  // const handleAddClient = async () => {
  //   try {
  //     if (!firstName || !lastName || !validatePhone(phone)) {
  //       notify('')
  //       throw new Error('Veuillez remplir tous les champs correctement.');
  //     }
  //     const response = await AddClientapi(firstName, lastName, phone);
  //   } catch (error: any) {
  //     console.error('Erreur lors de l\'ajout du client:', error.message);
  //   }
  // };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCloseModal]);

  return (
    <div className="addclientt" ref={modalRef}>
      <input className="inputt-2" 
      value={'prix: '+values.prix+" Da"}
      placeholder="CodeBar" type="text"
 />
            <input className="inputt-1" 
      value={'valeur payée: '+values.payed+" Da"}
      placeholder="CodeBar" type="text" />
      <div className="headd">
        <div className="dotss">
          <div className="dotss-child" />
          <div className="dotss-item" />
        </div>
        <div className="ajouter-clientt">Details de la Vente:</div>
      </div>
    </div>
  );
};

export default VenteDetails;
