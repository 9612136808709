import React, { FunctionComponent, useRef, useEffect, useState } from "react";
import "../../css/AddAnimal.css";
import { notify,Updatehospitalisation} from "../../api/api";
import { useVetoContext } from '../../api/Context';
import { format } from 'date-fns-tz';

interface EditHospitalisationProps {
  onCloseModal: () => void;
  clickedhos: any;
}

export interface Data {
  id: number;
  name: string;
  race: string;
  age: any;
  startDate: any;
  endDate: any;
  robe: string;
  client: any;
  servicesInformations: any;
  clientId: string;
}

const Edithospitalisation: FunctionComponent<EditHospitalisationProps> = ({ onCloseModal, clickedhos }) => {
  const { animals } = useVetoContext();
  const modalRef = useRef<HTMLDivElement>(null);  
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedAnimal, setSelectedAnimal] = useState<Data>(clickedhos);
  console.log(clickedhos)
  const startDates = clickedhos?.dateEntree? format(new Date(clickedhos.dateEntree), "yyyy-MM-dd'T'HH:mm", { timeZone: 'Africa/Algiers' }) : null;
  const endDates = clickedhos?.dateSortie? format(new Date(clickedhos.dateSortie), "yyyy-MM-dd'T'HH:mm", { timeZone: 'Africa/Algiers' }) : null;
  useEffect(() => {
    const startDates = clickedhos?.dateEntree ? format(new Date(clickedhos.dateEntree), "yyyy-MM-dd'T'HH:mm", { timeZone: 'Africa/Algiers' }) : null;
    const endDates = clickedhos?.dateSortie ? format(new Date(clickedhos.dateSortie), "yyyy-MM-dd'T'HH:mm", { timeZone: 'Africa/Algiers' }) : null;
    setStartDate(`${startDates}`);
    setEndDate(`${endDates}`);
  }, [clickedhos]);

  const [hosData, setHosData] = useState({
    animalId: clickedhos?.animalId || "",
    clientId: clickedhos?.clientId || "",
    dateEntree: clickedhos?.dateEntree || "",
    dateSortie: clickedhos?.dateSortie || "",
    conduiteATenir: clickedhos?.conduiteATenir || "",
    motif: clickedhos?.motif || "",

  });
  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onCloseModal();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCloseModal]);
  const handleButtonClick = async () => {
    try {
      const startDateTime = startDate ? new Date(startDate) : null;
      const endDateTime = endDate ? new Date(endDate) : null;
      const algeriaTimeZone = 'Africa/Algiers';
      const formattedStartDate = startDateTime ? format(startDateTime, "yyyy-MM-dd'T'HH:mm", { timeZone: algeriaTimeZone }) : null;
      const formattedEndDate = endDateTime ? format(endDateTime, "yyyy-MM-dd'T'HH:mm", { timeZone: algeriaTimeZone }) : null;
      const hosd = {
        id:clickedhos?.id,
        animalId: hosData.animalId,
        clientId: hosData.clientId,
        dateEntree: formattedStartDate,
        dateSortie: formattedEndDate,
        conduiteATenir: hosData.conduiteATenir,
        motif: hosData.motif,
      };

      if (!hosd.animalId || !hosd.clientId || !hosd.conduiteATenir || !hosd.motif || !hosd.dateEntree || !hosd.dateSortie) {
        console.error('Veuillez remplir tous les champs correctement.');
        notify('error', 'Veuillez remplir tous les champs correctement.');
        return;
      }

      await Updatehospitalisation(hosd);
    } catch (error: any) {
      console.error('Erreur lors de l\'ajout :', error.message);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setHosData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAnimalChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    
    const selectedAnimalId = e.target.value;
    const animal = animals.find((a: Data) => a.id.toString() === selectedAnimalId);

    if (animal) {
      setHosData((prevData) => ({
        ...prevData,
        animalId: animal.id,
        clientId: animal.clientId,
        animal: animal,
        client: animal.client,
      }));
      setSelectedAnimal(animal);
    } else {
      setHosData((prevData) => ({
        ...prevData,
        animalId: "",
        clientId: "",
      }));
    }
  };

  const handleMotifChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setHosData((prevData) => ({ ...prevData, motif: e.target.value }));
  };

  return (
    <div className="addanimalll" ref={modalRef}>
      <button type="button" className="but2" onClick={handleButtonClick}>
        <div className="backgroun" />
        <div className="suivan">Suivant</div>
      </button>
      <select
        className="inputt1"
        value={selectedAnimal ? selectedAnimal.id.toString() : ''}
        onChange={handleAnimalChange}
      >
        <option value="" disabled>choisir un animal</option>
        {animals && animals.map((animal: Data) => (
          <option
            key={animal.id}
            style={{ backgroundColor: 'pink', color: 'black' }}
            value={animal.id.toString()}
          >
            {animal.name}
          </option>
        ))}
      </select>

      <input
        className="inputt2"
        key={selectedAnimal?.clientId}
        type='text'
        value={selectedAnimal ? selectedAnimal.client.firstname + ' ' + selectedAnimal.client.lastname : ''}
        readOnly
      />

      <select
        className="inputt6"
        placeholder="Motif"
        value={hosData.motif}
        onChange={handleMotifChange}
      >
        <option value="" disabled>Choisir un motif</option>
        <option value="HOSPITALISATION">HOSPITALISATION</option>
        <option value="GARDE_NORMAL">GARDE_NORMAL</option>
      </select>

      <input
        className="inputt3"
        placeholder="conduite à tenir"
        type="text"
        name="conduiteATenir"
        value={hosData.conduiteATenir}
        onChange={handleChange}
      />

      <input
        className="inputt4"
        placeholder="date dentree "
        type="datetime-local"
        name="dateEntree"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
      />

      <input
        className="inputt5"
        placeholder="date de sortie"
        type="datetime-local"
        name="dateSortie"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
      />

      <div className="hed">
        <div className="dos">
          <div className="dos-child" />
          <div className="dos-item" />
        </div>
        <div className="ajouter-Animall">Modifier Hospitalisation</div>
      </div>
    </div>
  );
};

export default Edithospitalisation;
