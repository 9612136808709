import React, { FunctionComponent,useState } from "react";
import "../../css/Notifications.css";

interface NotificationsProps {
  notifications: any;
}

const Notifications: FunctionComponent<NotificationsProps> = ({ notifications }) => {
  console.log(notifications)
  const rdvNotifications = notifications.data&&notifications.data.rdv?notifications.data.rdv:[];
  console.log(rdvNotifications)
  const paymentNotifications = notifications.data&&notifications.data.users&&notifications.data.users.payement?notifications.data.users.payement:[];
  console.log(paymentNotifications)
  const assuranceNotifications =notifications.data&& notifications.data.users&&notifications.data.users.assurance?notifications.data.users.assurance:[];
  const quantityNotifications =notifications.data&&notifications.data.products&& notifications.data.products.qt?notifications.data.products.qt:[];
  const peremptionNotifications =notifications.data&&notifications.data.products&& notifications.data.products.peremption&&notifications.data.products?notifications.data.products.peremption:[];
  const [items, setItems] = useState<string[]>([
    "BlueSky Apartments",
    "Yellow Lines",
    "421 Avenue",
    "421 Avenue",
    "421 Avenue",
    "421 Avenue",
    "421 Avenue",
  ]);

  const addItemToTop = (itemText: string) => {
    setItems([itemText, ...items]);
  };
  return (
    <div className="widget-3">
    <div className="head">
      <div className="rendez-vous-daujourdhui">Notifications d’aujourd’hui</div>
    </div>
    <div className="scrollable-list">
      {rdvNotifications.map((item:any) => (
        <div className="item" key={item.id}>
          <img className="button-icon" alt="" src="/Images/HomePage/undefined.png" />
          <div className="most-successful-fellas">
            {
          item.animal&&item.animal.name?item.animal.name:""}</div>
          <div className="glassbricks">{"rendez vous de "+item.disc}</div>
        </div>
      ))}
          {paymentNotifications.map((item:any) => (
        <div className="item" key={item.id}>
          <img className="button-icon" alt="" src="/Images/HomePage/undefined.png" />
          <div className="most-successful-fellas">{"le "+item.paymentDate+" pour "+item.salary +"da"}</div>
          <div className="glassbricks">{"payment de "+item.firstname+" "+item.lastname}</div>
        </div>
      ))}
          {assuranceNotifications.map((item:any) => (
        <div className="item" key={item.id}>
          <img className="button-icon" alt="" src="/Images/HomePage/undefined.png" />
          <div className="most-successful-fellas">{
          new Date(
            item.assuranceExp
          ).toISOString().split("T")[0]
         }</div>
          <div className="glassbricks">{"assurence expiré de "+item.firstname+" "+item.lastname}</div>
        </div>
      ))}
          {quantityNotifications.map((item:any) => (
        <div className="item" key={item.id}>
          <img className="button-icon" alt="" src="/Images/HomePage/undefined.png" />
          <div className="most-successful-fellas">
            {"quantite disponible "+item.qt}</div>
          <div className="glassbricks">{"fin de stock de "+item.name}</div>
        </div>
      ))}
          {peremptionNotifications.map((item:any) => (
        <div className="item" key={item.id}>
          <img className="button-icon" alt="" src="/Images/HomePage/undefined.png" />
          <div className="most-successful-fellas">
          {new Date(
            item.dateDePeremption
            ).toISOString().split("T")[0]}
            </div>
          <div className="glassbricks">{"le produit "+item.name+" expirera le "}
         </div>
        </div>
      ))}
    </div>
  </div>
  );
};

export default Notifications;
