import React, { FunctionComponent, useRef, useEffect, useState } from "react";
import '../../css/addservice.css';
import Boxs from "./Box";
import TableAddserv1 from "../TablesService/TableAddserv1";
import TableAddserv2 from "../TablesService/TableAddserv2";
import BoxServ from "./BoxServ";

interface Addserviceprops{
  onCloseModal: () => void;
}
interface Element {
  id: string;
  type: string;
  qtDispo: number;
  qtInChart: number ;
}
const AddService  : FunctionComponent<Addserviceprops> = ({ onCloseModal }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [chart, setChart] = useState<Element[]>([]);
  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    
    if (
      !target.closest(".addsservice") 
      
    )
 {
      onCloseModal();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCloseModal]);
return(
    <div className="addsservice">
      <div className="addserv-body">
        <div className="first-part">
    <div>
        <label className="labels">Entrez le code du service</label>
      <input className="code-de-service" type="text" placeholder="Code du Service"/>
      </div>
      <div>
      <img className="searchh-icon" alt="" src="/Images/Clients/search.png" />
      <input type='search' className="search-input" placeholder="Search for product by name or code"/>
      </div>
      </div>
      <div className="second-part">
        <div>
      <label className="labels">Entrez le nom du service</label>
      <input className="nom-de-service" placeholder="Nom du Service"/>
      
     
      <div className="pro-tab1">
      <TableAddserv2 classcolor="cellserv2"
       chart={chart}
       />
      </div>
      </div>
      <div className="pro-tab">
      <TableAddserv1 classcolor="cellserv1" 
      data='qsfqs'
      />
      </div>
      </div>
      <div className="prix-prod">
        <BoxServ titleboxserv="Prix Des Produits: 0 DZD"/>
        </div>
        
        <label className="labels">Entrez le prix du service</label>
        <input className="prix-serv" type="text" placeholder="0"/>
          
        
        <div className="button-section">
          <div>
        <img className="powerstandby-icon" alt="" src="/Images/Clients/powerstandby.png" />
          <button className="reset-btn">Reset</button></div>
          <div>
          <img className="shoppingcart-icon" alt="" src="/Images/Clients/shoppingcart.png" />
          <button className="Add-btn">Add Service</button>
          </div>
        </div>

        </div>
      </div>
   

);
};
export default AddService;