import React from "react";
import "./addService.css";
import { useState, useEffect } from "react";
import { useReactToPrint } from 'react-to-print';

import { useRef } from "react";
import {
  chartIcon,
  resetIcon,
  logo,
  delete_itemIcon,
  searchIcon,
  searchGrayIcon,
  downIcon,
  minusIcon,
  frwrd,
  rtrn,
  linkIcon,
} from "../../assets/icons";
import Cookies from "js-cookie";
import { notify,instance } from "../../api/api";
import toast, { Toaster } from "react-hot-toast";
export default function AddService() {
  const [perPage, setPerPage] = useState(11);
  const [active, setActive] = useState(1);
  const [selectedClient, setSelectedClient ] = useState({})
  const [selectedAnimal, setSelectedAnimal] = useState({})
  const [clients, setClients] = useState([])
  const [animals, setAnimals] = useState([])
  const [services, setServices] = useState([]);
  const [products, setProducts] = useState([]);
  const [chart, setChart] = useState([]);
  const [list, setList] = useState([]);
  const [code,setCode] = useState("")
  const [name,setName] = useState("")
  const [filtred, setFiltred] = useState([]);
  const [filterActive, setFilterActive] = useState("");
  const [servicesProductTracker, setServicesPorductTracker] = useState({});
  const [pages, setPages] = useState([]);
  const [total,setTotal] = useState(0)
  const [payed, setPayed] = useState(0)
  const [validator, setValidator] = useState(false)
    const inputRef = useRef()
    const componentRef = useRef(null);

    useEffect(()=>{setTotal( chart.reduce((acc,chartElement)=>{acc += chartElement.prix*chartElement.qtInChart;return acc},0)  )},[chart])
  

  const chartHandler = (element) => {
    if (element?.type === "P") {
      if (element.qtDispo < 1) {
        notify("error", "Quantité du produit Insufisante");
      }
     
   
      let alredyInChart = false;
      let newChart = [];
      if (chart?.length) {
        for (let el of chart) {
          if (el.id == element.id) {
            alredyInChart = true;
          }
        }
      }
      if (alredyInChart) {
        newChart = chart.map((chartElement) => {
            let tracker = servicesProductTracker[chartElement.id]
            if(!tracker) {tracker = 0}
          if (
            chartElement.id === element.id &&
            chartElement.qtInChart+tracker < element.qtDispo
          ) {
            chartElement.qtInChart = chartElement.qtInChart + 1;
          }
          if ( chartElement.id === element.id && chartElement.qtInChart+tracker >= element.qtDispo) {
            notify("error", "quantité insufisante");
          }
          return chartElement;
        });
        setChart(newChart);
        return;
      }else{

            let tracker = servicesProductTracker[element.id]
            if(!tracker) {tracker = 0}
          if (
           
           tracker < element.qtDispo
          ) {
            setChart((state) => [...state, { ...element, qtInChart: 1 }]);

          }
          if ( tracker >= element.qtDispo) {
            notify("error", "quantité insufisante");
          }


      }

      
      return;
    }
    if (element?.type === "S") {
        let newTracker =servicesProductTracker
      if (element?.products?.length) {
        for (let product of element.products) {

          let qtDispo = list.filter((listElement)=>listElement.id===product.produitId)[0].qtDispo
          let qtInChart = 0;
          let tracker = 0
          if(servicesProductTracker[product.produitId]){
            tracker = servicesProductTracker[product.produitId]
          }
          if (
            chart.filter((chartElement) => chartElement.id === product.produitId)
              ?.length
          ) {
            qtInChart = chart.filter(
              (chartElement) => chartElement.id === product.produitId
            )[0].qtInChart;
          }
          if (qtInChart+tracker+product.qt >qtDispo) {
            notify("error", "Quantité insufisante dans le stock");
            return;
          }
          if(servicesProductTracker[product.produitId]) {
            newTracker[product.produitId] = newTracker[product.produitId]+ product.qt

          } else {newTracker[product.produitId]=product.qt}
        }
        setServicesPorductTracker(newTracker)
        let alredyInChart = false;
        let newChart = [];
        if (chart?.length) {
          for (let el of chart) {
            if (el.id == element.id) {
              alredyInChart = true;
            }
          }
        }
        if (alredyInChart) {
          newChart = chart.map((chartElement) => {
             
            if (
              chartElement.id === element.id 
            
            ) {
              chartElement.qtInChart = chartElement.qtInChart + 1;
            }
            
            return chartElement;
          });
          setChart(newChart);
          return;
        }
        setChart((state) => [...state, { ...element, qtInChart: 1 }]);
        return;
      }

    }
  };
  const deleteHandler = async(element)=>{
    if (element?.type === "P") {
        let newChart = chart.filter((chartElement)=>element.id!==chartElement.id)
        setChart(newChart)
    }
    if(element?.type ==="S"){
        let newTracker = servicesProductTracker
        let newChart = chart.filter((chartElement)=>element.id!==chartElement.id)
        if(element?.products){
            for(let el of element.products){
                newTracker[el.produitId] = 0
            }

        }
        setServicesPorductTracker(newTracker)
        setChart(newChart)
    }
    return
  }
  const minusHandler = (element)=>{
    if (element?.type === "P") {
        let newChart = chart.map((chartElement)=>{if(element.id===chartElement.id && chartElement.qtInChart>0){chartElement.qtInChart =chartElement.qtInChart-1}if(chartElement.qtInChart>0){return chartElement}}).filter((element)=>element !== undefined)
        setChart(newChart)
        return
    }
    if(element?.type ==="S"){
        let newTracker = servicesProductTracker
        let newChart = chart.map((chartElement)=>{if(element.id===chartElement.id && chartElement.qtInChart>0){chartElement.qtInChart =chartElement.qtInChart-1}if(chartElement.qtInChart>0){return chartElement}}).filter((element)=>!null)
        if(element?.products){
            for(let el of element.products){
                newTracker[el.produitId] = newTracker[el.produitId]-el.qt
            }

        }
        setServicesPorductTracker(newTracker)
        setChart(newChart)
        return
    }
  }
  const handelnavigation = (type, page) => {
    if (type === "return") {
      if (active > 1) {
        setActive((state) => state - 1);
      }
    }
    if (type === "frwrd") {
      if (active < pages.at(-1)) {
        setActive((state) => state + 1);
      }
    }
    if (type === "page") {
      setActive(page);
    }
  };
  const pager = (length, perPage) => {
    let actualLength = length / perPage;
    let pages = [];
    for (let i = 0; i < actualLength; i = i + 1) {
      pages[i] = i + 1;
    }
    return pages;
  };


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });



const payementHandler = async () => {
    try {
        if (!name || !code) {
            notify("error", "Tous les champs sont requis");
            return;
        }
        let url = "/service";
        let headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            pm_auth: `${Cookies.get("auth_token")}`,
            checkfor: "produit",
        };
        let data = {};
        let products = chart.filter((chartElement) => chartElement.type === "P").map((product) => {
            return { ...product, qt: product.qtInChart };
        });
        if (products.length) {
            data.products = [...products];
        }
        data.name = name;
        data.barCode = code;
        data.prix = parseInt(payed);
        const res = await instance.post(url, data, { headers });
        console.log(res.data)
        if (res?.data?.created) {
            notify("success", "Créé avec Succès");
            setValidator((state) => !state);
            // window.location.href = "/services"
        }

        setChart([]);
        setServicesPorductTracker({});
        inputRef.current.value = 0;
    } catch (err) {
        notify("error", err.message);
    }
};


  useEffect(() => {
 
    if (products?.length) {
      setList([...products]);
      return;
    }

  }, [products]);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#f3f4f6",
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  useEffect(() => {
    try {
      setPages(pager(list?.length, perPage));
    } catch (err) {
      notify("error", err.message);
    }
  }, [list]);
  useEffect(() => {
    const getProducts = async () => {
      let url = "/product";
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "produit",
      };
      try{
      const res=await instance
        .get(url, { headers })
          if (res?.data?.products) {
            setProducts(
              res.data.products.map((product) => {
                return { ...product, type: "P" };
              })
            );
          }
        }

        catch(err){
          notify("error", err.message);
        };
    };

    try {
      getProducts();
    } catch (err) {
      notify("error", err.message);
    }
  }, [validator]);

  
  const searchHandler = (event) => {
    setFilterActive(event.target.value);
    setFiltred((state) =>
      list.filter((element) => {
        return (
          element?.name
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          element?.barCode
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        );
      })
    );
  };
  useEffect(() => {
    if (filterActive) {
      setPages(pager(filtred.length, perPage));
      setActive(1);
    }
  }, [filterActive, filtred]);
  return (
    <div className="page_container">
    <div style={{display : "none"}}>
    </div>
      <Toaster
        toastOptions={{
          style: {
            background: "transparent",
            border: "none",
            boxShadow: "none",
            color: "#ffffff",
          },
        }}
      />
      <div className="p-r">
        <div className="header" style={{cursor:"pointer"}} onClick={()=>{window.location.href = "/services"}}>
          <img src="/logo-m-1.svg" alt="logo" />
        </div>
        <div className="body">
        <div className="select">
          <label>
              Entrez Le Code  Du service 
              <input  name="barCode" type="text" placeholder="Code du Service" onChange={(e)=>{setCode(e.target.value)}}/>
            </label>          </div>
        

          <div className="select">
          <label>
              Entrez Le Nom Du Service
              <input  name="name" type="text" placeholder="Nom du Service" onChange={(e)=>{setName(e.target.value)}}/>
            </label>          </div>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>P/S</th>
                  <th>prix</th>
                  <th>Qte</th>
                  <th>sub-totale</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
               {chart?.length ? chart.map((chartElement,index)=>{
                return(<tr key={index}>
                  <td>
                    <div className="td">
                      {chartElement.barCode} <br /> {chartElement.name}
                    </div>
                  </td>
                  <td>
                    <div className="td">{chartElement.prix.toLocaleString("en-DE")} DZD</div>
                  </td>
                  <td>
                    <div className="td">
                      <div className="qte">
                        <div className="minus" onClick={()=>{minusHandler(chartElement)}}>-</div>
                        <input type="number" disabled={true} value={chartElement.qtInChart}/>
                        <div className="plus" onClick={(e)=>{chartHandler(chartElement)}}>+</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="td">{(chartElement.qtInChart*chartElement.prix).toLocaleString("en-DE")} DZD</div>
                  </td>
                  <td>
                    <div className="td" onClick={()=>{deleteHandler(chartElement)}}>
                      <img src={delete_itemIcon} alt="delete_item" />
                    </div>
                  </td>
                </tr>)



               }) : ""}
              </tbody>
            </table>
          </div>
          <div className="total">
            <p>Prix Des Produits : {total.toLocaleString("en-DE")} DZD</p>
          </div>
          <div className="payed">
            <label>
              Entrez le Prix du Service
              <input ref={inputRef} name="payed" type="number" defaultValue={0} onChange={(e)=>{setPayed(e.target.value)}}/>
            </label>
          </div>
          <div className="btns">
            <div className="reset" onClick={()=>{setChart([]); setServicesPorductTracker({})}}>
              <img src={resetIcon} alt="reset" /> <p>Reset</p>
            </div>
            <div className="validate" onClick={payementHandler}>
              <img src={chartIcon} alt="validate"/> <p>Add Service</p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-l">
        <div className="searchss">
          <img src={searchGrayIcon} alt="search" />
          <input
            placeholder="search for Product By name Or Code"
            type="text"
            onChange={(e) => {
              searchHandler(e);
            }}
          />
        </div>
        <div className=" table">
          <table>
            <thead>
              <tr style={{ borderTop: "1px solid #EAECF0 " }}>
                <th>
                  <div className="th2">
                    <p>Id </p> <img src={downIcon} alt="down" />
                  </div>
                </th>
                <th>
                  <div className="th2">
                    <p>Nom </p> <img src={downIcon} alt="down" />
                  </div>
                </th>
                <th>
                  <div className="th2">
                    <p>Qte </p> <img src={downIcon} alt="down" />
                  </div>
                </th>
                <th>
                  <div className="th2">
                    <p>Type </p> <img src={downIcon} alt="down" />
                  </div>
                </th>
                <th>
                  <div className="th2">
                    <p>Date De Peremption </p> <img src={downIcon} alt="down" />
                  </div>
                </th>
                <th>
                  <div className="th2">
                    <p>Prix de vente </p> <img src={downIcon} alt="down" />
                  </div>
                </th>
                <th>
                  <div className="th3"></div>
                </th>
              </tr>
            </thead>
            {list?.length ? (
              <tbody>
                {filterActive
                  ? filtred
                      .filter((element) => !element.hidden)
                      .slice((active - 1) * 11, active * 11)
                      .map((element, index) => {
                        let isValid = false;
                        if (element?.dateDePeremption) {
                          let perDate = new Date(element.dateDePeremption);
                          let today = new Date();
                          let notDate = new Date(today.getTime() + 259200000);
                          isValid = perDate.getTime() <= notDate.getTime();
                        }

                        return (
                          <tr
                            key={index}
                            style={{ borderTop: "1px solid #EAECF0 " }}
                          >
                            <td>
                              <div className="td2 lite">
                                <p>{element.barCode} </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div className="td2">
                                <p>{element.name} </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div
                                className={`td2 lite ${
                                  element.qtDispo <= element.minQt ? "red" : ""
                                }`}
                              >
                                <p>
                                  {element?.qtDispo ? element.qtDispo : "--"}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="td2 lite">
                                <p>{element.type}</p>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`td2 lite ${isValid ? "red" : ""}`}
                              >
                                <p>
                                  {element?.dateDePeremption
                                    ? `${
                                        new Date(element.dateDePeremption)
                                          .toISOString()
                                          .split("T")[0]
                                      }`
                                    : "aucune date"}{" "}
                                </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div className="td2 lite">
                                <p>{element.prix.toLocaleString("en-DE")} DA</p>{" "}
                              </div>
                            </td>
                            <td>
                              <div
                                onClick={() => {
                                  chartHandler(element);
                                }}
                                className="add_btn"
                              >
                                Add
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  : list
                      .filter((element) => !element.hidden)
                      .slice((active - 1) * 11, active * 11)
                      .map((element, index) => {
                        let isValid = false;
                        if (element?.dateDePeremption) {
                          let perDate = new Date(element.dateDePeremption);
                          let today = new Date();
                          let notDate = new Date(today.getTime() + 259200000);
                          isValid = perDate.getTime() <= notDate.getTime();
                        }

                        return (
                          <tr
                            key={index}
                            style={{ borderTop: "1px solid #EAECF0 " }}
                          >
                            <td>
                              <div className="td2 lite">
                                <p>{element.barCode} </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div className="td2">
                                <p>{element.name} </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div
                                className={`td2 lite ${
                                  element.qtDispo <= element.minQt ? "red" : ""
                                }`}
                              >
                                <p>
                                  {element?.qtDispo ? element.qtDispo : "--"}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="td2 lite">
                                <p>{element.type}</p>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`td2 lite ${isValid ? "red" : ""}`}
                              >
                                <p>
                                  {element?.dateDePeremption
                                    ? `${
                                        new Date(element.dateDePeremption)
                                          .toISOString()
                                          .split("T")[0]
                                      }`
                                    : "aucune date"}{" "}
                                </p>{" "}
                              </div>
                            </td>
                            <td>
                              <div className="td2 lite">
                                <p>{element.prix.toLocaleString("en-DE")} DA</p>{" "}
                              </div>
                            </td>
                            <td>
                              <div
                                onClick={() => {
                                  chartHandler(element);
                                }}
                                className="add_btn"
                              >
                                Add
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                {/* <tr style={{ borderTop: "1px solid #EAECF0 " }}>
                                    <td className="td1" style={{ width: "1rem" }}><div><input type="checkbox" /></div></td>
                                    <td><div className="td2"><p>Nom et Prénom </p> </div></td>
                                    <td><div className="td2 lite"><p>12/05/2023 </p> </div></td>
                                    <td><div className="td2 lite"><p>1000,00 DA </p> </div></td>
                                    <td><div className="td3" onClick={handelOptions}><img src={options} alt="options" /><div className="openProfile" style={activeOption ? { animation: "slideDown 0.5s ease-out" } : { display: "none" }} ><p>Oper Profile</p><img src={linkIcon} alt="profile" /></div></div></td>
                                </tr> */}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={8}>
                    <div className="no-fr">
                      <p>
                        Aucun produit n'est retrouver ! veuillez Ajouter Un!
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>

        <div
          className="p-n"
          style={{ width: "100%", margin: "auto auto 1rem auto" }}
        >
          <div className="pagination">
            <div
              className="nav"
              onClick={() => {
                handelnavigation("return");
              }}
              style={
                active === 1 || pages.length === 0
                  ? {
                      backgroundColor: "#F5F5F5",
                      opacity: ".6",
                      cursor: "default",
                    }
                  : {}
              }
            >
              <img src={rtrn} alt="return" />
            </div>
            {active >= 3 && pages.length > 3 ? (
              <div className="ect">...</div>
            ) : (
              ""
            )}
            {pages.length > 3
              ? active < 3 && active > 0
                ? pages?.slice(0, 3).map((page, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          handelnavigation("page", page);
                        }}
                        className="page"
                        style={
                          active === page ? { backgroundColor: "#100DB1" } : {}
                        }
                      >
                        {" "}
                        <p style={page === active ? { color: "white" } : {}}>
                          {page}
                        </p>
                      </div>
                    );
                  })
                : active > pages.length - 3
                ? pages
                    ?.slice(pages.length - 3, pages.length)
                    .map((page, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            handelnavigation("page", page);
                          }}
                          className="page"
                          style={
                            active === page
                              ? { backgroundColor: "#100DB1" }
                              : {}
                          }
                        >
                          {" "}
                          <p style={page === active ? { color: "white" } : {}}>
                            {page}
                          </p>
                        </div>
                      );
                    })
                : pages?.slice(active - 2, active + 2).map((page, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          handelnavigation("page", page);
                        }}
                        className="page"
                        style={
                          active === page ? { backgroundColor: "#100DB1" } : {}
                        }
                      >
                        {" "}
                        <p style={page === active ? { color: "white" } : {}}>
                          {page}
                        </p>
                      </div>
                    );
                  })
              : pages?.map((page, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        handelnavigation("page", page);
                      }}
                      className="page"
                      style={
                        active === page ? { backgroundColor: "#100DB1" } : {}
                      }
                    >
                      {" "}
                      <p style={page === active ? { color: "white" } : {}}>
                        {page}
                      </p>
                    </div>
                  );
                })}

            {active < pages.length - 2 && pages.length > 3 ? (
              <div className="ect">...</div>
            ) : (
              ""
            )}
            <div
              className="frwrd"
              onClick={() => {
                handelnavigation("frwrd");
              }}
              style={
                active === pages.at(-1)
                  ? {
                      backgroundColor: " #F5F5F5",
                      opacity: ".6",
                      cursor: "default",
                    }
                  : {}
              }
            >
              <div
                className="nav"
                style={
                  active === pages.at(-1) || pages.length === 0
                    ? {
                        backgroundColor: " #F5F5F5",
                        opacity: ".6",
                        cursor: "default",
                      }
                    : {}
                }
              >
                <img src={frwrd} alt="next" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
