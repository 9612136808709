import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../../css/agenda.css";


import React, { FunctionComponent, useState, useEffect } from "react";
import ShowDates from "./ShowDates";
import { GetAllRdv } from "../../api/api";
export interface Data {
  id: number;
  name: string;
  race: string;
  age: any;
  robe: string;
  startDate:any;
  endDate:any;
  client: any;
  servicesInformations: any;
  clientId: string;
}
type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];



const Agenda: FunctionComponent = () => {
  const [value, onChange] = useState<Value>(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<Data | null>(null);
  const [dataRdv, setDataRdv] = useState<Data[]>([]);
  const [morningAppointments, setMorningAppointments] = useState<Data[]>([]);
  const [afternoonAppointments, setAfternoonAppointments] = useState<Data[]>([]);

  const handleShowDtaes = (selectedData: Data) => {
    setSelectedData(selectedData);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchRdvs = async () => {
      try {
        const Response = await GetAllRdv();
        setDataRdv(Response);
      } catch (error) {
        console.error("Error fetching:", error);
      }
    };

    fetchRdvs();
  }, []);

  useEffect(() => {     
    if (value instanceof Date) {
      const morningAppointments = dataRdv
        ? dataRdv.filter((data: Data) => {
            const day=new Date(data.startDate)
            return day.getMonth() === value.getMonth() &&day.getFullYear() === value.getFullYear()&&day.getDate() === value.getDate()  && day.getHours() >= 6 && day.getHours() < 12;
          })
        : null;
      const afternoonAppointments = dataRdv
        ? dataRdv.filter((data: Data) => {
          const day=new Date(data.startDate)
          console.log(day)
          console.log(value)
          return day.getMonth() === value.getMonth() &&day.getFullYear() === value.getFullYear()&&day.getDay() === value.getDay() && day.getHours() >= 12 && day.getHours() < 24;
          })
        : null;
       
console.log(afternoonAppointments)
      setMorningAppointments(morningAppointments || []);
      setAfternoonAppointments(afternoonAppointments || []);
    }
  }, [value, dataRdv]);

  const getFormattedTime = (date: Date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;

    return `${formattedHours}:${formattedMinutes} ${period}`;
  };

  return (
    <div className="agendda">
      <Calendar onChange={onChange} value={value} calendarType="islamic" className="agenda" />
      <div className="times">
        <div className="morning-section">
          <b className="morning-text">Morning</b>
          <div className="hours">
            {morningAppointments.map((data: Data) => (
              <button className="btn-hour" onClick={() => handleShowDtaes(data)} key={data.id}>
                {getFormattedTime(new Date(data.startDate))}
              </button>
            ))}
          </div>
        </div>
        <div className="afternoon-section">
          <b className="morning-text">Afternoon</b>
          <div className="hours">
            {afternoonAppointments.map((data: Data) => (
              <button className="btn-hour" onClick={() => handleShowDtaes(data)} key={data.id}>
                {getFormattedTime(new Date(data.startDate))}
              </button>
            ))}
          </div>
        </div>
      </div>
      {isModalOpen && <ShowDates onCloseModal={handleCloseModal} selectedData={selectedData} />}
    </div>
  );
};

export default Agenda;


