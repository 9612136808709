
import "./receit.css"


import React, { useState } from 'react';

export const Reciept = React.forwardRef((props, ref) => {
  let {chart,client,animal, receiptnumber,total,payed}= props
  
  return (
    <div className="receipt" ref={ref}>
      <div className="header">
        <div>
          <img src="/logo-m-1.svg" alt="Logo" />
          <h2>Cabinet de vétérinaire Fertas</h2>
        </div>
        <div>
          <span>{client?.firstname?client.firstname +" "+client.lastname:"Guest"}</span>
          <span>Receipt #{receiptnumber}</span>
        </div>
      </div>
      <p>-------------------------------------------</p>
      <ul className="product-list">
        <li>
          <span>P/S</span>
          <span>Quantité</span>
          <span>Prix</span>
        </li>
        {chart.map((product) => (
          <li key={product.id} >
            <span>{product.name}</span>
            <span className="li">{product.qtInChart}</span>
            <span>{product.prix*product.qtInChart}</span>
          </li>
        ))}
      </ul>
      <p>-------------------------------------------</p>

      <div className="total-price">
        <span>Total:</span>
        <span>{total.toLocaleString("en-DE")} DZD</span>
      </div>
      <div className="total-price">
        <span>Total Versment:</span>
        <span>{parseInt(payed).toLocaleString("en-DE") } DZD</span>
      </div>
      <p>-------------------------------------------</p>

      <div className="footer">
      Veuillez vérifier le reçu avant de partir.
      </div>
    </div>
  );
});
