import React, { FunctionComponent,useRef, useState,useEffect } from 'react';
import '../../css/showdates.css';
interface Showprops{
    onCloseModal: () => void;
    selectedData:any

}
const ShowDates:  FunctionComponent<Showprops> = ({onCloseModal,selectedData}) =>  {
    const modalRef = useRef<HTMLDivElement>(null);
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      
    if (
      
      !target.closest(".show-tab") 
      
    )
   {
        onCloseModal();
      }
    };
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [onCloseModal]);

    return (
      <div className='show-tab'> 
          <h3 className='text'>{selectedData.title}</h3>
          <p className='text'>Description: {selectedData.disc}</p>
          <p className='text'>End date: {selectedData.endDate}</p>
      </div>
  );
  
}
export default ShowDates;