import "./userProfileForm.css";
import React, { useEffect, useRef, useState } from "react";
import {
  errIcon,
  successIcon,
  cancelWhite,
  arrowIcon,
  editIcon,
  rightArrowIcon,
} from "../../assets/icons";
import { instance,notify } from "../../api/api";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";

import { useParams } from "react-router-dom";
export default function UserProfileForm({
  user,
  
  handelvalidator,

}) {
  const [lastlogdin, setlastlogedin] = useState(null);
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState([]);
  const inputsRef = useRef([]);


  

  const deletehandler = async () => {
    let url = `/users/${user.id}`;

    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      pm_auth: `${Cookies.get("auth_token")}`,
    };
    try{
 const res=await instance
      .delete(url, { headers })
        if (res?.data?.deleted) {
          notify("success", "Ce utilisateur a était supprimé avec succés !");
          window.location.href = "/users";
        } else {
         notify("error", "Des Records sont liés avec cet Utilisateur");
        }
      }
      catch(err){
       notify("error", err.message);
      };
  };
  const inputHandler = (event) => {
    event.preventDefault();
    setInputs((state) => {
      let news = state;
      news[event.target.name] = event.target.value;
      return news;
    });
  };
  const submitHandler = async (event) => {
    event.preventDefault();
    setErrors([]);
    let url = `/users/${user.id}`;

    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      pm_auth: `${Cookies.get("auth_token")}`,
    };
    let data = inputs;

    if (!Object.keys(inputs).length) {
     notify("error", "Acun changement n'a était éffectué !");
      return;
    }
    if (inputs?.phone) {
      if (inputs?.phone?.length !== 12 && inputs?.phone?.length !== 10) {
        setErrors((state) => {
          let news = state;
          news.push({
            field: "phone",
            message:
              "Invalid Number, the phone number should have 10 charachters or 12 !",
          });
          return news;
        });
        return;
      }
    }
 try{
   const res=await instance.put(url, { data: data }, { headers })
        if (res?.data?.updated) {
          notify("success", "Modifié avec succés");
          handelvalidator(state=>!state);
          inputsRef.current[0].value = "";
          inputsRef.current[1].value = "";
          inputsRef.current[2].value = "";
          inputsRef.current[3].value = "";
          inputsRef.current[4].value = "";
        } else {
         notify("error", "Quelque chose s'est mal passé");
        }

        return;
      }
      catch(err) {
       notify("error", err.message);
      };
    return;
  };
  return (
    <div className="user-container">
      <div className="form">
        <div className="p1">
          <label>
            Nom D'Utilisateur
            {errors.filter((err) => err.field == "email")?.length ? (
              <p className="err-field-msg">{`${
                errors.filter((err) => err.field == "email")[0].message
              }*`}</p>
            ) : (
              ""
            )}
            <input
              ref={(el) => (inputsRef.current[0] = el)}
              type="text"
              name="email"
              placeholder={`${user?.email ? user.email : "Nom*"}`}
              onChange={inputHandler}
            />
          </label>
          <label>
            Téléphone
            {errors.filter((err) => err.field == "phone")?.length ? (
              <p className="err-field-msg">{`${
                errors.filter((err) => err.field == "phone")[0].message
              }*`}</p>
            ) : (
              ""
            )}
            <input
              ref={(el) => (inputsRef.current[1] = el)}
              type="number"
              name="phone"
              placeholder={`${user?.phone ? user.phone : "Téléphone"}`}
              onChange={inputHandler}
            />
          </label>
          <label>
            Mot De Passe
            {errors.filter((err) => err.field == "password")?.length ? (
              <p className="err-field-msg">{`${
                errors.filter((err) => err.field == "password")[0].message
              }*`}</p>
            ) : (
              ""
            )}
            <input
              ref={(el) => (inputsRef.current[2] = el)}
              type="text"
              name="password"
              placeholder={`Mot De Passe`}
              onChange={inputHandler}
            />
          </label>
          <label>
            Jour de salaire 
            {errors.filter((err) => err.field == "paymentDate")?.length ? (
              <p className="err-field-msg">{`${
                errors.filter((err) => err.field == "paymentDate")[0].message
              }*`}</p>
            ) : (
              ""
            )}
            <input
              ref={(el) => (inputsRef.current[3] = el)}
              type="number"
              name="paymentDate"
              placeholder={`${
                user?.paymentDate ? user.paymentDate : "0"
              }`}
              onChange={inputHandler}
            />
          </label>
          <label>
            Date d'expiration d'Assurance
            {errors.filter((err) => err.field == "assuranceExp")?.length ? (
              <p className="err-field-msg">{`${
                errors.filter((err) => err.field == "assuranceExp")[0].message
              }*`}</p>
            ) : (
              ""
            )}
            <input
              ref={(el) => (inputsRef.current[4] = el)}
              onFocus={(event
              )=>{event.target.type='date'}}
              onBlur={(event
              )=>{event.target.type='text'}}
              name="assuranceExp"
              placeholder={`${
                user?.assuranceExp ? user.assuranceExp.split("T")[0] : ""
              }`}
              onChange={inputHandler}
            />
          </label>
        </div>

        <div className="btns">
          <div className="cancel" onClick={submitHandler}>
            <img src={editIcon} alt="cancel" /> <p>Modifier</p>
          </div>
          <div className="delete" onClick={deletehandler}>
            <img src={cancelWhite} alt="delete" /> <p>Supprimer</p>
          </div>
        </div>
      </div>
      <div className="options">
        <div className="option c1">
          <p>Derniere utilisation :</p>
          <p className="num">
            {user?.lastlogdin ? user.lastlogdin.split("T")[0] : "Jamais Utilisé"}
          </p>
        </div>
      </div>
    </div>
  );
}
