import React from 'react';
import { FunctionComponent } from 'react';
import '../../css/Buttons2.css';

interface Buttons2Props {
  onAjouterAnimalClick: () => void;
}

const Buttons2: FunctionComponent<Buttons2Props> = ({ onAjouterAnimalClick }) => {
  return (
    <div className="buttonss">
      <button className="ajouterclient" id="AjouterAnimale" onClick={onAjouterAnimalClick}>
        <div className="background" />
        <div className="ajouter-Animal"> Ajouter Animal</div>
        <img className="add-user-icon" alt="" src="/Images/Clients/addanimal.png" />
      </button>
      {/* <button className="ajouterclientt" id="Modifier">
        <div className="background2" />
        <div className="ajouter-Animal"> Modifier Animal </div>
        <img className="add-user-iconn2" alt="" src="/Images/Clients/update.png" />
      </button> */}
    </div>
  );
};

export default Buttons2;
