import {
  deleteIcon,
  editIcon,
  filtersIcon,
  frwrd,
  arrowUpIcon,
  moreIcon,
  options,
  rtrn,
  searchIcon,
  downIcon,
  minusIcon,
  linkIcon,
  cancelBlack,
  cancelWhite,
  arrowIcon,
} from "../../assets/icons";
import "./fournisseursList.css";
import { instance,notify } from "../../api/api";
import toast, { Toaster } from "react-hot-toast";

import React, { useEffect, useRef, useState } from "react";

import Cookies from "js-cookie";
const pager = (length, perPage) => {
  let actualLength = length / perPage;
  let pages = [];
  for (let i = 0; i < actualLength; i = i + 1) {
    pages[i] = i + 1;
  }
  return pages;
};

export default function FournisseursList() {
  const [perPage, setPerPage] = useState(11);
  const [active, setActive] = useState(1);
  const [deleteWindow, setDeleteWindow] = useState(false);
  const [selected, setSelected] = useState([]);
  const [activeOption, setActiveOption] = useState("");
  const [fournisseurs, setFournissseurs] = useState([]);
  const [credit, setCredit] = useState(0);
  const [filtred, setFiltred] = useState([]);
  const [filterActive, setFilterActive] = useState("");
  const [pages, setPages] = useState([]);
  const [reversed,setReversed] = useState(false)
  const [sortType,setSortType] = useState("name")
  const [sortModifier,setSortModifier] = useState("asc")


 const  handelSort =(type)=>{
        let newSortModifier;
        if(sortModifier==="asc"){newSortModifier = "desc"}else{newSortModifier ="asc"}
        if(type ==="name"){
          if(sortType !== "name") {setSortType("name")}
          if(newSortModifier==="asc"){
          let newFournisseursList = fournisseurs.sort((a,b)=>{
            let nameA = a.firstname.toUpperCase()
            let nameB = b.firstname.toUpperCase()
            if (nameA < nameB) {
              return -1;
            } else if (nameA > nameB) {
              return 1;
            } else {
              return 0;
            }
          })
          setFournissseurs([...newFournisseursList])
          setSortModifier(newSortModifier)
          }
          if(newSortModifier==="desc"){
            let newFournisseursList = fournisseurs.sort((a,b)=>{
              let nameA = a.firstname.toUpperCase()
              let nameB = b.firstname.toUpperCase()
              if (nameA > nameB) {
                return -1;
              } else if (nameA < nameB) {
                return 1;
              } else {
                return 0;
              }
            })
            setFournissseurs([...newFournisseursList])
            setSortModifier(newSortModifier)
            }
        }
        if(type ==="date"){
          if(sortType !== "date") {setSortType("date")}
          if(newSortModifier==="asc"){
          let newFournisseursList = fournisseurs.sort((a,b)=>{
            var dateA = a.transactions[0]?.createdAt ? new Date(a?.transactions[0]?.createdAt) : null;
            var dateB = b.transactions[0]?.createdAt ? new Date(b.transactions[0]?.createdAt) : null;
        
            if (dateA === null && dateB === null) {
              return 0; // If both dates are null, maintain the order
            } else if (dateA === null) {
              return 1; // Place null values at the end
            } else if (dateB === null) {
              return -1; // Place null values at the end
            } else {
              return dateA - dateB; // Compare the valid dates
            }
          })
          setFournissseurs([...newFournisseursList])
          setSortModifier(newSortModifier)
          }
          if(newSortModifier==="desc"){
            let newFournisseursList = fournisseurs.sort((a,b)=>{
              var dateA = a.transactions[0]?.createdAt ? new Date(a?.transactions[0]?.createdAt) : null;
              var dateB = b.transactions[0]?.createdAt ? new Date(b.transactions[0]?.createdAt) : null;
          
              if (dateA === null && dateB === null) {
                return 0; // If both dates are null, maintain the order
              } else if (dateB === null) {
                return 1; // Place null values at the end
              } else if (dateA === null) {
                return -1; // Place null values at the end
              } else {
                return dateB - dateA; // Compare the valid dates
              }})
              setFournissseurs([...newFournisseursList])
              setSortModifier(newSortModifier)
            }
        }


 }




  const handeledit = () => {
    if (!selected.length) {
      notify("error", "Aucun fournisseur selectioné !");
      return;
    }
    window.location.href = `/fournisseur/${selected[0]}`;
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.classList.contains("clicked")) {
        handelOptions("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [activeOption]);
  useEffect(() => {
    try {
      setPages(pager(fournisseurs.length, perPage));
      let creditTotale = 0;
      if (fournisseurs) {
        creditTotale = fournisseurs.reduce((acc, fournisseur) => {
          acc += fournisseur.transactions.reduce((accm, transaction) => {
            accm += transaction.totale - transaction.paye;
            return accm;
          }, 0);
          return acc;
        }, 0);
      }
      setCredit(creditTotale);
    } catch (err) {
      notify("error", err.message);
    }
  }, [fournisseurs]);
  useEffect(() => {
    const getdata = async () => {
      let url = "/fournisseurs/";
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        pm_auth: `${Cookies.get("auth_token")}`,
        checkfor: "fournisseurs",
      };
      try{
     const res=await instance
        .get(url, { headers })
          if (res?.data?.fournisseurs) {
            let newFournisseursList = res.data.fournisseurs.sort((a,b)=>{
              let nameA = a.firstname.toUpperCase()
              let nameB = b.firstname.toUpperCase()
              if (nameA < nameB) {
                return -1;
              } else if (nameA > nameB) {
                return 1;

              } else {
                return 0;
              }
            })
            setFournissseurs(newFournisseursList);
          }
        }
        catch(err) {
          notify("error", err.message);
        };
    };
    try {
      getdata();
    } catch (err) {
      notify("error", err.message);
    }
  }, []);
  useEffect(() => {
      setPages(pager(filtred.length, perPage));
      setActive(1)
  }, [filterActive, filtred]);
  const handeldeletewindow = () => {
    if (selected?.length) {
      let isValid =
        fournisseurs.filter((fournisseur) => fournisseur.id === selected[0])[0]
          .transactions.length <= 0;

      if (isValid) {
        setDeleteWindow((state) => !state);
        return;
      } else {
        notify("error", "Des records sont liée avec ce fournisseur !");
        return;
      }
    }
    notify("error", "Vous devez selectioné un fournisseur !");
    return;
  };
  const handelselect = (id) => {
    if (selected[0] === id) {
      setSelected([]);
    } else {
      setSelected([id]);
    }
  };
  // const handelselect = (id) => {
  //   const isFound = selected.some((element) => {
  //     if (element === id) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });
  //   if (isFound) {
  //     setSelected((state) => state.filter((idt) => idt != id));
  //   } else {
  //     setSelected((state) => [...state, id]);
  //   }
  // };
  const handelnavigation = (type, page) => {
    if (type === "return") {
      if (active > 1) {
        setActive((state) => state - 1);
      }
    }
    if (type === "frwrd") {
      if (active < pages.at(-1)) {
        setActive((state) => state + 1);
      }
    }
    if (type === "page") {
      setActive(page);
    }
  };
  const handelOptions = (id) => {
    setActiveOption((state) => {
      if (state === id) {
        return "";
      }
      if (id === "") {
        return "";
      }
      return id;
    });
  };
useEffect(()=>{

  const searchHandler = () => {
    let fl = fournisseurs.filter((fournisseur) => {
      if (fournisseur?.firstname) {
        return fournisseur?.firstname
          .toLowerCase()
          .includes(filterActive.toLowerCase());
      }
      if (fournisseur?.lastname) {
        return fournisseur?.lastname
          .toLowerCase()
          .includes(filterActive.toLowerCase());
      }
      return false;
    })
    setFiltred(fl)
    setPages(pager(fl.length,perPage))
    setActive(1)
  };

  if(filterActive){searchHandler()}



},[filterActive,fournisseurs])

  const handeldelete = async() => {
    try {
      let url = `/fournisseurs/${selected[0]}`;
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        checkfor: "fournisseurs",
        pm_auth: `${Cookies.get("auth_token")}`,
      };
      const res=await instance
        .delete(url, { headers })
          if (res?.data?.deleted?.id === selected[0]) {
            notify(
              "success",
              `${res.data.deleted.firstname} supprimé(s) avec succé !`
            );
            setFournissseurs((state) =>
              state.filter((fournisseur) => fournisseur.id !== selected[0])
            );
            setDeleteWindow(false);
            setSelected([]);
          } else {
            notify(
              "error",
              "Aucun fournisseurs n'est supprimé, debug nécessaire !"
            );
          }
 
    } catch (err) {
      notify("error", err.message);
    }
  };
  return (
    <div className="page_container">
      <Toaster
        toastOptions={{
          style: {
            background: "transparent",
            border: "none",
            boxShadow: "none",
            color: "#ffffff",
          },
        }}
      />
      {deleteWindow ? (
        <div className="overlay">
          <div className="delete-window">
            <img src={cancelBlack} alt="cancel" onClick={handeldeletewindow} />
            <p>Voulez-vous supprimer ce fournisseur ?</p>
            <p>
              {selected?.length == 0
                ? "Aucun Fournisseurs n'est selectioné"
                : selected?.length === 1
                ? fournisseurs.filter(
                    (fournisseur) => fournisseur.id === selected[0]
                  )[0].firstname
                : `${selected?.length} fournisseurs sélectionnés`}
            </p>
            <div className="btns">
              <div
                className="delete"
                onClick={() => {
                  if (selected?.length === 0) {
                    notify(
                      "error",
                      "Vous devez selectioné au moin un fournisseur à supprimer !"
                    );
                    return;
                  }
                  handeldelete();
                }}
              >
                <img src={cancelWhite} alt="delete" /> <p>Supprimer</p>
              </div>
              <div className="cancel" onClick={handeldeletewindow}>
                <img src={arrowIcon} alt="cancel" /> <p>Annuler</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="fournisseurs_list">
        <div className="p1">
          <div className="container c1">
            <p>Fournisseurs</p>
            <p className="num">
              {fournisseurs?.length ? fournisseurs.length : "0"}
            </p>
          </div>
          <div className="container c2">
            <p>Total Credit</p>
            <p className="num">
              {credit
                ? credit.toLocaleString("en-US", {
                    style: "currency",
                    currency: "DZD",
                  })
                : "0"}
            </p>
          </div>
        </div>
        {/* <div className="p1">
                    <div className="btn"><p>+Ajouter</p></div>
                    <div className="bih_holder "></div>
                    <div className="search_bar">
                        <img src={search} alt="search" />
                        <input name="search" placeholder="Recherche d’un fournisseur" type="text" />
                    </div>
                    <div className="little_holder"></div>
                    <div className="little_holder"></div>
                    <div className="little_holder"></div>

                </div> */}
        <div className="p2">
          <div className="hr">
            <p>Fournisseurs</p>
            <p className="faded">Liste Des Fournisseurs</p>
          </div>
          <div className="searchsse">
            <input
              placeholder="Search"
              onChange={(event) => {
                setFilterActive(event.target.value);
              }}
            />
            <img src={searchIcon} alt="search" />
          </div>
          <div className="element" onClick={handeldeletewindow}>
            <img src={deleteIcon} alt="delete" />
            <p>Suppr</p>
          </div>
          
          <div className="btn-modifier" onClick={handeledit}>
            <img src={editIcon} alt="change" />
            <p>Modifier</p>
          </div>
          <div
            className="btn"
            onClick={() => {
              window.location.href = "/addFournisseur";
            }}
          >
            <img src={moreIcon} alt="more" />
            <p>Ajouter</p>
          </div>
        </div>
        <div className="p3">
          <div className=" table">
            <table>
              <thead>
                <tr style={{ borderTop: "1px solid #EAECF0 " }}>
                  <th className="th1" style={{ width: "1rem" }}>
                    <div className="th1d">
                      <img src={minusIcon} alt="minus" />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Nom et Prénom </p> <img style={{cursor : "pointer"}} src={sortModifier==="desc"?arrowUpIcon:downIcon} alt="down" onClick={()=>{handelSort("name")}} />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Derniere Transaction </p>{" "}
                      <img style={{cursor : "pointer"}} src={sortModifier==="desc"?arrowUpIcon:downIcon} alt="down" onClick={()=>{handelSort("date")}} />
                    </div>
                  </th>
                  <th>
                    <div className="th2">
                      <p>Crédit </p> <img style={{cursor : "pointer"}} src={sortModifier==="desc"?arrowUpIcon:downIcon} alt="down"  />
                    </div>
                  </th>
                  <th>
                    <div className="th3"></div>
                  </th>
                </tr>
              </thead>
              {fournisseurs?.length ? (
                <tbody>
                  {filterActive
                    ? filtred
                        .slice((active - 1) * 11, active * 11)
                        .map((fournisseur, index) => {
                          let credit = 0;
                          if (fournisseur?.transactions) {
                            credit = fournisseur.transactions.reduce(
                              (acc, transactions) => {
                                acc +=
                                  parseInt(transactions.totale) -
                                  parseInt(transactions.paye);
                                return acc;
                              },
                              0
                            );
                          }
                          return (
                            <tr
                              key={index}
                              style={{ borderTop: "1px solid #EAECF0 " }}
                            >
                              <td className="td1" style={{ width: "1rem" }}>
                                <div className="td1d">
                                  <div
                                    className={`checkBox ${
                                      selected.indexOf(fournisseur.id) >= 0
                                        ? "checked"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      handelselect(fournisseur.id);
                                    }}
                                  ></div>
                                </div>
                              </td>
                              <td>
                                <div className="td2">
                                  <p>{fournisseur.firstname} </p>{" "}
                                </div>
                              </td>
                              <td>
                                <div className="td2 lite">
                                  <p>
                                    {fournisseur?.transactions[0]
                                      ? `${fournisseur.transactions[0].createdAt.split("T")[0]}`
                                      : "Aucune Transaction"}{" "}
                                  </p>{" "}
                                </div>
                              </td>
                              <td>
                                <div className="td2 lite">
                                  <p>
                                    {credit.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "DZD",
                                    })}{" "}
                                  </p>{" "}
                                </div>
                              </td>
                              <td>
                                <div
                                  className="td3 clicked"
                                  onClick={() => {
                                    handelOptions(fournisseur.id);
                                  }}
                                >
                                  <img
                                    className="clicked"
                                    src={options}
                                    alt="options"
                                  />
                                  <div
                                    className="openProfile"
                                    style={
                                      activeOption === fournisseur.id
                                        ? {
                                            animation:
                                              "slideDown 0.5s ease-out",
                                            backgroundColor: "white",
                                          }
                                        : { display: "none" }
                                    }
                                  >
                                    <div className="wrapper">
                                      
                                      <p>Open Profile</p>
                                      <img src={linkIcon} alt="profile" />
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                    : fournisseurs
                        .slice((active - 1) * 11, active * 11)
                        .map((fournisseur, index) => {
                          let credit = 0;
                          if (fournisseur?.transactions) {
                            credit = fournisseur.transactions.reduce(
                              (acc, transactions) => {
                                acc += transactions.totale - transactions.paye;
                                return acc;
                              },
                              0
                            );
                          }
                          return (
                            <tr
                              key={index}
                              style={{ borderTop: "1px solid #EAECF0 " }}
                            >
                              <td className="td1" style={{ width: "1rem" }}>
                                <div className="td1d">
                                  <div
                                    className={`checkBox ${
                                      selected.indexOf(fournisseur.id) >= 0
                                        ? "checked"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      handelselect(fournisseur.id);
                                    }}
                                  ></div>
                                </div>
                              </td>
                              <td>
                                <div className="td2">
                                  <p>{fournisseur.firstname} </p>{" "}
                                </div>
                              </td>
                              <td>
                                <div className="td2 lite">
                                  <p>
                                    {fournisseur?.transactions[0]
                                      ? `${fournisseur.transactions[0].createdAt.split("T")[0]}`
                                      : "Aucune Transaction"}{" "}
                                  </p>{" "}
                                </div>
                              </td>
                              <td>
                                <div className="td2 lite">
                                  <p>
                                    {credit.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "DZD",
                                    })}{" "}
                                  </p>{" "}
                                </div>
                              </td>
                              <td>
                                <div
                                  className="td3 clicked"
                                  onClick={() => {
                                    handelOptions(fournisseur.id);
                                  }}
                                >
                                  <img
                                    className="clicked"
                                    src={options}
                                    alt="options"
                                  />
                                  <div
                                    className="openProfile"
                                    onClick={() => {
                                      window.location.href = `/fournisseur/${fournisseur.id}`;
                                    }}
                                    style={
                                      activeOption === fournisseur.id
                                        ? {
                                            animation:
                                              "slideDown 0.5s ease-out",
                                            backgroundColor: "white",
                                          }
                                        : { display: "none" }
                                    }
                                  >
                                 <div className="wrapper">
                                      
                                      <p>Open Profile</p>
                                      <img src={linkIcon} alt="profile" />
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                  {/* <tr style={{ borderTop: "1px solid #EAECF0 " }}>
                                    <td className="td1" style={{ width: "1rem" }}><div><input type="checkbox" /></div></td>
                                    <td><div className="td2"><p>Nom et Prénom </p> </div></td>
                                    <td><div className="td2 lite"><p>12/05/2023 </p> </div></td>
                                    <td><div className="td2 lite"><p>1000,00 DA </p> </div></td>
                                    <td><div className="td3" onClick={handelOptions}><img src={options} alt="options" /><div className="openProfile" style={activeOption ? { animation: "slideDown 0.5s ease-out" } : { display: "none" }} ><p>Oper Profile</p><img src={linkIcon} alt="profile" /></div></div></td>
                                </tr> */}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={4}>
                      <div className="no-fr">
                        <p>
                          Aucun Fournisseurs n'est retrouver ! veuillez Ajouter
                          Un Fournisseur !
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          <div
            className="p-n"
            style={{ width: "100%", margin: "auto auto 1rem auto" }}
          >
            <div className="pagination">
              <div
                className="nav"
                onClick={() => {
                  handelnavigation("return");
                }}
                style={
                  active === 1 || pages.length === 0
                    ? {
                        backgroundColor: "#F5F5F5",
                        opacity: ".6",
                        cursor: "default",
                      }
                    : {}
                }
              >
                <img src={rtrn} alt="return" />
              </div>
              {active >= 3 && pages.length > 3 ? (
                <div className="ect">...</div>
              ) : (
                ""
              )}
              {pages.length > 3
                ? active < 3 && active > 0
                  ? pages?.slice(0, 3).map((page, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            handelnavigation("page", page);
                          }}
                          className="page"
                          style={
                            active === page
                              ? { backgroundColor: "#100DB1" }
                              : {}
                          }
                        >
                          {" "}
                          <p style={page === active ? { color: "white" } : {}}>
                            {page}
                          </p>
                        </div>
                      );
                    })
                  : active > pages.length - 3
                  ? pages
                      ?.slice(pages.length - 3, pages.length)
                      .map((page, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              handelnavigation("page", page);
                            }}
                            className="page"
                            style={
                              active === page
                                ? { backgroundColor: "#100DB1" }
                                : {}
                            }
                          >
                            {" "}
                            <p
                              style={page === active ? { color: "white" } : {}}
                            >
                              {page}
                            </p>
                          </div>
                        );
                      })
                  : pages?.slice(active - 2, active + 2).map((page, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            handelnavigation("page", page);
                          }}
                          className="page"
                          style={
                            active === page
                              ? { backgroundColor: "#100DB1" }
                              : {}
                          }
                        >
                          {" "}
                          <p style={page === active ? { color: "white" } : {}}>
                            {page}
                          </p>
                        </div>
                      );
                    })
                : pages?.map((page, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          handelnavigation("page", page);
                        }}
                        className="page"
                        style={
                          active === page ? { backgroundColor: "#100DB1" } : {}
                        }
                      >
                        {" "}
                        <p style={page === active ? { color: "white" } : {}}>
                          {page}
                        </p>
                      </div>
                    );
                  })}

              {active < pages.length - 2 && pages.length > 3 ? (
                <div className="ect">...</div>
              ) : (
                ""
              )}
              <div
                className="nav"
                onClick={() => {
                  handelnavigation("frwrd");
                }}
                style={
                  active === pages.at(-1) || pages.length === 0
                    ? {
                        backgroundColor: " #F5F5F5",
                        opacity: ".6",
                        cursor: "default",
                      }
                    : {}
                }
              >
                <img src={frwrd} alt="next" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
