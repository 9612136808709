import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./css/global.css";
import { Toaster } from 'react-hot-toast';
import { VetoProvider } from "./api/Context";
import { Provider } from 'react-redux';
import store, { persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <VetoProvider>
          <App />
          <div style={{ marginBottom: '20px' }}>
            <Toaster position="bottom-center" />
          </div>
        </VetoProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

reportWebVitals();
