import React, { FunctionComponent, useRef, useEffect, useState } from "react";
import "../../css/AddAnimal.css";
import { AddAnimalapi,notify } from "../../api/api";
import { format } from 'date-fns-tz';
interface AddAnimalProps {
  onCloseModal: () => void;
  selectedClientId: number | null;

}

const AddAnimal: FunctionComponent<AddAnimalProps> = ({ onCloseModal, selectedClientId}) => {
  
  const modalRef = useRef<HTMLDivElement>(null);
  const [animalData, setAnimalData] = useState({
    name: "",
    race: "",
    robe: "",
    age: '',
  });
  const [agedate, setagedate]= useState(animalData.age);
  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onCloseModal();
    }
  };

  const handleButtonClick = async () => {
    const algeriaTimeZone = 'Africa/Algiers';
    const agee = agedate?new Date(agedate):null;
    const formattedage = agee?format(agee, "yyyy-MM-dd'T'HH:mm", { timeZone: algeriaTimeZone }):null;
   console.log(formattedage)
    animalData.age=formattedage?formattedage:"";
    try {
 
      console.log(animalData)
      if (!animalData.name || !animalData.race || !animalData.robe || !animalData.age) {
        console.error('Veuillez remplir tous les champs correctement.');
        notify('error','Veuillez remplir tous les champs correctement.')
        return;
      }
 
      await AddAnimalapi(selectedClientId, animalData);
    } catch (error:any) {
      console.error('Erreur lors de l\'ajout de l\'animal:', error.message);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAnimalData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCloseModal]);

  return (
    <div className="addanimall" ref={modalRef}>
      <button type="button" className="but" onClick={handleButtonClick}>
        <div className="backgroun" />
        <div className="suivan">Suivant</div>
      </button>
      <input
        className="inputt1"
        placeholder="Nom"
        type="text"
        name="name"
        value={animalData.name}
        onChange={handleChange}
      />
      <input
        className="inputt2"
        placeholder="Race"
        type="text"
        name="race"
        value={animalData.race}
        onChange={handleChange}
      />
      <input
        className="inputt3"
        placeholder="Robe"
        type="text"
        name="robe"
        value={animalData.robe}
        onChange={handleChange}
      />
      <input
        className="inputt6"
        placeholder="Âge"
        type="datetime-local"  
        name="age"
        value={agedate}
        onChange={(e) =>setagedate(e.target.value) }
      />
      <div className="hed">
        <div className="dos">
          <div className="dos-child" />
          <div className="dos-item" />
        </div>
        <div className="ajouter-Animall">Ajouter Animal</div>
      </div>
    </div>
  );
};

export default AddAnimal;
