import React, { FunctionComponent, useState } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import "../../css/MotifTable.css"
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Edithospitalisation from './Edithospitalisation';
import EditIcon from '@material-ui/icons/Edit';
import { Deletehospitalisation } from '../../api/api';

interface Hospitalisation {
  data: any;
}

const MotifTable: FunctionComponent<Hospitalisation> = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const Deletehospitalisations = async (HospitalisationId: any) => {
    console.log(HospitalisationId);
    console.log(data);
    const reponse = await Deletehospitalisation(HospitalisationId);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleHospitalisationClick = (row: any) => {
    setSelectedRow(row);
    setIsModalOpen(true);
  };

  return (
    <div className="Motiftable">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Motif</TableCell>
            <TableCell>Conduite</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: any) => (
            <TableRow key={row.id}>
              <TableCell>{row.motif}</TableCell>
              <TableCell>{row.conduiteATenir}</TableCell>
              <TableCell>
                <div className="action-buttons">
                  <IconButton aria-label="delete" onClick={() => Deletehospitalisations(row.id)}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton aria-label="edit" onClick={() => handleHospitalisationClick(row)}>
                    <EditIcon />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isModalOpen && <Edithospitalisation onCloseModal={handleCloseModal} clickedhos={selectedRow} />}
    </div>
  );
};

export default MotifTable;
