import React from 'react';
import "../../css/HospitalisationTable.css"
import { FunctionComponent } from "react";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableSortLabel,
} from '@material-ui/core';

interface Hospitalisation{
  data:any
}
const HospitalisationTable: FunctionComponent<Hospitalisation> = ({ data })  => {
  
  return (
    <div className="HospitalisationTable">
      <Table>
      <TableHead>
        <TableRow>
        <TableCell >
            <TableSortLabel >
              Client
            </TableSortLabel>
          </TableCell>
          <TableCell >
            <TableSortLabel >
              Animal
            </TableSortLabel>
          </TableCell>
          <TableCell >
            <TableSortLabel>
            date d’entrée
            </TableSortLabel>
          </TableCell>
          <TableCell >
            <TableSortLabel >
            date de sortie
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {data.map((row:any) => (
            <TableRow
              key={row.id}
            >
              <TableCell>{row.client.firstname}{" "}{row.client.lastname}</TableCell>
              <TableCell>{row.animal.name}</TableCell>
              <TableCell>{   new Date(
            row.dateEntree
          ).toISOString().split("T")[0]}</TableCell>
              <TableCell>{   new Date(
          row.dateSortie
          ).toISOString().split("T")[0]}</TableCell>
          
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </div>
  );
}

export default HospitalisationTable ;