import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import "../../css/caissetable.css";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';

interface caisseprops {
  onCloseModal: () => void;
  ventes: any;
}

const CaisseTable: FunctionComponent<caisseprops> = ({ onCloseModal, ventes }) => {
  if (!ventes?.ventes || ventes.ventes.length === 0) {
    return (
      <div className='alltab'>
        <div className="caissetable">
          <div className='titre-table'><text>les derniers ventes</text></div>
          <Table>
            <TableHead className='header'>
              <TableRow>
                <TableCell className='cellcaisse'>
                  <TableSortLabel>
                    Id
                  </TableSortLabel>
                </TableCell>
                <TableCell className='cellcaisse'>
                  <TableSortLabel>
                    Client
                  </TableSortLabel>
                </TableCell>
                <TableCell className='cellcaisse'>
                  <TableSortLabel>
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell className='cellcaisse'>
                  <TableSortLabel>
                    Montant
                  </TableSortLabel>
                </TableCell>
                <TableCell className='cellcaisse'>
                  <TableSortLabel>
                    Reste
                  </TableSortLabel>
                </TableCell>
                <TableCell className='cellcaisse'>
                  <TableSortLabel>
                    Etat
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={6} className="no-sales-message">
                  Aucune vente disponible.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }

  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (!target.closest(".alltab")) {
      onCloseModal();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCloseModal]);

  return (
    <div className='alltab'>
      <div className="caissetable">
        <div className='titre-table'><text>les derniers ventes</text></div>
        <Table>
          <TableHead className='header'>
            <TableRow>
              <TableCell className='cellcaisse'>
                <TableSortLabel>
                  Id
                </TableSortLabel>
              </TableCell>
              <TableCell className='cellcaisse'>
                <TableSortLabel>
                  Client
                </TableSortLabel>
              </TableCell>
              <TableCell className='cellcaisse'>
                <TableSortLabel>
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell className='cellcaisse'>
                <TableSortLabel>
                  Montant
                </TableSortLabel>
              </TableCell>
              <TableCell className='cellcaisse'>
                <TableSortLabel>
                  Reste
                </TableSortLabel>
              </TableCell>
              <TableCell className='cellcaisse'>
                <TableSortLabel>
                  Etat
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ventes.ventes.map((user: any) => (
              <TableRow key={user.id}>
                <TableCell className="celltableachat">{user.id ?? '-'}</TableCell>
                <TableCell className="celltableachat">
                  {`${user.client?.firstname} ${user.client?.lastname}` ?? '-'}
                </TableCell>
                <TableCell className="celltableachat">{user.createdAt ?? '-'}</TableCell>
                <TableCell className="celltableachat">{user.prix ?? '-'}</TableCell>
                <TableCell className="celltableachat">{(user.prix - user.payed) ?? '-'}</TableCell>
                <TableCell className="celltableachat">
                  {user.prix === user.payed ? 'fermé' : 'ouverte'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default CaisseTable;
