import React from 'react';
import "../../css/rendezvoussection.css";
import { FunctionComponent, useEffect,useState } from "react";
import TableRendezVous from '../../components/RendezVous/TableRendezVous';
import Agenda from '../../components/RendezVous/Agenda';



const Rendezvous : FunctionComponent = () => {
  
  return (
    <div className='rendezvous' >
    <div className="title8">
        <img className="logo" alt="" src="/logo-m-1.svg" />
        <b className="rdvtext">Rendez-Vous</b>
        <div className='rdv-tab'> 
          <TableRendezVous/>
        </div>
        <div className='agenda-part'>
          <Agenda></Agenda>
        </div>
      </div>
      
    </div>
  );
};

export default Rendezvous ;