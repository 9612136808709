import React, { FunctionComponent, useState,useEffect } from 'react';
import '../../css/produittable.css';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteProduct } from '../../api/api';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
} from '@material-ui/core';
import { TablePagination } from '@mui/base/TablePagination';
   
interface ProduitTableProps {
  products: any;
}

const ProduitTable: FunctionComponent<ProduitTableProps> = ({ products }) => {
  const Deleteproduct = async (productId: any) => {
    console.log(productId);
    const reponse = await DeleteProduct(productId);
  };

    return (
        <div className="produitstable">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className='cellprod'>
                <TableSortLabel>
                    ID
                  </TableSortLabel>
                </TableCell>
                <TableCell className='cellprod'>
                <TableSortLabel>
                    Produit
                    </TableSortLabel>
                </TableCell>
                <TableCell className='cellprod'>
                <TableSortLabel>
                    Qte
                    </TableSortLabel>
                </TableCell>
                <TableCell className='cellprod'>
                 <TableSortLabel>
                Date de peremption
                 </TableSortLabel>
                </TableCell>
                
                <TableCell className='cellprod'>
                 <TableSortLabel>
                    Prix de vente
                 </TableSortLabel>
                </TableCell>
                   
                <TableCell className='cellprod'>
                 <TableSortLabel>
                    Action
                 </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
          {products ? (
            products.map((product: any) => (
              <TableRow key={product.id}>
                <TableCell className='cellprod'>{product.id}</TableCell>
                <TableCell className='cellprod'>{product.name}</TableCell>
                <TableCell className='cellprod'>{product.qtDispo}</TableCell>
                <TableCell className='cellprod'>{new Date(
                                          product.dateDePeremption
                                        ).toISOString().split("T")[0]}</TableCell>
                <TableCell className='cellprod'>{product.prix}</TableCell>
                <TableCell>
                <div className="action-buttons">
                  <IconButton aria-label="delete" 
                  onClick={() => Deleteproduct(product.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  {/* <IconButton aria-label="edit" >
                    <EditIcon />
                  </IconButton> */}
                  </div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell  className='cellprod'>
                Aucun produit disponible.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
            </Table>
            
            </div>
            );
};
export default ProduitTable;