import React from 'react';

import { FunctionComponent } from "react";
import "../../css/Pic.css";
const Pic: FunctionComponent = () => {
  return (
    <div className="left-blue">
      <img className="logo-m-1" alt="" src="/logo-m-1.svg" />
      <div className="bienvenu-au-vetapp-wrapper">
        <b className="bienvenu-au-vetapp">Bienvenu au VetApp</b>
      </div>
      <div className="plan-your-blog-post-by-choosin-wrapper">
        <div className="plan-your-blog-container">
          <p className="plan-your-blog">
          Soin expert, compassion constante VetApp, 
          </p>
          <p className="plan-your-blog">votre partenaire bien-être pour les animaux</p>
        </div>
      </div>
      <img className="s-confirmation-icon" alt="" src="/s-confirmation.svg" />
    </div>
  );
};

export default Pic;
