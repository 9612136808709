import "./fournisseurProfileTransactions.css";
import {
  downIcon,
  linkIcon,
  options,
  plusIcon,
  cancelBlack,
  arrowIcon,
  cancelWhite,
  arrowUpIcon,
} from "../../assets/icons";
import { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import React from "react";
import { instance,notify } from "../../api/api";
import Cookies from "js-cookie";

export default function FournisseurProfileTransactions({
  inTotal,
  fournisseur,
  handelintotal,
  handeltransactions,
  transactions,
  handelvalidator,
  validator
}) {
  const [activeOption, setActiveOption] = useState("");
  const [overlay, setOverlay] = useState(false);
  const [activeStatus, setActiveStatus] = useState("not-payed");
  const [payement, setPayement] = useState(0);
  const [active, setActive] = useState({ id: "", totale: 0, payed: 0 });
  const selected = [];
  const [sortType,setSortType] = useState("id")
  const [sortModifier,setSortModifier] = useState("asc")


 const  handelSort =(type)=>{
        let newSortModifier;
        if(sortModifier==="asc"){newSortModifier = "desc"}else{newSortModifier ="asc"}
        if(type ==="id"){
          if(sortType !== "id") {setSortType("id")}
          if(newSortModifier==="asc"){
            
          let newTL = [...transactions].sort((a,b)=>{
            let nameA = parseInt(a.id)
            let nameB = parseInt(b.id)
            return nameA -nameB
          })
          handeltransactions(newTL)
          setSortModifier(newSortModifier)
          }
          if(newSortModifier==="desc"){
            let newTL = [...transactions].sort((a,b)=>{
              let nameA = parseInt(a.id)
              let nameB = parseInt(b.id)

              return nameB-nameA
            })

            handeltransactions(newTL)
            setSortModifier(newSortModifier)
            }
        }
        if(type ==="reste"){
          if(sortType !== "reste") {setSortType("reste")}
          if(newSortModifier==="asc"){
            
          let newTL = [...transactions].sort((a,b)=>{
            let nameA = parseInt(a.totale)-parseInt(a.paye)
            let nameB = parseInt(b.totale)-parseInt(b.paye)
            return nameA -nameB
          })
          handeltransactions(newTL)
          setSortModifier(newSortModifier)
          }
          if(newSortModifier==="desc"){
            let newTL = [...transactions].sort((a,b)=>{
              let nameA = parseInt(a.totale)-parseInt(a.paye)
              let nameB = parseInt(b.totale)-parseInt(b.paye)

              return nameB-nameA
            })

            handeltransactions(newTL)
            setSortModifier(newSortModifier)
            }
        }
        if(type ==="date"){
          if(sortType !== "date") {setSortType("date")}
          if(newSortModifier==="asc"){
          let newTL = transactions.sort((a,b)=>{
            var dateA = new Date(a.createdAt) 
            var dateB = new Date(b.createdAt) 
        
            if (dateA === null && dateB === null) {
              return 0; // If both dates are null, maintain the order
            } else if (dateA === null) {
              return 1; // Place null values at the end
            } else if (dateB === null) {
              return -1; // Place null values at the end
            } else {
              return dateA - dateB; // Compare the valid dates
            }
          })
          handeltransactions(newTL)
          setSortModifier(newSortModifier)
          }
          if(newSortModifier==="desc"){
            let newTL = transactions
            .sort((a,b)=>{
              var dateA = new Date(a.createdAt) 
              var dateB = new Date(b.createdAt) 
          
              if (dateA === null && dateB === null) {
                return 0; // If both dates are null, maintain the order
              } else if (dateB === null) {
                return 1; // Place null values at the end
              } else if (dateA === null) {
                return -1; // Place null values at the end
              } else {
                return dateB - dateA; // Compare the valid dates
              }})
              handeltransactions(newTL)
            setSortModifier(newSortModifier)
            }
        }


 }


  const handelOverlay = () => {
    setOverlay((state) => !state);
  };
  useEffect(() => {
   if(transactions?.length){
    let totale = transactions.reduce((acc, transaction) => {
      acc += transaction.totale;
      return acc;
    }, 0);
    let paye = transactions.reduce((acc, transaction) => {
      acc += transaction.paye;
      return acc;
    }, 0);
    let credit = totale - paye;
    handelintotal({ credit: credit, totale: totale });
   }
  }, [transactions]);
  const handelpayement = (event) => {
    setPayement(event.target.value);
  };
  const handelAddPayement = async (event) => {
    event.preventDefault();
    let url = `/transaction/${active.id}`;

    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      pm_auth: `${Cookies.get("auth_token")}`,
      checkfor: "fournisseurs",
    };
    let data = {
      paye: parseInt(active.payed) + parseInt(payement),
    };

    if (parseInt(payement) === 0 || active?.totale - active?.payed < payement) {
      notify("error", "Montant invalide, veuillez le corriger !");
      return;
    }
   try{
    const res=await instance
      .put(url, { data: data }, { headers })
        if (res?.data?.updated) {
          notify("success", "Payement Ajouté");
          handelvalidator(!validator)
          setOverlay(false);
        } else {
          notify("error", "Quelque chose s'est mal passé");
        }

        return;
      }
      catch(err){
        notify("error", err.message);
      };
    return;
  };

  const handelOptions = (id) => {
    setActiveOption((state) => {
      if (state === id) {
        return "";
      }
      if (id === "") {
        return "";
      }
      return id;
    });
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.classList.contains("clicked") ) {
        handelOptions("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [activeOption]);

  return (
    <div className="f-p-transactions">
      {overlay ? (
        <div className="overlay">
          <div className="add-payement-window">
            <img src={cancelBlack} alt="cancel" onClick={handelOverlay} />
            {activeStatus === "not-payed" ? (
              <div className={`amount `}>
                <p>Le montant :</p>{" "}
                <input
                  onChange={handelpayement}
                  name="payement"
                  type="number"
                />
                <p>.00 DZD</p>
              </div>
            ) : (
              <p style={{ color: "#FF5B5B", fontSize: " smaller" }}>
                Cette transaction est déja payée !
              </p>
            )}
            {parseInt(active.totale) - parseInt(active.payed) <
              parseInt(payement) ||
            (parseInt(payement) === 0 && activeStatus === "not-payed") ? (
              <p style={{ color: "#FF5B5B", fontSize: "smaller" }}>
                Un payement valide doit être entre 0 DZD et{" "}
                {parseInt(active.totale) - parseInt(active.payed)} DZD
              </p>
            ) : (
              ""
            )}
            <p className="p">{fournisseur.firstname}</p>
            <div className="btns">
              <div
                className="ajouterdoc"
                onClick={(event) => {
                  if (!active?.id) {
                    notify(
                      "error",
                      "Vous devez sélectionner à la moins une transaction à Modifier !"
                    );
                    return;
                  }
                  handelAddPayement(event);
                }}
              >
                <img src={cancelWhite} alt="delete" /> <p>Ajouter</p>
              </div>
              <div
                className="cancel"
                onClick={() => {
                  handelOverlay();
                  setActiveOption("");
                  setActiveStatus("not-payed");
                  setPayement(0);
                }}
              >
                <img src={arrowIcon} alt="cancel" /> <p>Annuler</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="options">
        <div className="option c1">
          <p>Total Achats :</p>
          <p className="num">{inTotal.totale.toLocaleString("en-DE")} DZD</p>
        </div>
        <div className="option c2">
          <p>Total Credit :</p>
          <p className="num">{inTotal.credit.toLocaleString("en-DE")} DZD</p>
        </div>
      </div>
      <div className="p2">
        <table>
          <thead>
            <tr
              style={{ borderTop: "1px solid #EAECF0 " }}
              className={"fixed "}
            >
              <th className={"f-p " + overlay ? "z-index-down" : ""}>
                <div className="th2">
                  <p>N°Bon </p> <img src={sortModifier==="desc"?arrowUpIcon:downIcon} alt="down" onClick={()=>{handelSort("id")}} />
                </div>
              </th>
              <th className={"f-p " + overlay ? "z-index-down" : ""}>
                <div className="th2">
                  <p>Date </p> <img src={sortModifier==="desc"?arrowUpIcon:downIcon} alt="down" onClick={()=>{handelSort("date")}} />
                </div>
              </th>
              <th className={"f-p " + overlay ? "z-index-down" : ""}>
                <div className="th2">
                  <p>Montant </p> <img src={sortModifier==="desc"?arrowUpIcon:downIcon} alt="down" />
                </div>
              </th>
              <th className={"f-p " + overlay ? "z-index-down" : ""}>
                <div className="th2">
                  <p>Reste </p> <img src={sortModifier==="desc"?arrowUpIcon:downIcon} alt="down" onClick={()=>{handelSort("reste")}} />
                </div>
              </th>
              <th className={"f-p " + overlay ? "z-index-down" : ""}>
                <div className="th2">
                  <p>Etat </p> <img src={sortModifier==="desc"?arrowUpIcon:downIcon} alt="down" />
                </div>
              </th>
              <th className={"f-p " + overlay ? "z-index-down" : ""}>
                <div className="th3"></div>
              </th>
            </tr>
          </thead>
          {transactions?.length ? (
            <tbody>
              {transactions
                ? transactions

                    .map((transaction, index) => {
                      let newId = transaction.id.toString()
                          while(newId.length < 6) newId = "0" + newId
                      let createdAt = new Date(transaction.createdAt);
                      return (
                        <tr key={index}>
                          <td>
                            <div className="td2">
                              <p>{newId} </p>
                            </div>
                          </td>
                          <td>
                            <div className="td2 lite">
                              <p>{createdAt.toLocaleDateString("fr")} </p>
                            </div>
                          </td>
                          <td>
                            <div className="td2 lite">
                              <p>
                                {transaction.totale.toLocaleString("en-DE")} DA{" "}
                              </p>{" "}
                            </div>
                          </td>
                          <td>
                            <div className="td2 lite">
                              <p>
                                {(
                                  transaction.totale - transaction.paye
                                ).toLocaleString("en-DE")}{" "}
                                DA{" "}
                              </p>{" "}
                            </div>
                          </td>
                          <td>
                            <div className="td2 lite">
                              {transaction.totale === transaction.paye ? (
                                <div className="tag-green">
                                  <span></span>
                                  <p className="tag-p">Payé</p>
                                </div>
                              ) : (
                                <div className="tag-gray">
                                  <span></span>
                                  <p className="tag-p">Non-payé</p>
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            <div
                              className="td3"
                              onClick={() => {
                                handelOptions(transaction.id);
                                setActiveStatus((state) => {
                                  if (transaction.paye === transaction.totale) {
                                    return "payed";
                                  }
                                  return "not-payed";
                                });
                                setActive({
                                  id: transaction.id,
                                  totale: transaction.totale,
                                  payed: transaction.paye,
                                });
                              }}
                            >
                              <img className="clicked" src={options} alt="options" />
                              <div
                                onClick={() => {

                                  if (
                                    parseInt(transaction.paye) >=
                                    parseInt(transaction.totale)
                                  ) {
                                    notify("success", "Transaction dejà payée");
                                    return;
                                  }
                                  handelOverlay();
                                }}
                                className="add-payement"
                                style={
                                  activeOption === transaction.id
                                    ? { animation: "slideDown 0.5s ease-out" }
                                    : { display: "none" }
                                }
                              >
                                <p>Ajouter un payment</p>
                                <img src={plusIcon} alt="profile" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                : ""}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={6}>
                  <div className="no-fr">
                    <p>
                      Aucune transaction n'est retrouver ! veuillez Ajouter Une
                      transaction !
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}
