const initialAuthState = {
  auth: { authenticated: false, roles: "0000000000", user: {} },
};

function authReducer(state = initialAuthState, action) {
  if (action.type === "Authentication") {
    if (action?.payload) {
      let user = action.payload;
      return {
        auth: { authenticated: true, roles: user.accessAuth, user: user },
      };
    }
  }
  if (action.type === "Disconnect") {
      return {
        auth:initialAuthState,
    }
  }

  return state;
}

export default authReducer;
