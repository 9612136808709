import React, { FunctionComponent, useRef, useEffect, useState } from "react";
import "../../css/AddClient.css";
import { UpdateClientapi,notify } from '../../api/api';
export interface Data {
  id: number;
  client: string;
  phone: string;
  credit: number;
  derniereTransaction: string;
  animals: {id:number, name: string; race: string }[];
}
interface UpdateClientProps {
  onCloseModal: () => void;
  selectedClient:Data | null;
}

const UpdateClient: FunctionComponent<UpdateClientProps> = ({ onCloseModal, selectedClient }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  useEffect(() => {
    if (selectedClient) {
      setFirstName(selectedClient.client.split('-')[0]);
      setLastName(selectedClient.client.split('-')[1]);
      setPhone(selectedClient.phone);
    }
  }, [selectedClient]);
  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (
      modalRef.current &&
      !modalRef.current.contains(target) &&
      !target.closest(".addclientt") &&
      !target.closest(".inputt-1") &&
      !target.closest(".inputt-2") &&
      !target.closest(".inputt-3")
    ) {
      onCloseModal();
    }
  };

  const validatePhone = (value: string) => {
    const phoneRegex = /^(0[5-7])\d{8}$/;
    const isValid = phoneRegex.test(value);
    if(!isValid){
    notify('error', 'Le numéro de téléphone doit commencer par 05, 06 ou 07 et être suivi de 8 chiffres.');}
    return isValid;
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCloseModal]);
  const handleModifyClient = async () => {
    try {
      if (!firstName || !lastName || !validatePhone(phone)) {
        notify('');
        throw new Error('Veuillez remplir tous les champs correctement.');
      }
      const data = {
        id: selectedClient?.id,
        firstname: firstName,
        lastname:lastName,
        phone,
      };
      const response = await UpdateClientapi(data);
      return ;
    } catch (error: any) {
      console.error('Erreur lors de la mise à jour du client:', error.message);
    }
  };
  return (
    <div className="addclientt" ref={modalRef}>
      <button className="buttonn" onClick={handleModifyClient}>
        <div className="backgroundd" />
        <div className="suivantt">Suivant</div>
      </button>
      <input className="inputt-2" placeholder="Prénom" value={firstName}  type="text" onChange={(e) => setFirstName(e.target.value)} />
      <input className="inputt-3" placeholder="Téléphone" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
      <input className="inputt-1" placeholder="Nom" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
      <div className="headd">
        <div className="dotss">
          <div className="dotss-child" />
          <div className="dotss-item" />
        </div>
        <div className="ajouter-clientt">Modifier Client</div>
      </div>
    </div>
  );
};

export default UpdateClient;
