import React from 'react';
import { FunctionComponent, useEffect,useState } from "react";
import "../../css/Animalsection.css";
import RendezAnimal from '../../components/Animalupdate/Animalrend';
import BasicInfo from '../../components/Animalupdate/AnimalInfos';
const Animalupdate: FunctionComponent = () => {
  return (
    <div className="Animalsec" >
      <div className="titleAnimal">
        <img className="logoAnimal" alt="" src="/logo-m-1.svg" />
        <b className="cltextAnimal">Clients</b>
      </div>
       <BasicInfo></BasicInfo>  
       <RendezAnimal></RendezAnimal> 
    </div>
  );
};

export default Animalupdate;
