import React, { FunctionComponent, useRef, useEffect, useState } from "react";
import jsPDF from "jspdf";
import { useBarcode } from '@createnextapp/react-barcode';
import "../../css/AddClient.css";
interface ServiceDetailProps {
  onCloseModal: () => void;
  values: any;
}

const ServiceDetails: FunctionComponent<ServiceDetailProps> = ({ onCloseModal, values }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [pdfGenerated, setPdfGenerated] = useState("");
  const [Name, setName] = useState('');
  const [prix, setprix] = useState('');
const handlePrint = async () => {
  try {
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.setFontSize(18);
    pdf.text("Service Details", 75, 10);

    const serviceprintDate = new Date();
    const formattedDate = `${serviceprintDate.getFullYear()}-${(
      serviceprintDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${serviceprintDate.getDate().toString().padStart(2, "0")}`;
    const formattedTime = `${serviceprintDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${serviceprintDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${serviceprintDate
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;
    pdf.setFontSize(12);
    pdf.text(`Date: ${formattedDate} Heure: ${formattedTime}`, 10, 20);
    pdf.setFontSize(14);
    pdf.text(`Service: ${values.name}`, 10, 30);
    pdf.text(`Prix: ${values.prix}`, 10, 40);
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = inputRef.current.clientWidth+10;
    canvas.height = inputRef.current.clientHeight+10;
    const svgData = new XMLSerializer().serializeToString(inputRef.current);
    const svgImage = new Image();
    svgImage.src = "data:image/svg+xml;base64," + btoa(svgData);
    svgImage.onload = () => {
      context?.drawImage(svgImage, 0, 0);
      const barcodeDataURL = canvas.toDataURL("image/jpeg");
      pdf.addImage(barcodeDataURL, "JPEG", 10, 50, 100, 100);
      const pdfContent = pdf.output("datauristring");
      setPdfGenerated(pdfContent);
      const printWindow = window.open("", "_blank");
      printWindow?.document.write('<html><head><title>Impression des details</title></head><body>');
      printWindow?.document.write(`<iframe width='100%' height='100%' src='${pdfContent}'></iframe>`);
      printWindow?.document.write('</body></html>');
      printWindow?.document.addEventListener('load', () => {
        printWindow?.print()
        printWindow?.close();
      });
    };
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};
  const { inputRef } = useBarcode({
    value: values.barCode,
    options: {
      background: '#ffffff',
      fontSize: 20,
      margin: 30,
      fontOptions: "bold",
      width: 1,
      height: 70
    }
  });

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (
      modalRef.current &&
      !modalRef.current.contains(target) &&
      !target.closest(".addclientt") &&
      !target.closest(".inputt-1") &&
      !target.closest(".inputt-2") &&
      !target.closest(".inputt-3")
    ) {
      onCloseModal();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCloseModal]);

  return (
    <div className="addclientt" ref={modalRef}>
      <button className="buttonn" onClick={handlePrint}>
        <div className="backgroundd" />
        <div className="suivantt">Print </div>
      </button>
      <svg className="inputt-2" ref={inputRef} />
      <input
        className="inputt-3"
        placeholder="Prix"
        value={values.prix}
        type="text"
        onChange={(e) => setprix(e.target.value)}
      />
      <input
        className="inputt-1"
        placeholder="Nom"
        type="text"
        value={values.name}
        onChange={(e) => setName(e.target.value)}
      />
      <div className="headd">
        <div className="dotss">
          <div className="dotss-child" />
          <div className="dotss-item" />
        </div>
        <div className="ajouter-clientt">Service Detail:</div>
      </div>
      <div>
      </div>
    </div>
  );
};

export default ServiceDetails;
