import React, { FunctionComponent, useEffect, useState } from "react";
import "../../css/Utilisateurs.css";
import Boxs from "../../components/Produit/Box";
import UtilisateursleTable from "../../components/Utilisateurs/UtilisateursTable";
import AjoutUtilisateurs from "../../components/Utilisateurs/AddUtilisateurs";
import SearchArea from "../../components/Clients/SearchArea";
import { GetAllUsers,  DeleteUser } from "../../api/api";
import { useVetoContext } from "../../api/Context";
import { useNavigate } from "react-router-dom";
const Utilisateurs: FunctionComponent = () => {
  const { notificationss } = useVetoContext();
  console.log(notificationss);
  const paymentNotifications = notificationss&&notificationss.data&&notificationss.data.users&&notificationss.data.users.payement?notificationss.data.users.payement:[];
  const assuranceNotifications =notificationss.data&& notificationss.data.users&&notificationss.data.users.assurance?notificationss.data.users.assurance:[];
  const totalNotifications = paymentNotifications.length + assuranceNotifications.length;

  const [user, setUser] = useState<any[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [searchText, setSearchText] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(user);
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsResponse = await GetAllUsers();
        console.log(clientsResponse.users);
        setUser(clientsResponse.users);
      } catch (error) {
        console.error("Error fetching Utilisateur:", error);
      }
    };

    fetchClients();
  }, []);
  useEffect(() => {
    if(user){

const filtered = user
  .map((user: { firstname: string; lastname: string }) => ({
    ...user,
    firstname: user.firstname.toLowerCase(),
    lastname: user.lastname.toLowerCase(),
  }))
  .filter((user: { firstname: string; lastname: string }) =>
    user.firstname.includes(searchText.toLowerCase()) ||
    user.lastname.includes(searchText.toLowerCase())
  );
setFilteredProducts(filtered);
    }
  }, [searchText, user]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate(); 
  const handleAjouterClick = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteClick = async () => {
    console.log(selectedUserId);
    if (selectedUserId) {
      const reponse = await  DeleteUser(selectedUserId);
    }
  };
  const handleModifierClick = () => {
    if (selectedUserId) {
      // Redirigez l'utilisateur vers la page UserProfile avec l'ID de l'utilisateur sélectionné
      navigate(`/utilisateurs/${selectedUserId}`);
    }
  };
  const handleSearch = (text: string) => {
    setSearchText(text);
  };
  const handleAnnuler = () => {
    setSearchText("");
  };
  return (
    <div className="home-utilisateurs anim">
      <div className="title">
        <img className="logo-m-1" alt="" src="/logo-m-1.svg" />
        <b className="bienvenu-au-vetapp">Utilisateurs</b>
      </div>

      <div className="card-utilisateursgenerale">
        <div className="box8-achats">
        <Boxs titlebox="Utilisateurs" quantitie={user ? user.length : 0} dzd="" />

        </div>
        <div className="box2-achats">
          <Boxs titlebox="Notificationss" quantitie={totalNotifications ?totalNotifications :0} dzd="" />
        </div>
      </div>

      <div className="card5-achats ">
        <div>
          <p className="paragraph1">Utilisateurs</p>
          <p className="paragraph2">Liste Des Utilisateurs</p>
        </div>
        <div className="searchachat">
          <SearchArea
            onSearch={handleSearch}
            onAnnuler={handleAnnuler}
          ></SearchArea>
        </div>
        <div className="buttons-Utilisateurs">
          <button className="Modifierbutton " onClick={handleModifierClick}>Modifier</button>

          <button className="Cloturerbutton " onClick={handleAjouterClick}>
            Ajouter
          </button>
          {isModalOpen && <AjoutUtilisateurs onCloseModal={handleCloseModal} />}

          <button className="Retraitbutton" onClick={handleDeleteClick}>
            Supprimer
          </button>
        </div>
      </div>
      <div className="achattable">
        {Array.isArray(user) && user.length > 0 ? (
          <UtilisateursleTable
          users={filteredProducts}
          selectedUserId={selectedUserId}
          setSelectedUserId={setSelectedUserId}
        ></UtilisateursleTable>
        ) : (
          <p>No users found</p>
        )}
      </div>
    </div>
  );
};

export default Utilisateurs;
