import axios from "axios";
import toast from "react-hot-toast";
import Cookies from "js-cookie";

const baseURL = "https://a.logikabox.com:4000/api";
const commonHeaders = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

 export const instance = axios.create({
  baseURL,
  headers: commonHeaders,
});

export const notify = (type, message) => {
  if (type === "success") {
    toast.success(message);
  } else if (type === "error") {
    toast.error(message);
  }
};
const checkAuth = async (user, dispatch) => {
  if (!user) {
    notify("err", "Pas de nom d'utilisateur !");
    return;
  }
  try {
    let type = "pos";
    let url = "/caisse/isAuthorized/";
    let data = { type: type, user: user };
    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      pm_auth: `${Cookies.get("auth_token")}`,
      checkfor: "notification",
    };
    const res = instance.post(url, { data }, { headers });
    if (type === "pos" && res?.data?.auth) {
      dispatch({ type: "setCaisse", payload: res?.data.lastCaisse });
      return;
    }
    dispatch({ type: "setCaisse", payload: {} });
  } catch (err) {
    notify("err", err.message);
  }
};
export const GetAllCaisse = async () => {
  let url = "/caisse";
let headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  pm_auth: `${Cookies.get("auth_token")}`,
  checkfor: "produit",
};
  try {
    const response = await instance.get(url,{headers});
    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Invalid response from the server. Check the server logs for details."
      );
    }
  } catch (error) {
    notify("error", "Error fetching clients", +error.message);
    throw error;
  }
};
const redirect = (roles) => {
  let routes = [
    "Home",
    "Clients",
    "Animal",
    "Produit",
    "Ventes",
    "caisse",
    "RendezVous",
    "Hospitalisation",
    "Achats",
    "Pointdevente",
    "utilisateurs",
    "CaisseGenerale",
  ];
  if (roles === "000000000000") {
    window.location.href = "/";
  } else {
    window.location.href = `/${routes[roles.indexOf("1")]}`;
  }
};
export const login = async (email, password, dispatch) => {
  try {
    const url = "/users/login";
    const body = { email, password };
    const res = await instance.post(url, body);

    if (res.data?.user) {
      notify("success", "Connecté avec succès");
      let { user, token } = res.data;
      Cookies.set("auth_token", token);
      sessionStorage.setItem("isLoggedIn", "true");
      dispatch({ type: "Authentication", payload: user });
      if (user) {
        checkAuth(user, dispatch);
        setTimeout(() => {
          redirect(user.accessAuth);
        }, 2000);
      }
      return user;
    } else {
      notify("err", "Informations d’identification erronées!");
      return;
    }
  } catch (error) {
    notify("error", "An error occurred during login");
    throw error;
  }
};

//client
export const GetAllClient = async () => {
  try {
    const url = "/Client";
    const response = await instance.get(url);

    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Invalid response from the server. Check the server logs for details."
      );
    }
  } catch (error) {
    notify("error", "Error fetching clients", +error.message);
    throw error;
  }
};

export const GetClientCredit = async (id) => {
  try {
    const url = `/Client/credit/${id}`;
    const response = await instance.get(url);

    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Invalid response from the server. Check the server logs for details."
      );
    }
  } catch (error) {
    notify("error", "Error fetching client credit", +error.message);
    throw error;
  }
};
export const DeleteClient = async (id) => {
  try {
    const url = `/Client/${id}`;
    const response = await instance.delete(url);

    if (response.data.success) {
      notify("success", "Client deleted successfully");
      window.location.reload();
      return response.data;
    } else {
      throw new Error(
        "Invalid response from the server. Check the server logs for details."
      );
    }
  } catch (error) {
    notify("error", "Error deleting client", +error.message);
    throw error;
  }
};
export const UpdateClientapi = async (data) => {
  console.log({ data });
  try {
    const url = `/client/${data.id}`;
    const response = await instance.put(url, { data });
    if (response.data?.success) {
      notify("success", "Client mis à jour avec succès");
      window.location.reload();
      return response;
    }
  } catch (error) {
    notify("error", "Erreur lors de la mise à jour du client", +error.message);
    throw error;
  }
};
export const AddClientapi = async (firstName, lastName, phone) => {
  try {
    const url = "/Client";
    const body = {
      data: {
        firstname: firstName,
        lastname: lastName,
        phone: phone,
      },
    };
    const response = await instance.post(url, body);

    if (response.data?.client) {
      notify("success", "Client added successfully");
      // window.location.reload();
      return response.data;
    } else {
      throw new Error("Error adding client");
    }
  } catch (error) {
    notify("error", "Error adding client", +error.message);
    throw error;
  }
};
//animal:
export const DeleteAnimal = async (id) => {
  const url = `/animal/${id}`;
  const response = await instance.delete(url);
  try {
    if (response.data) {
      notify("success", "animal supprimé");
      window.location.reload();
      return response.data;
    } else {
      throw new Error(
        "Invalid response from the server. Check the server logs for details."
      );
    }
  } catch (error) {
    notify("error", "Error deleting client", +error.message);
    throw error;
  }
};
export const GetAnimalById = async (id) => {
  try {
    const url = `/animal/${id}`;
    const response = await instance.get(url);

    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Réponse invalide du serveur. Vérifiez les journaux du serveur pour plus de détails."
      );
    }
  } catch (error) {
    notify(
      "error",
      "Erreur lors de la récupération de lanimal par ID",
      error.message
    );
    throw error;
  }
};
export const GetAnimalByIdClientId = async (id,clientId) => {
  try {
    const url = `/animal/${clientId}/${id}`;
    const response = await instance.get(url);

    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Réponse invalide du serveur. Vérifiez les journaux du serveur pour plus de détails."
      );
    }
  } catch (error) {
    notify(
      "error",
      "Erreur lors de la récupération de lanimal par ID",
      error.message
    );
    throw error;
  }
};
export const GetAllAnimals = async (id) => {
  try {
    const url = `/animal`;
    const response = await instance.get(url);
    return response.data.animals;
  } catch (error) {
    notify("error", "Erreur lors de la récupération ", error.message);
    throw error;
  }
};
export const UpdateAnimalapi = async (data) => {
  try {
    const url = `/animal/${data.id}`;
    const requestBody = {
      data: {
        name: data.name,
        race: data.race,
        age: data.age,
        robe: data.robe,
        clientId: data.clientId,
      },
    };
    const response = await instance.put(url, requestBody);
    if (response.data?.success) {
      notify("success", "Animal mis à jour avec succès");
      window.location.reload();
      return response;
    }
  } catch (error) {
    notify("error", "Erreur lors de la mise à jour de lanimal", +error.message);
    throw error;
  }
};
export const AddAnimalapi = async (clientId, data) => {
  try {
    const url = `/animal/${clientId}`;
    const requestBody = {
      data: {
        name: data.name,
        race: data.race,
        age: data.age,
        robe: data.robe,
        clientId: clientId,
      },
    };
    console.log(requestBody);
    const reponse = await instance.post(url, requestBody);
    if (reponse.data?.animal) {
      notify("success", "Animal ajouté avec succès");
      window.location.reload();
      return reponse.data;
    }
  } catch (erreur) {
    notify("error", "Erreur lors de l'ajout de l'animal", +erreur.message);
    throw erreur;
  }
};
//serviceInformations
export const GetserviceInformationsById = async (id) => {
  console.log(id)
  try {
    const url = `/serviceInformations/${id}`;
    const response = await instance.get(url);
    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Réponse invalide du serveur. Vérifiez les journaux du serveur pour plus de détails."
      );
    }
  } catch (error) {
    notify("error", "Erreur lors de la récupération ", error.message);
    throw error;
  }
};
export const UpdateserviceInformationsById = async (data) => {
  console.log(data);
  try {
    const url = `/serviceInformations/${data.id}`;
    const response = await instance.put(url, { data });
    if (response.data) {
      notify("success", "Modification avec succès");
      window.location.reload();
      return response.data;
    } else {
      throw new Error(
        "Réponse invalide du serveur. Vérifiez les journaux du serveur pour plus de détails."
      );
    }
  } catch (error) {
    notify("error", "Erreur lors de la modification ", error.message);
    throw error;
  }
};
//hospitalisation services
export const GetAllhospitalisation = async () => {
  try {
    const url = "/grade";
    const response = await instance.get(url);
    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Invalid response from the server. Check the server logs for details."
      );
    }
  } catch (error) {
    notify("error", "Error fetching hospitalisation", +error.message);
    throw error;
  }
};
export const Addhospitalisation = async (data) => {
  try {
    console.log(data)
    const url = "/grade";
    const response = await instance.post(url,data);
    if (response.data) {
      notify("success", "ajout avec succès");
      window.location.reload();
      return response.data;
    } else {
      notify("success", "Invalid response from the server. Check the server logs for details.");
      throw new Error(
        "Invalid response from the server. Check the server logs for details."
      );
    }
  } catch (error) {
    console.log(error)
    notify("error", "Error adding hospitalisation", +error.message);
  }
};
export const Updatehospitalisation= async (data) => {
  try {
    const url = `/grade/${data.id}`;
    const response = await instance.put(url,data);
    if (response.data?.success) {
      notify("success", "hospitalisation mis à jour avec succès");
      window.location.reload();
      return response;
    }
  } catch (error) {
    notify("error", "Erreur lors de la mise à jour ", +error.message);
    throw error;
  }
};
export const Deletehospitalisation = async (id) => {
  try {
    const url = `/grade/${id}`;
    const response = await instance.delete(url);
    if (response.data) {
      notify("success", "hospitalisation deleted successfully");
      window.location.reload();
      return response.data;
    } else {
      
      throw new Error(
        "Invalid response from the server. Check the server logs for details."
      );
    }
  } catch (error) {
    console.log('err',error.response?.data?.message)
    notify("error", "Error deleting hospitalisation", +error.response?.data?.message);
  }
};
//Rdvs
export const CreateRendezVous = async (data) => {
  console.log(data);
  const { animalId, title, disc, startDate, endDate, clientId } = data;
  if (!animalId || !title || !disc || !startDate || !endDate || !clientId) {
    notify("error", "Tous les champs requis doivent être renseignés.");
    return Promise.reject(
      new Error("Tous les champs requis doivent être renseignés.")
    );
  }
  try {
    const url = `/rdv`;
    const reponse = await instance.post(url, data);
    if (reponse.data?.rdv) {
      notify("success", "rendez vous rajouté avec succès");
      window.location.reload();
      return reponse.data;
    }
  } catch (erreur) {
    notify("error", "Erreur lors de l'ajout du rendez vous", +erreur.message);
    throw erreur;
  }
};
export const GetAllRdv = async () => {
  try {
    const url = "/rdv";
    const response = await instance.get(url);
    if (response.data) {
      return response.data.rdvs;
    } else {
      throw new Error(
        "Invalid response from the server. Check the server logs for details."
      );
    }
  } catch (error) {
    notify("error", "Error fetching Rdv", +error.message);
    throw error;
  }
};
//users
export const GetAllUsers = async () => {
  try {
    const url = "/users";
    const response = await instance.get(url);

    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Invalid response from the server. Check the server logs for details."
      );
    }
  } catch (error) {
    notify("error", "Error fetching clients", +error.message);
    throw error;
  }
};
export const GetUserById = async (id) => {
  try {
    const url = `/users/${id}`;
    const response = await instance.get(url);
    if (response.data) {
      return response.data;
    } else {
      throw new Error(
        "Réponse invalide du serveur. Vérifiez les journaux du serveur pour plus de détails."
      );
    }
  } catch (error) {
    notify("error", "Erreur lors de la récupération ", error.message);
    throw error;
  }
};
export const DeleteUser = async (id) => {
  try {
    const url = `/users/${id}`;
    const response = await instance.delete(url);
    console.log(response?.data?.deleted)
    if (response?.data?.deleted) {
      notify("success", "User deleted successfully");
      window.location.reload();
      return response.data;
    } else {
      notify("error", "Error deleting client", +response.data.message);
    }
  } catch (error) {
    notify("error", "Error deleting client", +error.message);
    throw error;
  }
};
export const UpdateUser = async (data) => {
  console.log({ data });
  try {
    const url = `/users/${data.id}`;
    const response = await instance.put(url, { data });
    if (response.data?.success) {
      notify("success", "User mis à jour avec succès");
      window.location.reload();
      return response;
    }
  } catch (error) {
    notify("error", "Erreur lors de la mise à jour du client", +error.message);
    throw error;
  }
};
export const AddUser = async (data) => {
  try {
    const url = "/users";
    console.log({data})
    const response = await instance.post(url, {data});
    console.log(response)
    if (response.data?.user) {
      notify("success", "User added successfully");
      // window.location.reload();
      return response.data;
    }
    if (response.data?.message) {
      notify("error",response.data?.message );
      return response.data;
    }
  } catch (error) {
    console.log(error.message)
    notify("error", "Error adding client maybe client aleardy exsists",);
    throw error;
  }
};
//
export const getAllProducts = async () => {
  try {
    const url = `/product`;
    const response = await instance.get(url);
    return response.data;
  } catch (error) {
    notify("error", "Erreur lors de la récupération ", error.message);
    throw error;
  }
};
export const DeleteProduct = async (id) => {
  console.log(id)
  try {
    const url = `/product/${id}`;
    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      checkfor: "product",
      pm_auth: `${Cookies.get("auth_token")}`,
    };
    let data = {hidden:true}
   const response=await instance.put(url, {data:data, returnProducts: true} , { headers })
    console.log(response.data.success)
    if (response.data.success) {

      notify("success", "product deleted successfully");
      window.location.reload();
      return response.data;
    } else {
      console.log(response.data.message)
      notify("success", "Error deleting ",response.data.message);
    }
  } catch (error) {
    notify("error", "Error deleting ", +error.message);
    throw error;
  }
};

export const getAllServices = async () => {
  try {
    const url = `/service`;
    const response = await instance.get(url);
    if (response.data) {
      const totalProducts = response?.data.reduce(
        (accumulator, service) => accumulator + (service.products ? service.products.length : 0),
        0
      );

      return {
        totalProducts: totalProducts,
        services: response.data,
      };
    } else {
      console.error("Échec de la requête :", response.data.message);
      // Gérer cela en conséquence, par exemple, en retournant une valeur par défaut.
      return {
        totalProducts: 0,
        services: [],
      };
    }
  } catch (error) {
    notify("error", "Erreur lors de la récupération ", error.message);
    throw error;
  }
};
export const DeleteService = async (id) => {
  console.log(id)
  try {
    const url = `/service/${id}`;
    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      checkfor: "product",
      pm_auth: `${Cookies.get("auth_token")}`,
    };
    let data = {hidden:true}
   const response=await instance.put(url, {data:data, returnProducts: true} , { headers })
    console.log(response.data.success)
    if (response.data.success) {

      notify("success", "service deleted successfully");
      window.location.reload();
      return response.data;
    } else {
      console.log(response.data.message)
      notify("success", "Error deleting ",response.data.message);
    }
  } catch (error) {
    notify("error", "Error deleting ", +error.message);
    throw error;
  }
};
//notifications
export const getnotifications = async () => {
  try {
    let url = "/notification";
    // let headers = {
    //   "Content-Type": "application/json",
    //   "Access-Control-Allow-Origin": "*",
    //   pm_auth: `${Cookies.get("auth_token")}`,
    //   checkfor: "notification",
    // };
    const res = await instance.get(url);
    if (res?.data?.notifications) {
      let numeration = 0;
      if (res.data.notifications?.products?.peremption?.length) {
        numeration += res.data.notifications?.products?.peremption?.length;
      }
      if (res.data.notifications?.products?.qt?.length) {
        numeration += res.data.notifications?.products?.qt?.length;
      }
      return { data: res.data.notifications, count: numeration };
    }
  } catch (e) {
    notify("errcatch", err.message);
  }
};

//Ventes
export const getAllVentes = async () => {
  try {
    const url = `/vente`;
    const response = await instance.get(url);
    return response.data;
  } catch (error) {
    notify("error", "Erreur lors de la récupération ", error.message);
    throw error;
  }
};
export const getventes = async () => {
  let url = "/vente/vente_today";
  let headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    pm_auth: `${Cookies.get("auth_token")}`,
    checkfor: "produit",
  };
  try{
  const res=await instance.get(url, { headers })
      if (res?.data?.ventes) {
        console.log(res.data.ventes)
        let todays_gains = res?.data.ventes.reduce(
          (accumulator, vente) => accumulator + (vente.payed ? vente.payed: 0),
          0
        );
        console.log(todays_gains)
        return {ventes:res.data.ventes,todaysgains:todays_gains};
      }    } catch (error) {
        notify("error", "Erreur lors de la récupération ", error.message);
        throw error;
      }
};
//Transaction
export const getAllTransaction = async () => {
  try {
    const url = `/transaction`;
    const response = await instance.get(url);
    return response.data;
  } catch (error) {
    notify("error", "Erreur lors de la récupération ", error.message);
    throw error;
  }
};
// fournisseurs
export const getFournisseurs = async () => {
  let url = "/fournisseurs";
  let headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    pm_auth: `${Cookies.get("auth_token")}`,
    checkfor: "fournisseurs",
  };
try{
  const res=await instance.get(url, { headers })
  if (res?.data?.fournisseurs) {
    let fr = res.data.fournisseurs.map((fournisseur)=>{
      return {...fournisseur,label:fournisseur.firstname}})
  }
  if (res?.data?.message) {
    notify("error", res.data.message);
    return res.data.message
  }
} catch (error) {
  notify("error", "Erreur lors de la récupération ", error.message);
  throw error;
}
};

export const api = {
  login,
  GetAllClient,
  GetClientCredit,
  DeleteClient,
  AddClientapi,
  notify,
  GetAllhospitalisation,
  AddAnimalapi,
  GetAnimalById,
  UpdateserviceInformationsById,
  GetAllAnimals,
  getAllProducts,
  getAllServices,
  getFournisseurs,
  GetAllUsers,
  GetUserById,
  DeleteUser,
  UpdateUser,
  AddUser,
  getAllVentes,
  getAllTransaction,
};